import { useTranslation } from 'react-i18next'
import { IPosition } from '../../../generated-types'
import { Dialog, Stack, Typography, Box, Autocomplete, MenuItem, TextField, Button } from '@mui/material'
import { useState, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useUpdatePositionTemplates } from '../../../api/moorings'
import { useGetPositionDrawingInMooring } from '../../../api/position-drawing'
import { invalidatePositionCache } from '../../../api/positions'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { IPositionDrawing } from '../../../generated-types/position-drawing'

interface SetPositionTemplateDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
  selectedPositions: IPosition[]
}

export const SetPositionTemplateDialog: React.FC<SetPositionTemplateDialogProps> = ({
  open,
  setOpen,
  isLoading,
  customerId,
  localityId,
  mooringId,
  selectedPositions,
}) => {
  const { t } = useTranslation(['mooring', 'common'])
  const { data: positionDrawings, isLoading: isLoadingPositionDrawing } = useGetPositionDrawingInMooring(customerId, mooringId)
  const [selectedTemplate, setSelectedTemplate] = useState<IPositionDrawing>()
  const [positionDrawingList, setPositionDrawingList] = useState<IPositionDrawing[]>([])
  const { mutateAsync, isLoading: isUpdating } = useUpdatePositionTemplates(customerId, localityId, mooringId)

  const handleSubmitSelectedTemplate = useCallback(() => {
    if (selectedTemplate?.positionDrawingProducts.length == 0)
      toast(t('noProductsAssignedInTheTemplate'), { type: 'error' })
    else {
      mutateAsync({
        positionIdList: selectedPositions.map(position => position.id),
        positionDrawingId: selectedTemplate?.id as number,
      }, {
        onSuccess: () => {
          invalidatePositionCache.getPositions(customerId, localityId, mooringId)
          invalidatePositionCache.getPositionsWithMinimalData(customerId as EntityId, localityId as EntityId, mooringId as EntityId)
          toast(t('updatedSuccessfully', { ns: 'common' }), { type: 'success' })
          setOpen(false)
        },
        onError: () => {
          toast(t('errorUpdatingPositionTemplate'), { type: 'error' })
        },
      })
    }
  }, [customerId, localityId, mooringId, mutateAsync, selectedPositions, selectedTemplate, setOpen, t])

  useEffect(() => {
    const drawings = positionDrawings?.items as IPositionDrawing[]
    if (drawings && selectedPositions) {
      const filterByCategory = drawings.filter(x => x.category == selectedPositions[0]?.type)
      setPositionDrawingList(filterByCategory ?? [])
    }
  }, [positionDrawings, selectedPositions])

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <GenericDialogLayout
          actions={[
            <Button
              key="cancel-button"
              sx={{ marginRight: 1 }}
              variant="outlined"
              onClick={() => {
                setOpen(false)
              }}
            >
              {t('cancel', { ns: 'common' })}
            </Button>,
            <Button
              key="update-button"
              variant="contained"
              onClick={handleSubmitSelectedTemplate}
              disabled={isLoading || selectedTemplate == undefined || isUpdating}
            >
              {t('update', { ns: 'common' })}
            </Button>,
          ]}
          title={t('select-template-title', { ns: 'mooring' })}
          pop={() => {
            setOpen(false)
          }}
        >
          <Stack flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'flex-start'} width={'100%'}>
            <Typography>{t('position-template', { ns: 'mooring' })}</Typography>
            <Box width={'75%'} className="mt-2 ">
              <Autocomplete
                options={positionDrawingList}
                loading={isLoadingPositionDrawing}
                autoHighlight
                getOptionLabel={o => o.drawingNumber}
                onChange={(_, value) => {
                  setSelectedTemplate(value as IPositionDrawing)
                }}
                renderOption={(props, o) => (
                  <MenuItem key={o.id} value={o.id} {...props}>
                    {o.drawingNumber}
                  </MenuItem>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Box>
          </Stack>
        </GenericDialogLayout>
      </Dialog>
    </>
  )
}
