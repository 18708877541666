import { Box } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { Delete } from '@mui/icons-material'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { useDeleteLocality } from '../../../api/locality-groups-locality-links'
import { invalidateLocalityGroupsQueries, useGetLocalitiesInLocalityGroups } from '../../../api/locality-groups'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { CustomStyledTable } from 'src/screens/localities/moorings/Components/PositionTable'

const LocalitiesInLocalityGroups = ({ localityGroupId }) => {
  const { t } = useTranslation(['access-control', 'common', 'locality'])
  const { showConfirmDialog } = useDialogCtx()
  const { customer } = useCustomerCtx()
  const { data } = useGetLocalitiesInLocalityGroups(customer.id, localityGroupId)
  const { mutateAsync: deleteLocality } = useDeleteLocality(customer.id, localityGroupId)

  const deleteLocalityAction = (id: number) => {
    showConfirmDialog(
      t('delete-confirm-locality-title', { ns: 'locality-group' }),
      t('delete-confirm-locality-description', { ns: 'locality-group' }),
      { acceptText: t('yes', { ns: 'common' }), cancelText: t('no', { ns: 'common' }) },
      async pop => {
        await deleteLocality(id, {
          onSuccess: () => {
            invalidateLocalityGroupsQueries.getLocalities(customer.id, localityGroupId)
            pop()
          },
        })
      },
    )
  }

  const localityGroupsColumn: GridColDef[] = [
    {
      field: 'name',
      headerName: t('name', { ns: 'common' }),
      flex: 2,
      renderCell: params => params.row.name,
    },
    {
      field: 'actions',
      headerName: '',
      maxWidth: 50,
      renderCell: params => {
        return (
          <Box className="cursor-pointer">
            <Delete color="primary" onClick={() => deleteLocalityAction(params?.id as number)} />
          </Box>
        )
      },
    },
  ]

  return (
    <CustomStyledTable
      id={'locality-locality-groups'}
      columns={localityGroupsColumn}
      data={data || []}
      getRowId={row => row.id}
      getRowHeight={() => 'auto'}
    />
  )
}

export default LocalitiesInLocalityGroups
