import { Box, Card, useTheme, styled, IconButton, Stack, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../app-routes'
import { ReactComponent as SettingsIcon } from '../../assets/svg/settings.svg'
import { GetRGBA } from 'src/utils'
import { Flex } from 'src/styles/flexComponent'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CountWithIconBox from '../common/CountWithIconBox'
import { ReactComponent as DeviationIcon } from '../../assets/svg/deviation.svg'
import { ReactComponent as MaintenanceIcon } from '../../assets/svg/maintenance.svg'
import { ReactComponent as DocumentIcon } from '../../assets/svg/documents-icon.svg'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { ICustomerActivitySummary } from 'src/generated-types'
import { Spinner } from '../Spinner'
import { DynamicTextTypography } from '../DynamicTextTypography'

interface CustomerHeaderProps {
  id: number
  name: string
  image: string
  active?: boolean
  onClick?: (e: any) => void
  MultipleSelectComponent?: React.ReactNode
  hasAdminAccess?: boolean
  isServiceStation?: boolean
  customerActivitySummaryData?: any
  isLandingPage?: boolean
  isLoading?: boolean
  subComponentEnable?: boolean
  isGlobalServiceStationProvider: boolean
}

interface ShadowedCardProps {
  changeColor?: boolean
  padding?: string
  isLandingPage?: boolean
  height?: string
}

interface CommonInterface {
  isLandingPage?: boolean
  isServiceStationSmallHeader?: boolean
  img?: any
}
interface customerLocalitiesStatusInterface {
  customerActivitySummaryData?: ICustomerActivitySummary
  loading?: boolean
}

interface CustomerActionRowProps {
  customerId: EntityId
  active?: boolean
  isLandingPage?: boolean
  isServiceStationSmallHeader?: boolean
  isGlobalServiceProvider: boolean
}

export const CustomerHeader = ({
  id,
  name,
  image,
  active,
  onClick,
  isLandingPage,
  isServiceStation = false,
  MultipleSelectComponent,
  hasAdminAccess,
  customerActivitySummaryData,
  isLoading,
  subComponentEnable,
  isGlobalServiceStationProvider,
}: CustomerHeaderProps) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1070)) && isLandingPage
  const isMobile = useMediaQuery(theme.breakpoints.down(600))
  return (
    <ShadowedCard padding="0px 8px 0px 0px" isLandingPage={isLandingPage}>
      <Flex.Row
        alignItems="center"
        justifyContent={'space-between'}
        padding={0}
        height={!isLandingPage ? '64px' : 'inherit'}
      >
        <Flex.Row
          alignItems="center"
          onClick={onClick}
          height="52px"
          padding={0}
          margin={0}
          width={isLandingPage ? 'auto' : '107px'}
        >
          {isLandingPage && <Chevron>{active ? <ArrowDropDown /> : <ArrowRight />}</Chevron>}
          {!isMobile && (
            <>
              {image != null && image != undefined && image != '' ? (
                <CustomerLogo isLandingPage={isLandingPage} img={image} />
              ) : (
                <StyledEmptyLogoBox isLandingPage={isLandingPage}> {getNameLettersForLogo(name)}</StyledEmptyLogoBox>
              )}
            </>
          )}
          <StyledContainer isLandingPage={isLandingPage}>
            <Name isLandingPage={isLandingPage} maxLength={20} text={name} />
            {!isServiceStation && !isMediumScreen && customerActivitySummaryData && (
              <CustomerLocalitiesStatus
                customerActivitySummaryData={customerActivitySummaryData}
                isLandingPage={isLandingPage}
                loading={isLoading}
              />
            )}
            {subComponentEnable && <>{MultipleSelectComponent}</>}
          </StyledContainer>
        </Flex.Row>
        <Flex.Row alignItems="center" margin={`${isLandingPage || isServiceStation ? '0px' : '11px 3px 37px 0px'}`}>
          {isLandingPage && (
            <Box
              sx={{
                ml: 'auto',
                paddingRight: '8px',
                height: '44px',
                width: '173px',
              }}
            >
              {active && MultipleSelectComponent}
            </Box>
          )}
          {hasAdminAccess && (
            <CustomerActionRow
              customerId={id}
              active={!isLandingPage && active}
              isLandingPage={isLandingPage}
              isServiceStationSmallHeader={isServiceStation && !isLandingPage}
              isGlobalServiceProvider={isGlobalServiceStationProvider}
            />
          )}
        </Flex.Row>
      </Flex.Row>
    </ShadowedCard>
  )
}

const CustomerActionRow = ({
  customerId,
  isLandingPage,
  isServiceStationSmallHeader,
  isGlobalServiceProvider,
}: CustomerActionRowProps) => {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <StyledCustomerActionRow
      isLandingPage={isLandingPage}
      isServiceStationSmallHeader={isServiceStationSmallHeader}
      theme={theme}
    >
      <IconButton
        onClick={e => {
          e.stopPropagation()
          if (isGlobalServiceProvider) {
            navigate(AppRoutesNavigation.Customer.serviceStations(customerId))
          } else {
            navigate(AppRoutesNavigation.Customer.localities(customerId))
          }
        }}
      >
        <SettingsIcon height={isLandingPage ? '18px' : '17px'} width={isLandingPage ? '17px' : '15px'} />
      </IconButton>
    </StyledCustomerActionRow>
  )
}

const CustomerLocalitiesStatus: React.FC<customerLocalitiesStatusInterface & CommonInterface> = ({
  customerActivitySummaryData,
  isLandingPage,
  loading,
}) => {
  const { t } = useTranslation('common')
  const theme = useTheme()
  return loading ? (
    <Box
      className={`flex ${isLandingPage ? 'flex-wrap justify-end' : 'nowrap'}`}
      width={'100px'}
      marginTop={'3px'}
      alignContent={'center'}
      justifyContent={'center'}
    >
      <Spinner size={'15px'} />
    </Box>
  ) : (
    <Box className={`flex ${isLandingPage ? 'flex-wrap justify-end' : 'nowrap'}`} width={'200px'} marginTop={'3px'}>
      <Box>
        <CountWithIconBox
          title={t('maintenance', { ns: 'common' })}
          Component={MaintenanceIcon}
          value={String(customerActivitySummaryData?.servicesCount ?? 0).padStart(3, '0')}
          iconBgColor={theme.palette.primaryDark[theme.palette.mode]}
          color={theme.palette.black[theme.palette.mode]}
          styles={{
            paddingRight: !isLandingPage ? '5px' : '6px',
            fontSize: isLandingPage ? '12px' : '11px',
            textAlign: 'left',
            paddingTop: isLandingPage ? '0px' : '1px',
          }}
          customChipWidth={'auto'}
        />
      </Box>
      <Box sx={{ paddingLeft: isLandingPage ? '8px' : '4px' }}>
        <CountWithIconBox
          title={t('deviations', { ns: 'common' })}
          Component={DeviationIcon}
          value={String(customerActivitySummaryData?.deviationsCount ?? 0).padStart(3, '0')}
          iconBgColor={theme.palette.errorMain[theme.palette.mode]}
          color={theme.palette.black[theme.palette.mode]}
          styles={{
            paddingRight: !isLandingPage ? '5px' : '6px',
            fontSize: isLandingPage ? '12px' : '11px',
            textAlign: 'left',
            paddingTop: isLandingPage ? '0px' : '1px',
          }}
          customChipWidth={'auto'}
        />
      </Box>
      <Box sx={{ paddingLeft: isLandingPage ? '8px' : '4px' }}>
        <CountWithIconBox
          title={t('documents', { ns: 'common' })}
          Component={DocumentIcon}
          value={String(customerActivitySummaryData?.documentsCount ?? 0).padStart(3, '0')}
          iconBgColor={theme.palette.primaryDark[theme.palette.mode]}
          color={theme.palette.black[theme.palette.mode]}
          styles={{
            paddingRight: !isLandingPage ? '5px' : '6px',
            fontSize: isLandingPage ? '12px' : '11px',
            textAlign: 'left',
            paddingTop: isLandingPage ? '0px' : '1px',
          }}
          customChipWidth={'auto'}
        />
      </Box>
      {/* <Box sx={{ paddingLeft: isLandingPage ? '8px' : '4px' }}>
        <CountWithIconBox
          title={t('Tasks')}
          Component={CalendarIcon}
          value={String(customerActivitySummaryData?.tasksCount ?? 0).padStart(3, '0')}
          iconBgColor={theme.palette.primaryDark[theme.palette.mode]}
          color={theme.palette.black[theme.palette.mode]}
          styles={{
            paddingRight: !isLandingPage ? '5px' : '6px',
            fontSize: isLandingPage ? '12px' : '11px',
            textAlign: 'left',
            marginTop: !isLandingPage ? '1px' : '',
          }}
          customChipWidth={isLandingPage ? '51px' : '48px'}
        />
      </Box> */}
    </Box>
  )
}

const CustomerLogo = styled(Box)<CommonInterface>(({ isLandingPage, img }) => ({
  borderRadius: '4px',
  padding: isLandingPage ? '3px 21px 3px 0px' : '0px 11px 0px 0px',
  marginRight: '16px',
  width: isLandingPage ? '90px' : '71px',
  height: isLandingPage ? '50px' : '64px',
  backgroundImage: `url(${img})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  minHeight: isLandingPage ? '50px' : '64px',
  minWidth: isLandingPage ? '90px' : '71px',
}))

export const ShadowedCard = styled(Card)<ShadowedCardProps>(
  ({ theme, changeColor, padding, isLandingPage = false, height }) => ({
    '&.MuiPaper-root': {
      backgroundColor: theme.palette.white[theme.palette.mode],
      color: changeColor ? theme.palette.white[theme.palette.mode] : theme.palette.primaryDark[theme.palette.mode],
      boxShadow: `${isLandingPage ? '0px 0px 6px' : '0px 2px 8px'} ${GetRGBA(
        theme.palette.black[theme.palette.mode],
        isLandingPage ? 0.29 : 0.4,
      )}`,
    },
    cursor: 'pointer',
    padding: padding,
    height: isLandingPage ? height ?? '56px' : '64px',
    width: isLandingPage ? 'auto' : '290px',
  }),
)

export const Chevron = styled(Box)(({ theme }) => ({
  padding: '0 8px 0 4px',
  alignContent: 'center',
  color: theme.palette.primaryMain[theme.palette.mode],
}))

const Name = styled(DynamicTextTypography)<CommonInterface>(({ isLandingPage }) => ({
  fontWeight: isLandingPage ? 'bold' : '600',
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: isLandingPage ? '21px' : '18px',
  color: '#050505',
  paddingRight: isLandingPage ? '19px' : '1px',
}))

const StyledContainer = styled(Stack)<CommonInterface>(({ isLandingPage }) => ({
  display: 'flex',
  flexDirection: isLandingPage ? 'row' : 'column',
  alignItems: isLandingPage ? 'center' : 'start',
  justifyContent: 'space-between',
  margin: isLandingPage ? '14px 0px 13px 0px' : '6px 0px 8px 0px',
}))

const StyledEmptyLogoBox = styled(Box)<CommonInterface>(({ isLandingPage }) => ({
  minHeight: isLandingPage ? '50px' : '64px',
  minWidth: isLandingPage ? '90px' : '71px',
  borderRadius: '4px',
  margin: isLandingPage ? '3px 21px 3px 0px' : '0px 7px 0px 0px',
  backgroundColor: '#bdbdbd',
  color: '#E9F0F8',
  textAlign: 'center',
  alignContent: 'center',
  fontSize: '20px',
  fontWeight: '500',
}))

const getNameLettersForLogo = name => {
  return name
    .split(' ')
    .slice(0, 2)
    .map(word => word.charAt(0))
    .join('')
}

const StyledCustomerActionRow = styled(Flex.Row)<CommonInterface>(
  ({ isLandingPage, theme, isServiceStationSmallHeader }) => ({
    alignContent: 'center',
    color: theme.palette.cadetBlue[theme.palette.mode],
    width: isLandingPage ? '44px' : '15px',
    height: isLandingPage ? '44px' : '17px',
    alignItems: 'center',
    justifyContent: 'center',
    border: isLandingPage ? '1px solid #C4C4C4' : 'none',
    borderRadius: '4px',
    marginLeft: isLandingPage ? '8px' : '12px',
    marginBottom: isServiceStationSmallHeader ? '2px' : '',
    marginRight: isServiceStationSmallHeader ? '8px' : '',
    '&:hover ': {
      borderColor: '#212121',
    },
    '&.Mui-focused ': {
      borderColor: '#00526B',
    },
  }),
)
