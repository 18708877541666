import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { IPositionDrawing } from 'src/generated-types/position-drawing'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { useTranslation } from 'react-i18next'
import { IPositionType } from 'src/generated-types'
interface PositionDrawingCardProps {
  positionDrawing?: IPositionDrawing
  setSelectedTemplate?: (positionDrawing?: IPositionDrawing) => void
  border: string
  icon?: any
  isDefaultDrawing: boolean
  toolTipText: string
  handleConfirmDelete?: (id: number) => void
  isSelected?: boolean
}

const PositionDrawingCard: React.FC<PositionDrawingCardProps> = ({
  positionDrawing,
  setSelectedTemplate,
  border,
  icon,
  isDefaultDrawing = true,
  toolTipText,
  handleConfirmDelete,
  isSelected,
}) => {
  const theme = useTheme()
  const { t: translate } = useTranslation(['position', 'common'])
  return (
    <>
      <Box padding={'2px'}>
        <Box
          width={'auto'}
          height={'auto'}
          sx={{
            backgroundColor: `${theme.palette.white[theme.palette.mode]}`,
            background:
              isDefaultDrawing && isSelected
                ? 'transparent linear-gradient(180deg, #00416A 0%, #003E65 17%, #003B5F 33%, #00395A 50%, #003654 67%, #00334F 83%, #00304A 100%) 0% 0% no-repeat padding-box'
                : '',
          }}
          border={border}
          borderRadius={'4px'}
          onClick={() => {
            setSelectedTemplate && setSelectedTemplate(positionDrawing)
          }}
        >
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
            <Box>
              {isDefaultDrawing && (
                <DragIndicatorIcon
                  htmlColor={isDefaultDrawing && isSelected ? theme.palette.white[theme.palette.mode] : ''}
                />
              )}
            </Box>
            <Box padding={'14px'}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={isDefaultDrawing && isSelected ? theme.palette.white[theme.palette.mode] : ''}
              >
                {positionDrawing?.drawingNumber} - {translate(IPositionType[positionDrawing?.category ?? ''], { ns: 'position' })}
              </Typography>
            </Box>
            <Box
              flex={'auto'}
              paddingRight={'12px'}
              display={'flex'}
              justifyContent={'flex-end'}
              sx={{ cursor: 'pointer' }}
            >
              <Tooltip title={toolTipText}>
                {
                  <Box
                    onClick={() => {
                      !isDefaultDrawing && handleConfirmDelete && handleConfirmDelete(positionDrawing?.id as number)
                    }}
                  >
                    {icon}
                  </Box>
                }
              </Tooltip>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default PositionDrawingCard
