import { ComponentsTable } from './Components/ComponentsTable'
import { useGetComponents } from '../../../api/components'
import { IComponent } from '../../../generated-types'
import { FC, useEffect, useState } from 'react'
import { APIResultCount } from 'src/constant'
import { CenteredSpinner } from 'src/components/Spinner'
import { EmptyStatusCard } from 'src/components/mooring/EmptyStatusCard'
import { UpdateComponentDialog } from '../positionsComponents/UpdateComponentDialog'
import { useDialogCtx } from 'src/hooks/context-hooks'

export interface PositionInformationContainerProps {
  customerId: EntityId
  positionId: EntityId
  localityId: EntityId
  mooringId: EntityId
}

const PositionInformationContainer: FC<PositionInformationContainerProps> = ({
  customerId,
  positionId,
  localityId,
  mooringId,
}) => {
  const [componentsList, setComponentsList] = useState([] as IComponent[])
  const { data: components, isLoading } = useGetComponents(
    customerId as EntityId,
    positionId as EntityId,
    0,
    APIResultCount.Max,
  )
  const { showDialog } = useDialogCtx()

  useEffect(() => {
    setComponentsList((components?.items as IComponent[]) ?? [])
  }, [components])

  const onEdit = async (id: EntityId) => {
    const updated = await showDialog(
      UpdateComponentDialog,
      {
        componentProps: {
          customerId,
          localityId,
          mooringId,
          positionId,
          id,
        },
      },
      undefined,
      true,
    )
    if (!updated) return
  }

  if (isLoading) return <CenteredSpinner containerProps={{ minHeight: 'calc(100vh * 0.3)' }} />

  return (
    <>
      {componentsList && componentsList.length > 0 ? (
        <ComponentsTable
          components={componentsList as IComponent[]}
          setComponents={setComponentsList}
          customerId={customerId}
          positionId={positionId}
          localityId={localityId}
          mooringId={mooringId}
          onEdit={onEdit}
          height={44 * componentsList.length + 60}
        />
      ) : (
        <EmptyStatusCard imageWidth="250px" />
      )}
    </>
  )
}

export default PositionInformationContainer
