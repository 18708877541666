import { useTranslation } from 'react-i18next'
import GenericTable from '../../../components/table/GenericTable'
import { Box, styled, useTheme } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { GridEventListener, useGridApiRef, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useGetProducts } from 'src/api/product'
import { APIResultCount } from 'src/constant'
import { CenteredSpinner, SelectField } from '@lovoldsystem/ui'
import { IProduct } from 'src/generated-types'
import { IPositionDrawingProduct } from 'src/generated-types/position-drawing-product'
import { generateUUID } from 'src/utils/function'
import { getFormattedDate, getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

export const CustomTemplateProductsGrid = styled(GenericTable)(({ theme }) => ({
  '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible.MuiDataGrid-row': {
    border: `1px dashed ${getThemeColor(theme, MUIThemeColors.primaryMain)}`,
    borderRadius: '4px',
    overflow: 'hidden',
  },
}))

interface ITemplateProductsGridProps {
  onCellEditCommit?: GridEventListener<'cellEditCommit'> | undefined
  setPositionDrawingProducts: React.Dispatch<React.SetStateAction<IPositionDrawingProduct[]>>
  positionDrawingProducts: IPositionDrawingProduct[]
}

export const TemplateProductsGrid: React.FC<ITemplateProductsGridProps> = ({
  onCellEditCommit,
  setPositionDrawingProducts,
  positionDrawingProducts,
}) => {
  const { t } = useTranslation(['mooring', 'common', 'component', 'supplier', 'product', 'admin-position-drawing'])
  const theme = useTheme()
  const apiRef = useGridApiRef()

  function ProductEditInputCell(props: GridRenderCellParams) {
    const { id, api, field } = props
    const row = api.getRow(id)

    const { data, isLoading } = useGetProducts('', 0, APIResultCount.Max)
    if (isLoading) return <CenteredSpinner size={'small'} />

    const selectedProduct = row != null && row.productId ? data?.items.find(p => p.id === row.productId) : null

    const handleChange = (event, product) => {
      const prevProducts = positionDrawingProducts.filter(p => p.id !== row.id)
      setPositionDrawingProducts([
        ...prevProducts,
        {
          ...product,
          productNumber: product.number,
          productId: product.id,
          quantity: 1,
          product: product,
          id: id,
        },
      ])
      api.setEditCellValue({ id, field, value: product.number }, event)
      if (row != null) row.productId = product.id
    }

    return (
      <SelectField
        sx={{ width: '240px', backgroundColor: '' }}
        label={t('search', { ns: 'common' })}
        data={data?.items as IProduct[]}
        getOptionLabel={o => o.number}
        onChange={handleChange}
        renderOption={(props: any, o) => (
          <Box {...props} key={o.id}>
            {o.number}
          </Box>
        )}
        defaultValue={selectedProduct}
        isOptionEqualToValue={(o, v) => o.id === v.id}
      />
    )
  }

  function renderProductEditInputCell(params) {
    return <ProductEditInputCell {...params} />
  }

  const handleClick = () => {
    const id = generateUUID()
    apiRef.current.updateRows([{ id, isNew: true }])
    apiRef.current.setRowMode(id, 'edit')
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      })
      apiRef.current.setCellFocus(id, 'productNumber')
    }, 150)
  }

  const columns: GridColumns = [
    { field: 'productId', headerName: 'ProductId', type: 'number', hide: true },
    {
      field: 'positionDrawingNumber',
      headerName: '#',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: any) => {
        return params.row.positionDrawingNumber
      },
    },
    {
      field: 'productNumber',
      headerName: t('productNumber', { ns: 'component' }),
      flex: 1,
      minWidth: 150,
      editable: true,
      renderEditCell: renderProductEditInputCell,
      valueGetter: (params: any) => {
        return params.row.productNumber
      },
    },
    {
      field: 'supplierName',
      headerName: t('supplier', { ns: 'supplier' }),
      flex: 1,
      minWidth: 100,
      valueGetter: (params: any) => {
        return params.row.product?.supplier?.name
      },
    },
    {
      field: 'description',
      headerName: t('productDescription', { ns: 'component' }),
      flex: 2.5,
      minWidth: 170,
      renderCell: params => {
        return params.row.product?.description
      },
    },
    {
      field: 'quantity',
      headerName: t('quantity', { ns: 'component' }),
      editable: true,
      flex: 0.5,
      minWidth: 70,
      renderCell: params => {
        return <span style={{ textAlign: 'center', width: '100%' }}>{params.row.quantity}</span>
      },
    },
    {
      field: 'unit',
      headerName: t('unit', { ns: 'component' }),
      flex: 1,
      minWidth: 70,
      valueGetter: (params: any) => {
        return params.row.product?.unit?.name
      },
    },
    { field: 'batchNo', headerName: t('batchNumber', { ns: 'component' }), flex: 1, editable: true, minWidth: 100 },
    {
      field: 'installationDate',
      headerName: t('installationDate'),
      flex: 1,
      minWidth: 170,
      type: 'date',
      editable: true,
      renderCell: ({ value }) => getFormattedDate(value),
    },
  ]

  return (
    <>
      <CustomTemplateProductsGrid
        id="templateProducts"
        data={positionDrawingProducts}
        apiRef={apiRef}
        enableToolbar={true}
        toolbarOptions={[
          {
            label: t(''),
            Component: (
              <Box marginTop={'-70px'}>
                <GridActionButton
                  onClick={handleClick}
                  toolTipTitle={''}
                  buttonText={t('addProduct', { ns: 'product' })}
                  bgColor={theme.palette.white.main}
                  height="38px"
                  width="142px"
                  buttonIcon={
                    <AddCircleOutline
                      style={{ fontSize: '16px' }}
                      htmlColor={theme.palette.primaryMain[theme.palette.mode]}
                    />
                  }
                />
              </Box>
            ),
          },
        ]}
        columns={columns}
        noRowsMessage={t('selectedTemplateHasNoProducts', { ns: 'mooring' })}
        onCellEditCommit={onCellEditCommit}
        height={200}
        autoHeight={false}
      />
    </>
  )
}
