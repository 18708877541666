import { z } from 'zod'
import { IPositionType } from '../../../generated-types'
import { t } from 'i18next'

export const getUpdatePositionSchema = (positionType: IPositionType) => {
  return z.object({
    id: z.number().default(0),
    name: z
      .string()
      .min(1, { message: t('nameIsRequired', { ns: 'common' }) })
      .regex(/^\d+$/, t('positionNamePositiveNumbersOnly', { ns: 'position' })),
    type: z.nativeEnum(IPositionType, {
      errorMap: () => {
        return { message: t('selectOneOfTheGivenOptions', { ns: 'common' }) }
      },
    }),
    positionDrawingId: z
      .number()
      .or(z.string())
      .or(z.null())
      .transform(x => (typeof x == 'string' && x == '' ? null : x))
      .optional(),
    latitude: showLatLang(positionType)
      ? z.union([z.string().regex(/\d+/, t('provideValidInput', { ns: 'common' })), z.number()])
      : z.any(),
    longitude: showLatLang(positionType)
      ? z.union([z.string().regex(/\d+/, t('provideValidInput', { ns: 'common' })), z.number()])
      : z.any(),
    isActive: z.boolean().default(false),
    mbl: z.union([z.string().regex(/\d+/, t('provideValidInput', { ns: 'common' })), z.number()]),
    accidentLoad: z.union([z.string().regex(/\d+/, t('provideValidInput', { ns: 'common' })), z.number()]),
    width: z.union([z.string().regex(/\d+/, t('provideValidInput', { ns: 'common' })), z.number()]).or(z.null()).optional(),
    mooringAnalyseAppliance: z.boolean().default(false),
    reference: z.string().or(z.null()).optional(),
    depth: z.union([z.string().regex(/\d+/, t('provideValidInput', { ns: 'common' })), z.number()]),
    note: z.string().or(z.null()).optional(),
    mooringId: z.number().optional(),
    connectedPositions: connectedPositionsRequired(positionType)
      ? z
          .array(z.object({ positionId: z.number(), positionConnectedId: z.number() }))
          .min(1, { message: t('connected-position-required', { ns: 'common' }) })
          .max(2)
      : z
          .array(z.object({ positionId: z.number(), positionConnectedId: z.number() }))
          .max(2)
          .or(z.null())
          .optional(),
  })
}

export const showLatLang = (positionType: IPositionType): boolean =>
  positionType == IPositionType.MooringLine || positionType == IPositionType.Corner

export const connectedPositionsRequired = (positionType: IPositionType): boolean => {
  return positionType == IPositionType.MooringLine
}
