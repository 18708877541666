import { useTranslation } from 'react-i18next'
import { useGetPositions, useAddPosition, invalidatePositionCache } from '../../../api/positions'
import { APIResultCount } from '../../../constant'
import { ShowDialogProps } from '../../../show-dialog'
import { PositionForm } from './components/PositionsForm'
import { useCallback } from 'react'
import { IPosition } from '../../../generated-types'
import { CenteredSpinner } from '../../../components/Spinner'
import { toast } from 'react-toastify'
import { invalidateMooringCache } from 'src/api/moorings'

interface CreatePositionProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
}
export const CreatePositionDialog: React.FC<CreatePositionProps> = ({ pop, customerId, localityId, mooringId }) => {
  const { t } = useTranslation(['position', 'common'])
  const { data: positionsList } = useGetPositions(customerId, localityId, mooringId, 0, APIResultCount.Max)

  const { mutateAsync, isLoading } = useAddPosition(customerId, localityId, mooringId, {})

  const onSubmit = useCallback(
    async form => {
      await mutateAsync(form, {
        onSuccess: () => {
          invalidateMooringCache.getSvgForPosition(customerId, localityId, mooringId)
          invalidatePositionCache.getPositions(customerId as EntityId, localityId as EntityId, mooringId as EntityId, {
            isIgnoreQueryFilters: true,
          })
          invalidateMooringCache.getMapSvg(customerId, localityId, mooringId)
          invalidateMooringCache.getGetMooring(customerId as EntityId, localityId, mooringId, {
            isWithAllInfo: true,
          })
          invalidatePositionCache.getPositionsWithMinimalData(customerId as EntityId, localityId as EntityId, mooringId as EntityId)
          invalidateMooringCache.getMooringsForMap(customerId, localityId)
          toast(t('addedSuccessfully', { ns: 'common' }), { type: 'success' })
          pop()
        },
        onError: () => {
          toast(t('error-position-add', { ns: 'position' }), { type: 'error' })
        },
      })
    },
    [mutateAsync, pop, t],
  )
  if (isLoading) return <CenteredSpinner />

  return (
    <PositionForm
      onSubmit={onSubmit}
      onCancel={() => pop()}
      title={t('addPosition', { ns: 'position' })}
      positions={positionsList?.items}
      customerId={customerId}
      localityId={localityId}
      initialValues={{ mooringId: mooringId as number } as IPosition}
      isDialog={true}
    />
  )
}
