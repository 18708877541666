import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IPositionDrawing } from 'src/generated-types/position-drawing'
import { TemplateProductsGrid } from './TemplateProductsGrid'
import { IPositionDrawingProduct } from 'src/generated-types/position-drawing-product'
import { useCallback, useEffect, useState } from 'react'
import { getUTCDate } from 'src/utils/function'
import { toast } from 'react-toastify'

interface CustomizeComponentContainerProps {
  positionDrawing?: IPositionDrawing
  setCustomizedTemplates?: React.Dispatch<React.SetStateAction<IPositionDrawing[]>>
  setSelectedTemplate: React.Dispatch<React.SetStateAction<IPositionDrawing | undefined>>
  initialProducts?: IPositionDrawingProduct[]
  addDrawing: (newDrawing: IPositionDrawing, positionDrawingProducts: IPositionDrawingProduct[]) => void
}
export const CustomizeComponentContainer: React.FC<CustomizeComponentContainerProps> = ({
  positionDrawing,
  setSelectedTemplate,
  addDrawing,
  initialProducts,
}) => {
  const { t: translate } = useTranslation(['mooring', 'product', 'component'])
  const [positionDrawingProducts, setPositionDrawingProducts] = useState<IPositionDrawingProduct[]>(
    positionDrawing?.positionDrawingProducts || [],
  )

  const [hasDuplicateDrawingNumbers, setHasDuplicateDrawingNumbers] = useState(false)

  useEffect(() => {
    setPositionDrawingProducts(positionDrawing?.positionDrawingProducts as IPositionDrawingProduct[])
  }, [positionDrawing?.positionDrawingProducts])

  const removeSelectedTemplate = () => {
    setSelectedTemplate(undefined)
  }

  const onCellEditCommit = useCallback(
    async ({ id, field, value }) => {
      const products = positionDrawingProducts as IPositionDrawingProduct[]
      const newField: object = {}
      if (field === 'installationDate') {
        newField['installationDate'] = getUTCDate(value)
      } else {
        newField[field] = value
      }
      const updatedProducts = await (products || []).map((p: any) => {
        if (p.id === id) {
          return { ...p, ...newField }
        }
        return { ...p }
      })

      const drawingNumbers = updatedProducts.map(item => parseInt(item.positionDrawingNumber))
      const hasDuplicates = new Set(drawingNumbers).size !== drawingNumbers.length
      if (hasDuplicates) {
        setHasDuplicateDrawingNumbers(true)
        toast.error(translate('duplicateDrawingNumber', { ns: 'component' }))
      } else setHasDuplicateDrawingNumbers(false)

      setPositionDrawingProducts(updatedProducts)
    },
    [initialProducts, positionDrawingProducts],
  )

  return (
    <Box>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} padding={'8px'}>
        <Box padding={'10px'}>
          <Typography variant="subtitle1" fontWeight={'500'}>
            {translate('customize-component')} - {positionDrawing?.drawingNumber} - {positionDrawing?.categoryName}
          </Typography>
        </Box>
      </Stack>
      <Box padding={'8px'}>
        <TemplateProductsGrid
          onCellEditCommit={onCellEditCommit}
          setPositionDrawingProducts={setPositionDrawingProducts}
          positionDrawingProducts={positionDrawingProducts}
        />
      </Box>
      <Stack direction="row" justifyContent="flex-end" gap={1} padding={'8px'}>
        <Button onClick={removeSelectedTemplate} variant="outlined">
          {translate('cancel', { ns: 'common' })}
        </Button>
        <Button
          onClick={() =>
            addDrawing(positionDrawing as IPositionDrawing, positionDrawingProducts as IPositionDrawingProduct[])
          }
          disabled={hasDuplicateDrawingNumbers}
          variant="contained"
        >
          {translate('add-template')}
        </Button>
      </Stack>
    </Box>
  )
}
