import { z } from 'zod'
import { IFormType } from '../../../generated-types/form-type'
import { DataTypes } from '../../../enums'
import { t } from 'i18next'

export const certificateSchema = z.object({
  certificateNumber: z
    .string()
    .nonempty()
    .regex(/^(?! *$).+/, t('certificateNumberIsInvalid', { ns: 'certificate' })),
  certificateDate: z.date().or(z.string()),
  validUntil: z.date().or(z.string()).optional(),
  certificateDocumentType: z.number(),
  certificateFileId: z.union([z.string(), z.number()]),
  certificateSupplierId: z
    .object({
      id: z.number(),
    })
    .transform(value => value.id),
  validPeriod: z
    .object({
      id: z.number(),
    })
    .transform(value => value.id),
})

export const getMainComponentSchema = (formType: IFormType, translate: any, isCertificateDetailsRequired?: boolean) => {
  const createSchema = z
    .object({
      typeId: z.number(),
      name: z.string().nonempty(translate('nameIsRequired')),
      description: z.string().or(z.null()).optional(),
      serialNumber: z.string().nonempty(translate('serialNumberIsRequired')),
      orderNumber: z.string().or(z.null()).optional(),
      dateProduced: z.date().or(z.string()).or(z.null()).optional(),
      lifetime: z
        .string()
        .or(z.number())
        .transform(v => Number(v))
        .optional(),
      supplierId: z
        .object({
          id: z.number(),
        })
        .transform(value => value.id),
      reference: z.string().or(z.null()).optional(),
      productionMonth: z.any().or(z.null()).optional(),
      productionYear: z.string().or(z.null()).optional(),
      fileId: z.union([z.string(), z.number()]).or(z.null()).optional(),
      positionId: z.number().or(z.null()).optional(),
      status: z.number().or(z.null()).optional(),
      circumference: z
        .number()
        .or(z.string())
        .or(z.null())
        .transform(x => (typeof x === 'string' ? Number(x) : x))
        .refine(value => value == null || value >= 0, { message: translate('mustBePositive', { ns: 'common' }) })
        .optional(),
      solidity: z
        .number()
        .or(z.string())
        .or(z.null())
        .transform(x => (typeof x === 'string' ? Number(x) : x))
        .refine(value => value == null || value >= 0, { message: translate('mustBePositive', { ns: 'common' }) })
        .optional(),
      mainComponentProperties: z
        .array(
          z.object({
            mainComponentId: z.number().or(z.null()).optional(),
            hs: z.number().or(z.string()),
            vC5: z.number().or(z.string()),
            vC15: z.number().or(z.string()),
          }),
        )
        .or(z.null())
        .optional(),
    })
    .merge(isCertificateDetailsRequired ? certificateSchema : z.object({}))

  const updateSchema = z.object({
    id: z.number(),
    name: z.string().nonempty(translate('nameIsRequired')),
    typeId: z.number(),
    description: z.string().optional(),
    serialNumber: z.string().nonempty(translate('serialNumberIsRequired')),
    orderNumber: z.string().optional(),
    dateProduced: z.any().optional(),
    lifetime: z
      .string()
      .or(z.number())
      .transform(v => Number(v))
      .optional(),
    supplierId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    reference: z.string().optional(),
    productionMonth: z.any().or(z.null()).optional(),
    productionYear: z.string().or(z.null()).optional(),
    customPropertyValues: z.string().optional(),
    fileId: z.union([z.any(), z.any()]).optional(),
    positionId: z.any().optional(),
    status: z.number().optional(),
    circumference: z
      .number()
      .or(z.string())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? (value == '' ? null : Number(value)) : value))
      .refine(value => value == null || value >= 0, { message: translate('mustBePositive', { ns: 'common' }) })
      .optional(),
    solidity: z
      .number()
      .or(z.string())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? (value == '' ? null : Number(value)) : value))
      .refine(value => value == null || value >= 0, { message: translate('mustBePositive', { ns: 'common' }) })
      .optional(),
    mainComponentProperties: z
      .array(
        z.object({
          mainComponentId: z.number().or(z.null()).optional(),
          hs: z.number().or(z.string()),
          vC5: z.number().or(z.string()),
          vC15: z.number().or(z.string()),
        }),
      )
      .or(z.null())
      .optional(),
  })

  switch (formType) {
    case IFormType.Create:
      return createSchema
    case IFormType.Update:
      return updateSchema
    default:
      return createSchema
  }
}

export function addCustomFields(schema, customFields) {
  const customFieldSchemas = {}
  customFields.forEach(field => {
    customFieldSchemas[JSON.parse(field.label as string).en.toLowerCase()] = getFieldSchema(field)
  })
  return schema.extend(customFieldSchemas)
}

function getFieldSchema(field) {
  switch (field.dataType) {
    case DataTypes.Integer:
      return z
        .string()
        .or(z.number())
        .transform(value => Number(value))
        .optional()
    case DataTypes.String:
      return z.string().or(z.null()).optional()
    default:
      return z.any() // Default to any type if data type is not recognized
  }
}
