import { IApiResult } from 'src/generated-types/api-result'
import { IComponent, IPosition, IWorkAssignmentPosition } from '../generated-types'
import { IProductCertification } from '../generated-types/product-certification'
import { IWorkAssignment } from '../generated-types/work-assignment'
import { IUpdateInstallationDateDto } from '../generated-types/work-assignment-dto'
import { queryClient } from '../query-client'
import { workAssignmentQueryKeys } from '../query-keys'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId, localityId: EntityId) =>
  `customers/${customerId}/localities/${localityId}/work-assignments`
const baseUrlComponents = (
  customerId: EntityId,
  localityId: EntityId,
  workAssignmentId: EntityId,
  workAssignmentPositionId: EntityId,
) =>
  `${baseUrl(
    customerId,
    localityId,
  )}/${workAssignmentId}/work-assignment-positions/${workAssignmentPositionId}/components`

const baseUrlOlex = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  `customers/${customerId}/localities/${localityId}/moorings/${mooringId}/work-assignments`

export const workAssignmentEndpoints = {
  getWorkAssignments(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}`
  },
  getWorkAssignment(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}`
  },
  checkCompletePositions(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}/check-complete-positions`
  },
  createWorkAssignment(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}`
  },
  signAndComplete(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}/sign-and-complete`
  },
  updateWorkAssignment(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}`
  },
  getWorkAssignmentPositions(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}/work-assignment-positions`
  },
  getPositionsOfWorkAssignment(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/mooring-positions`
  },
  updateWorkAssignmentPositions(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}/update-positions`
  },
  updateWorkAssignmentPosition(customerId: EntityId, localityId: EntityId, workAssignmentId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${workAssignmentId}/update-positions/${id}`
  },
  getWorkAssignmentPositionComponentsURI(
    customerId: EntityId,
    localityId: EntityId,
    workAssignmentId: EntityId,
    workAssignmentPositionId: EntityId,
  ) {
    return baseUrlComponents(customerId, localityId, workAssignmentId, workAssignmentPositionId)
  },
  getWorkAssignmentPositionComponentURI(
    customerId: EntityId,
    localityId: EntityId,
    workAssignmentId: EntityId,
    workAssignmentPositionId: EntityId,
    id: EntityId,
  ) {
    return `${baseUrlComponents(customerId, localityId, workAssignmentId, workAssignmentPositionId)}/${id}`
  },
  addBatchNumber(
    customerId: EntityId,
    localityId: EntityId,
    workAssignmentId: EntityId,
    workAssignmentPositionId: EntityId,
    id: EntityId,
  ) {
    return `${baseUrlComponents(
      customerId,
      localityId,
      workAssignmentId,
      workAssignmentPositionId,
    )}/${id}/addBatchNumber`
  },
  updateInlineValues(
    customerId: EntityId,
    localityId: EntityId,
    workAssignmentId: EntityId,
    workAssignmentPositionId: EntityId,
  ) {
    return `${baseUrlComponents(
      customerId,
      localityId,
      workAssignmentId,
      workAssignmentPositionId,
    )}/update-inline-values`
  },
  updateBulkInstallationDateComponents(
    customerId: EntityId,
    localityId: EntityId,
    workAssignmentId: EntityId,
    workAssignmentPositionId: EntityId,
  ) {
    return `${baseUrlComponents(
      customerId,
      localityId,
      workAssignmentId,
      workAssignmentPositionId,
    )}/update-bulk-installationDate`
  },
  updateInstallDate(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}/update-positions-install-date`
  },
  deleteWorkAssignment(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}`
  },
  uploadOlex(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrlOlex(customerId, localityId, mooringId)}/upload-olex`
  },
  exportOlex(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrlOlex(customerId, localityId, mooringId)}/export-olex`
  },
  removePosition(customerId: EntityId, localityId: EntityId, workAssignmentId: EntityId) {
    return `${baseUrl(customerId, localityId)}/${workAssignmentId}/remove-position`
  },
  generateWorkAssignmentPDF(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/pdf`
  },
}

export const invalidateWorkAssignmentCache = {
  getWorkAssignments(customerId?: EntityId, localityId?: EntityId) {
    queryClient.invalidateQueries(workAssignmentQueryKeys.GET_WORK_ASSIGNMENTS(customerId, localityId))
  },
  getWorkAssignment(customerId: EntityId, localityId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(workAssignmentQueryKeys.GET_WORK_ASSIGNMENT(customerId, localityId, id))
  },
  getWorkAssignmentPositions(customerId: EntityId, localityId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(workAssignmentEndpoints.getWorkAssignmentPositions(customerId, localityId, id))
  },
  getPositionsOfWorkAssignment(customerId?: EntityId, localityId?: EntityId, mooringId?: EntityId, reportId?: number) {
    queryClient.invalidateQueries(workAssignmentQueryKeys.GET_WORK_ASSIGNMENT_POSITIONS(customerId, localityId, { reportId: reportId, mooringId: mooringId }))
  },
  checkCompletePositions(customerId: EntityId, localityId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(workAssignmentEndpoints.checkCompletePositions(customerId, localityId, id))
  },
  getAllWorkAssignmentComponents(
    customerId: EntityId,
    localityId: EntityId,
    workAssignmentId: EntityId,
    workAssignmentPositionId: EntityId,
  ) {
    queryClient.invalidateQueries(
      workAssignmentEndpoints.getWorkAssignmentPositionComponentsURI(
        customerId,
        localityId,
        workAssignmentId,
        workAssignmentPositionId,
      ),
    )
  },
}

export const useGetWorkAssignments = (
  customerId: EntityId,
  localityId: EntityId,
  queryParams?: { isIgnoreQueryFilters?: boolean },
) =>
  useGet<IPaginatedResult<IWorkAssignment>>(workAssignmentEndpoints.getWorkAssignments(customerId, localityId), {
    ...queryParams,
  })

export const useGetWorkAssignment = (
  customerId: EntityId,
  localityId: EntityId,
  id: EntityId,
  queryParams?: { isIgnoreQueryFilters?: boolean },
) => useGet<IWorkAssignment>(workAssignmentEndpoints.getWorkAssignment(customerId, localityId, id), { ...queryParams }, {
  queryKey: workAssignmentQueryKeys.GET_WORK_ASSIGNMENT(customerId, localityId, id)
})

export const useGetPositionsForWorkAssignment = (
  customerId: EntityId,
  localityId: EntityId,
  mooringId: EntityId,
  reportId: number,
) =>
  useGet<IPosition[]>(workAssignmentEndpoints.getPositionsOfWorkAssignment(customerId, localityId), {
    reportId,
    mooringId,
  }, {
    queryKey: workAssignmentQueryKeys.GET_WORK_ASSIGNMENT_POSITIONS(customerId, localityId, { reportId: reportId, mooringId: mooringId })
  })

export const useGetPositionsByWorkAssignmentId = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  useGet<IWorkAssignmentPosition[]>(workAssignmentEndpoints.getWorkAssignmentPositions(customerId, localityId, id))

export const useCheckCompletePositions = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  useGet<boolean>(workAssignmentEndpoints.checkCompletePositions(customerId, localityId, id))

export const useAddWorkAssignment = (customerId: EntityId, localityId: EntityId) =>
  usePost<IWorkAssignment, IWorkAssignment>(workAssignmentEndpoints.createWorkAssignment(customerId, localityId))

export const useUpdateWorkAssignment = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePut<IWorkAssignment, IWorkAssignment>(workAssignmentEndpoints.updateWorkAssignment(customerId, localityId, id))

export const useSignAndComplete = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePost<IWorkAssignment, IWorkAssignment>(workAssignmentEndpoints.signAndComplete(customerId, localityId, id))

export const useUpdateWorkAssignmentPositions = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePut<IWorkAssignment, IPosition[]>(
    workAssignmentEndpoints.updateWorkAssignmentPositions(customerId, localityId, id),
  )

export const useUpdateWorkAssignmentPosition = (
  customerId: EntityId,
  localityId: EntityId,
  workAssignmentId: EntityId,
  id: EntityId,
) =>
  usePut<IPosition, IPosition>(
    workAssignmentEndpoints.updateWorkAssignmentPosition(customerId, localityId, workAssignmentId, id),
  )

export const useUpdateInstallDate = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePut<IWorkAssignment, IUpdateInstallationDateDto>(
    workAssignmentEndpoints.updateInstallDate(customerId, localityId, id),
  )

export const useDeleteWorkAssignment = (customerId: EntityId, localityId: EntityId) =>
  useDelete<IWorkAssignment>(workAssignmentEndpoints.deleteWorkAssignment(customerId, localityId))

export const useUploadOlex = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) => {
  return usePost<IPosition[], FormData>(workAssignmentEndpoints.uploadOlex(customerId, localityId, mooringId))
}

export const useExportOlex = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) => {
  return usePost<any, { workAssignmentId?: EntityId; mooringId: EntityId }>(
    workAssignmentEndpoints.exportOlex(customerId, localityId, mooringId),
    {},
    { responseType: 'blob' },
  )
}

export const useRemovePosition = (customerId: EntityId, localityId: EntityId, workAssignmentId: EntityId) =>
  useDelete<IWorkAssignment>(workAssignmentEndpoints.removePosition(customerId, localityId, workAssignmentId))

export const useGenerateWorkAssignmentPDF = (customerId: EntityId, localityId: EntityId) =>
  usePost<any, object>(
    workAssignmentEndpoints.generateWorkAssignmentPDF(customerId, localityId),
    {},
    { responseType: 'arraybuffer' },
  )

export const useGetWorkAssignmentPositionComponents = (
  customerId: EntityId,
  localityId: EntityId,
  workAssignmentId: EntityId,
  workAssignmentPositionId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IComponent>>(
    workAssignmentEndpoints.getWorkAssignmentPositionComponentsURI(
      customerId,
      localityId,
      workAssignmentId,
      workAssignmentPositionId,
    ),
    {
      page,
      pageSize,
    },
  )

export const useAddWorkAssignmentComponent = (
  customerId: EntityId,
  localityId: EntityId,
  workAssignmentId: EntityId,
  workAssignmentPositionId: EntityId,
) =>
  usePost<IComponent, IComponent>(
    workAssignmentEndpoints.getWorkAssignmentPositionComponentsURI(
      customerId,
      localityId,
      workAssignmentId,
      workAssignmentPositionId,
    ),
  )

export const useUpdateWorkAssignmentComponent = (
  customerId: EntityId,
  localityId: EntityId,
  workAssignmentId: EntityId,
  workAssignmentPositionId: EntityId,
  id: EntityId,
) =>
  usePut<IComponent, IComponent>(
    workAssignmentEndpoints.getWorkAssignmentPositionComponentURI(
      customerId,
      localityId,
      workAssignmentId,
      workAssignmentPositionId,
      id,
    ),
  )

export const useAddBatchNumber = (
  customerId: EntityId,
  localityId: EntityId,
  workAssignmentId: EntityId,
  workAssignmentPositionId: EntityId,
  id: EntityId,
) =>
  usePut<IProductCertification, IProductCertification>(
    workAssignmentEndpoints.addBatchNumber(customerId, localityId, workAssignmentId, workAssignmentPositionId, id),
  )

export const useDeleteWorkAssignmentPositionComponent = (
  customerId,
  localityId,
  workAssignmentId,
  workAssignmentPositionId,
) =>
  useDelete<IComponent>(
    workAssignmentEndpoints.getWorkAssignmentPositionComponentsURI(
      customerId,
      localityId,
      workAssignmentId,
      workAssignmentPositionId,
    ),
  )

export const useUpdateWorkAssignmentPositionComponentInlineValues = (
  customerId: EntityId,
  localityId: EntityId,
  workAssignmentId: EntityId,
  workAssignmentPositionId: EntityId,
) =>
  usePut<IComponent, IComponent>(
    workAssignmentEndpoints.updateInlineValues(customerId, localityId, workAssignmentId, workAssignmentPositionId),
  )

export const useBulkUpdateWorkAssignmentPositionComponentInstallationDate = (
  customerId: EntityId,
  localityId: EntityId,
  workAssignmentId: EntityId,
  workAssignmentPositionId: EntityId,
) =>
  usePut<IApiResult, {componentIds:number[],installationDate:Date}>(
    workAssignmentEndpoints.updateBulkInstallationDateComponents(
      customerId,
      localityId,
      workAssignmentId,
      workAssignmentPositionId,
    ),
  )
