import { GridColumns } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { GpsToDms } from '../../../../components/GpsToDms'
import { Box, MenuItem, Select, Stack, Typography } from '@mui/material'
import { IPositionType, IPositionWithDrawing } from '../../../../generated-types'
import { toast } from 'react-toastify'
import { IPositionDrawing } from '../../../../generated-types/position-drawing'
import { invalidatePositionCache, useUpdatePosition } from '../../../../api/positions'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed'
import {
  AssignTemplates,
  CreatePosition,
  GenerateComponents,
  ManageTemplates,
  UploadOlex,
} from './PositionTableToolbarOptionsHelper'
import React, { memo, useMemo } from 'react'
import { Sediment } from 'src/enums'
import { Spinner } from 'src/components/Spinner'
import { IPaginatedResult } from 'src/api/paginated-result'

interface RenderCellLonLatProps {
  latitude: any
  longitude: any
}

export const RenderCellLonLat: React.FC<RenderCellLonLatProps> = memo(({ latitude, longitude }) => {
  return (
    <>
      {latitude || longitude ? (
        <Stack>
          <Box>
            {latitude ? (
              <Stack direction={'row'}>
                <GpsFixedIcon className="pt-1 pr-1" fontSize="inherit" color="primary" />
                <GpsToDms decimalGPS={latitude} isLongitude={false} />
              </Stack>
            ) : (
              ''
            )}
            {longitude ? (
              <Stack direction={'row'}>
                <GpsNotFixedIcon className="pt-1 pr-1" fontSize="inherit" color="primary" />
                <GpsToDms decimalGPS={longitude} isLongitude={true} />
              </Stack>
            ) : (
              ''
            )}
          </Box>
        </Stack>
      ) : (
        '/'
      )}
    </>
  )
})

export const PositionsTableStaticColumns: GridColumns = [
  {
    field: 'name',
    headerName: t('position', { ns: 'position' }),
    flex: 1,
    renderCell: params => params.row.name ?? 'N/A',
  },
  {
    field: 'reference',
    headerName: t('reference', { ns: 'position' }),
    flex: 1,
    renderCell: params => params.row.reference ?? 'N/A',
  },
  {
    field: 'latitude',
    headerName: t('latitude', { ns: 'common' }) + '/' + t('longitude', { ns: 'common' }),
    flex: 1.5,
    renderCell: params => <RenderCellLonLat latitude={params.row.latitude} longitude={params.row.longitude} />,
  },
  {
    field: 'mbl',
    headerName: t('mbl', { ns: 'position' }),
    flex: 1,
    renderCell: params => params.row.mbl ?? 'N/A',
  },
  {
    field: 'accidentLoad',
    headerName: t('accidentLoad', { ns: 'position' }),
    flex: 1,
    renderCell: params => params.row.accidentLoad ?? 'N/A',
  },
  {
    field: 'depth',
    headerName: t('depth', { ns: 'position' }),
    flex: 1,
    renderCell: params => params.row.depth ?? 'N/A',
  },
  {
    field: 'direction',
    headerName: t('direction', { ns: 'position' }),
    flex: 1,
    renderCell: params => params.row.direction ?? 'N/A',
  },
  {
    field: 'testLoad',
    headerName: t('testLoad', { ns: 'position' }),
    flex: 1,
    renderCell: params => params.row.testLoad ?? 'N/A',
  },
  {
    field: 'bottomAttachment',
    headerName: t('bottomAttachment', { ns: 'position' }),
    flex: 1.5,
    renderCell: params => params.row.bottomAttachment ?? 'N/A',
  },
  {
    field: 'sediment',
    headerName: t('sediment', { ns: 'position' }),
    flex: 1,
    renderCell: value => {
      return Sediment[value.value] ?? 'N/A'
    },
  },
]

export const PositionsTableToolbarOptions = ({
  customerId,
  localityId,
  mooringId,
  mooringType,
  selectedIds,
  positions,
  selectedPositionTypes,
}) => [
  {
    label: t('add-template', { ns: 'mooring' }),
    Component: (
      <ManageTemplates
        selectedIds={selectedIds}
        mooringId={mooringId}
        positions={positions}
        customerId={customerId}
        selectedPositionTypes={selectedPositionTypes}
      />
    ),
  },
  {
    label: t('addPosition', { ns: 'position' }),
    Component: <CreatePosition customerId={customerId} localityId={localityId} mooringId={mooringId} />,
  },
  {
    label: t('upload-olex', { ns: 'mooring' }),
    Component: (
      <UploadOlex customerId={customerId} localityId={localityId} mooringId={mooringId} mooringType={mooringType} />
    ),
    showSeparator: true,
  },
  {
    label: t('generateComponents', { ns: 'mooring' }),
    Component: (
      <GenerateComponents
        customerId={customerId}
        localityId={localityId}
        mooringId={mooringId}
        selectedIds={selectedIds}
        selectedPositionTypes={selectedPositionTypes}
      />
    ),
  },
  {
    label: t('assign-template', { ns: 'mooring' }),
    Component: (
      <AssignTemplates
        customerId={customerId}
        localityId={localityId}
        mooringId={mooringId}
        selectedIds={selectedIds}
        positions={positions}
        selectedPositionTypes={selectedPositionTypes}
      />
    ),
  },
]

interface PositionsTableTemplateRenderProps {
  position: IPositionWithDrawing
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  availablePositionDrawings?: IPaginatedResult<IPositionDrawing>
  isLoading?: boolean
}

export const PositionsTableTemplateRender: React.FC<PositionsTableTemplateRenderProps> = memo(
  ({ position, customerId, localityId, mooringId, availablePositionDrawings, isLoading }) => {
    const { mutateAsync: mutationUpdate } = useUpdatePosition(
      customerId,
      localityId,
      mooringId,
      position?.id as EntityId,
    )
    const positionDrawings = useMemo(() => {
      const positionDrawings = [
        ...(availablePositionDrawings?.items ?? []).filter(x => x.category == position.type),
        { id: -1, drawingNumber: t('n/a', { ns: 'common' }) } as IPositionDrawing,
      ]
      if (position.positionDrawing != null && !positionDrawings?.find(x => x.id == position.positionDrawingId)) {
        positionDrawings.push(position.positionDrawing)
      }
      return positionDrawings
    }, [availablePositionDrawings?.items, position.type])

    const value = positionDrawings?.find(x => x.id == position.positionDrawingId)?.id ?? -1

    const handleTemplateChange = ({ value, position }) => {
      const selectedPositionDrawing = positionDrawings?.find(x => x.id == value.props.value)
      const positionDrawingProducts = selectedPositionDrawing?.positionDrawingProducts
      if (positionDrawingProducts?.length == 0) {
        toast(t('noProductsAssignedInTheTemplate', { ns: 'mooring' }), { type: 'error' })
        return
      }
      const positionDrawingId = value.props.value == -1 ? null : value.props.value
      position.positionDrawingId = positionDrawingId
      mutationUpdate(position, {
        onSuccess: () => {
          toast(t('templateAssignedSuccessfully', { ns: 'mooring' }), { type: 'success' })
          invalidatePositionCache.getPositionsWithMinimalData(
            customerId as EntityId,
            localityId as EntityId,
            mooringId as EntityId,
          )
        },
      })
    }

    if (isLoading) return <Spinner size={'24px'} />

    if (positionDrawings?.length == 1)
      return <Typography variant="body2">{t('no-assigned-templates', { ns: 'mooring' })}</Typography>

    return (
      position && (
        <Box>
          <Select
            sx={{ width: 100, height: 35 }}
            value={value}
            onChange={(_, value) => handleTemplateChange({ value, position })}
            disabled={position.type === IPositionType.Cage}
          >
            {positionDrawings && positionDrawings.length > 1 ? (
              positionDrawings.map(positionDrawing => (
                <MenuItem key={positionDrawing.id} value={positionDrawing.id}>
                  {positionDrawing.drawingNumber}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={-1} value={-1}>
                <Typography variant="body2">{t('n/a', { ns: 'common' })}</Typography>
              </MenuItem>
            )}
          </Select>
        </Box>
      )
    )
  },
)

export default PositionsTableTemplateRender
