import { useNavigate, useParams } from 'react-router-dom'
import { IMooring, IPosition, IPositionType } from '../../../generated-types'
import { useTranslation } from 'react-i18next'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { PositionContent } from './PositionContent'
import { useGetPositionsForDropDownByMooringId } from 'src/api/positions'
import { Flex } from 'src/styles/flexComponent'
import { filterAndSortPositions, getThemeColor } from 'src/utils'
import { Padding } from 'src/styles/paddingComponent'
import { Radio, styled, Typography, useTheme } from '@mui/material'
import { MUIThemeColors } from 'src/mui-theme'
import { Card } from 'src/styles/Index'
import { AppRoutesNavigation } from 'src/app-routes'
import { useEffect, useMemo, useState } from 'react'
import { CenteredSpinner } from 'src/components/Spinner'

export const PositionContainer: React.FC<{ position: IPosition; mooringDetails: IMooring; loading: boolean }> = ({
  position,
  mooringDetails,
  loading,
}) => {
  const { t: translate } = useTranslation(['position', 'component', 'common'])
  const { customerId, localityId, mooringId, positionId } = useParams()
  const [filters, setFilters] = useState<IPositionType>(position?.type)
  const { data } = useGetPositionsForDropDownByMooringId(
    customerId as EntityId,
    localityId as EntityId,
    mooringId as EntityId,
  )

  const onChecked = (value, type) => {
    if (value.target.checked) {
      setFilters(type)
    }
  }

  useEffect(() => {
    if (position) {
      setFilters(position?.type)
    }
  }, [position])

  return (
    <div style={{ background: '#F1F5F7' }}>
      <CommonPageLayout
        enableBackButton
        titleSection={{ title: translate('positionDetailView') }}
        topAction={[
          <PositionTypeRadioButton
            filters={filters}
            key={'mooringLine'}
            type={IPositionType.MooringLine}
            title={translate('mooringLines', { ns: 'work-assignment' })}
            onChecked={onChecked}
          />,
          <PositionTypeRadioButton
            filters={filters}
            key={'corners'}
            type={IPositionType.Corner}
            title={translate('corners', { ns: 'work-assignment' })}
            onChecked={onChecked}
          />,
          <PositionTypeRadioButton
            filters={filters}
            key={'bridles'}
            type={IPositionType.Bridle}
            title={translate('bridles', { ns: 'work-assignment' })}
            onChecked={onChecked}
          />,
          <PositionTypeRadioButton
            filters={filters}
            key={'frameLines'}
            type={IPositionType.FrameLines}
            title={translate('frameLines', { ns: 'work-assignment' })}
            onChecked={onChecked}
          />,
        ]}
      >
        <Padding.pb10>
          <PositionsNavigationListContainer positions={data} filter={[filters]} selectedPosition={position} />
        </Padding.pb10>
        {loading ? (
          <CenteredSpinner />
        ) : (
          position && <PositionContent position={position} mooringDetails={mooringDetails} positionId={positionId} />
        )}
      </CommonPageLayout>
    </div>
  )
}

const PositionTypeRadioButton = ({ filters, onChecked, title, type }) => {
  return (
    <Flex.Row key={type}>
      <Radio checked={filters == type} onChange={value => onChecked(value, type)} />
      <Padding.pt8>
        <Typography variant="subtitle2">{title}</Typography>
      </Padding.pt8>
    </Flex.Row>
  )
}

export const PositionsNavigationListContainer: React.FC<{
  positions?: IPosition[]
  filter: IPositionType[]
  selectedPosition: IPosition
}> = ({ positions, filter, selectedPosition }) => {
  const hasIncludedSelectedPosition = useMemo(
    () => filterAndSortPositions(positions || [], filter)?.some(p => p.id == selectedPosition?.id),
    [positions, selectedPosition, filter],
  )
  const { positionId } = useParams()

  useEffect(() => {
    let mouseDown = false
    let startX, scrollLeft
    const slider = document.getElementById('container-positions')

    if (slider) {
      const startDragging = e => {
        mouseDown = true
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      }

      const stopDragging = e => {
        mouseDown = false
      }

      const move = e => {
        e.preventDefault()
        if (!mouseDown) {
          return
        }
        const x = e.pageX - slider.offsetLeft
        const scroll = x - startX
        slider.scrollLeft = scrollLeft - scroll
      }

      slider.addEventListener('mousemove', move, false)
      slider.addEventListener('mousedown', startDragging, false)
      slider.addEventListener('mouseup', stopDragging, false)
      slider.addEventListener('mouseleave', stopDragging, false)
    }
  }, [])

  useEffect(() => {
    const selectedPositionDiv = document.getElementById(`position-${positionId}`)
    if (selectedPosition && selectedPositionDiv) {
      selectedPositionDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }, [filter, positionId])

  return (
    <Flex.Row>
      {!hasIncludedSelectedPosition && selectedPosition != null && (
        <PositionCard enable={true} key={selectedPosition?.id}>
          <PositionNumberCard position={selectedPosition} enable={true} />
        </PositionCard>
      )}
      <PositionCardContainer
        id="container-positions"
        columnGap={'8px'}
        className="scrollbar"
        sx={{ overflowX: 'auto' }}
      >
        {positions &&
          filterAndSortPositions(positions, filter).map(position => {
            const enable = position != null && selectedPosition?.id == position.id
            if (enable) {
              return (
                <PositionCard enable={true} key={position.id}>
                  <PositionNumberCard position={position} enable={enable} />
                </PositionCard>
              )
            }
            return <PositionNumberCard key={position.id} position={position} enable={false} />
          })}
      </PositionCardContainer>
    </Flex.Row>
  )
}

const PositionNumberCard = ({ position, enable }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { customerId, localityId, mooringId } = useParams()

  return (
    <Padding.p8
      id={`position-${position.id}`}
      height={'45px'}
      minWidth={'65px'}
      className="hover-pointer"
      onClick={() => {
        navigate(
          `${AppRoutesNavigation.Localities.position(
            customerId as EntityId,
            localityId as EntityId,
            mooringId as EntityId,
            position?.id,
          )}#${position?.type}`,
        )
      }}
    >
      <Typography
        textAlign={'center'}
        width={'49px'}
        variant="h6"
        color={enable ? getThemeColor(theme, MUIThemeColors.white) : getThemeColor(theme, MUIThemeColors.primaryMain)}
      >
        {parseInt(position?.name) > 900 ? `M${parseInt(position?.name) - 900}` : position?.name}
      </Typography>
    </Padding.p8>
  )
}

const PositionCard = styled(Card)<{ enable: boolean }>`
  background-color: ${props =>
    props.enable ? getThemeColor(props.theme, MUIThemeColors.primaryMain) : null} !important;
  height: 50px;
  min-width: 65px;
  padding: 0px;
`

const PositionCardContainer = styled(Flex.Row)`
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`
