import { Box, Typography, styled, useTheme } from '@mui/material'
import { useLeafletContext } from '@react-leaflet/core'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MUIThemeColors } from 'src/mui-theme'
import { getThemeColor } from 'src/utils'


export const GestureHandlingComponent: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation(['map'])
  const context = useLeafletContext()
  const [showOverlay, setShowOverlay] = useState(false)
  let hideOverlayTimeout: NodeJS.Timeout | null
  const OVERLAY_HIDE_TIMEOUT = 600

  useEffect(() => {
    context.map.scrollWheelZoom.disable()
  }, [context])

  const handleWheelEvent = useCallback((event: WheelEvent) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault()
        context.map.scrollWheelZoom.enable()
        setShowOverlay(false)
      } else {
        setShowOverlay(true)
        context.map.scrollWheelZoom.disable()
      }

      if (hideOverlayTimeout) clearTimeout(hideOverlayTimeout)
      hideOverlayTimeout = setTimeout(() => setShowOverlay(false), OVERLAY_HIDE_TIMEOUT)
  }, [context])

  useEffect(() => {
    const container = context.map.getContainer()
    container.addEventListener('wheel', handleWheelEvent)
    return () => {
      container.removeEventListener('wheel', handleWheelEvent)
      if (hideOverlayTimeout) clearTimeout(hideOverlayTimeout)
    }
  }, [])

  return (
    <>
      {showOverlay && (
        <Overlay>
          <Typography variant="body1" fontWeight={600} lineHeight={'18px'} color={getThemeColor(theme, MUIThemeColors.white)}>
            {t('gesture-handling')}
          </Typography>
        </Overlay>
      )}
    </>
  )
}

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  backgroundColor: `${getThemeColor(theme, MUIThemeColors.primaryDark)}50`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}))
