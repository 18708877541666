import { Box, Checkbox, IconButton, Link, Tooltip } from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColumns,
  GridNativeColTypes,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import React, { useCallback } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit, MdExpandMore } from 'react-icons/md'
import GenericTable from '../../../components/table/GenericTable'
import { MainButton } from '../../../components/MainButton'
import { GlobalDocumentVersionTable } from './GlobalDocumentVersionTable'
import { staticGlobalDocumentColumns } from './constants'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { GlobalDocumentCreateDialog } from './GlobalDocumentCreateDialog'
import { globalDocumentEndpoints, invalidateGlobalDocument, useDeleteDocument } from '../../../api/global-documents'
import { GlobalDocumentVersionCreateDialog } from './GlobalDocumentVersionCreateDialog'
import { AlertDialog } from '../../../show-dialog'
import { toast } from 'react-toastify'
import { GlobalDocumentRuleContainerDialog } from './GlobalDocumentRuleContainerDialog'
import { GlobalDocumentEditDialog } from './GlobalDocumentEditDialog'
import { IGlobalDocument } from '../../../generated-types/global-documents'
import { Launch } from '@mui/icons-material'
import { invalidateDocumentCategoryCache } from '../../../api/document-category'
import { invalidateDocument } from '../../../api/document'

const VersionPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'value'>) => {
  const { id, value: isExpanded } = props
  const apiRef = useGridApiContext()
  const { t } = useTranslation(['global-documents', 'common'])

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector)

  const hasDetail = React.isValidElement(contentCache[id])

  return (
    <Tooltip title={`${t('view-versions', { ns: 'global-documents' })}`}>
      <IconButton
        size="medium"
        tabIndex={-1}
        disabled={!hasDetail}
        aria-label={isExpanded ? 'Close' : 'Open'}
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: theme =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
      >
        <MdExpandMore fontSize="inherit" />
      </IconButton>
    </Tooltip>
  )
}

export interface GlobalDocumentsTableProps {
  supplierId?: number
  customerId?: EntityId | undefined
  height: string | number
}

export const GlobalDocumentsTable: React.FC<GlobalDocumentsTableProps> = ({ supplierId, customerId, height }) => {
  const { mutateAsync } = useDeleteDocument()
  const { t } = useTranslation(['global-documents', 'common'])
  const { showDialog } = useDialogCtx()

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])
  const handleAddDocument = useCallback(() => {
    showDialog(GlobalDocumentCreateDialog, {
      title: t('add-document', { ns: 'global-documents' }),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: {
        supplierId: supplierId,
      },
    })
  }, [showDialog, supplierId, t])

  const handleEditDocument = (row: IGlobalDocument) => {
    showDialog(GlobalDocumentEditDialog, {
      title: t('edit-document', { ns: 'global-documents' }),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: {
        documentId: row.id,
        initialValues: row,
        customerId: customerId,
      },
    })
  }

  const handleDeleteDocument = useCallback(
    async (id: EntityId) => {
      showDialog(AlertDialog, {
        componentProps: {
          title: t('delete-document', { ns: 'global-documents' }),
          text: t('areYouSureDocument', { ns: 'global-documents' }),
          onConfirm: async pop => {
            await mutateAsync(id).then(
              () => {
                toast(t('deletedSuccessfully', { ns: 'common' }), { type: 'success' })
                invalidateGlobalDocument.getDocuments()
                customerId && invalidateDocumentCategoryCache.getDocumentCategoriesWithDocumentCountByKey()
                customerId && invalidateDocument.getDocuments(customerId)
                pop(true)
              },
              () => {
                toast(t('deletingError', { ns: 'common' }), { type: 'error' })
                pop(false)
              },
            )
          },
        },
      })
    },
    [t],
  )
  const handleAddNewVersion = useCallback(
    (documentId, documentTitle) => {
      showDialog(GlobalDocumentVersionCreateDialog, {
        title: t('add-version', { ns: 'global-documents' }),
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          documentId: documentId,
          documentTitle: documentTitle,
          supplierId: supplierId,
          customerId: customerId,
        },
      })
    },
    [showDialog, supplierId, t],
  )

  const handleAddRule = useCallback(
    (row: GridRowParams<any>) => {
      showDialog(GlobalDocumentRuleContainerDialog, {
        title: t('document-rules', { ns: 'global-documents' }),
        opts: {
          maxWidth: 'lg',
          fullWidth: true,
        },
        componentProps: {
          globalDocumentId: row.id,
          customerId: customerId,
        },
      })
    },
    [customerId, showDialog, t],
  )

  const columns: GridColumns = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        flex: 0.2,
        align: 'center',
        renderCell: params => <VersionPanelToggle id={params.id} value={params.value} />,
      },
      {
        field: 'isActive',
        headerName: t('active', { ns: 'common' }),
        flex: 0.3,
        type: 'checkbox',
        align: 'center',
        sortable: false,
        renderCell: params => {
          return (
            <>
              <Checkbox disabled checked={!!params.row.isActive} inputProps={{ 'aria-label': 'controlled' }} value="" />
            </>
          )
        },
      },
      {
        field: 'title',
        headerName: t('title', { ns: 'common' }),
        flex: 2,
        renderCell: ({ row }) => {
          return (
            <Link href={row.documentUrl} target="_blank" underline="hover" letterSpacing={2}>
              {row.title}
              <Launch />
            </Link>
          )
        },
      },
      ...(staticGlobalDocumentColumns(t) as GridColumns),
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: t('actions', {ns: 'common'}),
        width: 10,
        flex: 2,
        renderCell: ({ row }) => [
          <Tooltip title={`${t('edit', { ns: 'common' })}`} key={'edit'}>
            <IconButton onClick={() => handleEditDocument(row as IGlobalDocument)}>
              <MdEdit size={24} />
            </IconButton>
          </Tooltip>,
          <Tooltip title={`${t('delete', { ns: 'common' })}`} key={'delete'}>
            <IconButton onClick={() => handleDeleteDocument(row.id)}>
              <MdDelete size={24} />
            </IconButton>
          </Tooltip>,
          <Box key={'add-version'} paddingLeft={1}>
            <MainButton
              variant="contained"
              sx={{ minWidth: '120px', minHeight: '24px', textTransform: 'none' }}
              onClick={() => handleAddNewVersion(row.id, row.title)}
            >
              {t('add-version', { ns: 'global-documents' })}
            </MainButton>
          </Box>,
          <Box key={'add-rule'} paddingLeft={1}>
            <MainButton
              variant="contained"
              onClick={() => handleAddRule(row)}
              sx={{ minWidth: '120px', minHeight: '24px', textTransform: 'none' }}
            >
              {t('add-rule', { ns: 'global-document-rules' })}
            </MainButton>
          </Box>,
        ],
      },
    ],
    [t],
  )

  return (
    <GenericTable
      id="global-document-table"
      columns={columns}
      enableServerPagination={supplierId != undefined}
      queryUrl={globalDocumentEndpoints.getDocuments()}
      queryParams={{ supplierId }}
      pageSize={10}
      rowColor="#e5e4e2"
      enableToolbar={true}
      toolbarOptions={[
        {
          label: t('add-document', { ns: 'global-documents' }),
          Component: (
            <MainButton disabled={supplierId == undefined} onClick={handleAddDocument}>
              {t('add-document', { ns: 'global-documents' })}
            </MainButton>
          ),
        },
      ]}
      getDetailPanelContent={({ row }) => {
        return <GlobalDocumentVersionTable globalDocumentId={row.id} customerId={customerId} />
      }}
      getDetailPanelHeight={() => 'auto'}
      detailPanelExpandedRowIds={detailPanelExpandedRowIds}
      onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
      noRowsMessage={
        supplierId == undefined
          ? t('supplier-error', { ns: 'global-documents' })
          : t('no-documents', { ns: 'global-documents' })
      }
      onCellClick={({ row, field }) => {
        if (field !== 'actions' && field !== 'title' && field != '__detail_panel_toggle__') handleAddRule(row)
      }}
      autoHeight={false}
      height={height}
    />
  )
}
