import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSharedFileUrl } from 'src/api/file'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { Spinner } from 'src/components/Spinner'
import { ShowDialogProps } from 'src/show-dialog'
import { Flex } from 'src/styles/flexComponent'

interface Props extends ShowDialogProps {
  fileUrl: string
}

export const ShowFileDialog: React.FC<Props> = ({ pop, fileUrl }) => {
  const [loading, setLoading] = useState(true)
  const { data, isLoading } = useGetSharedFileUrl(fileUrl)

  const handleLoad = () => {
    setLoading(false)
  }
  const { t } = useTranslation(['common'])
  const isWordOrExcelFile =
    fileUrl?.endsWith('.doc') || fileUrl?.endsWith('.docx') || fileUrl?.endsWith('.xls') || fileUrl?.endsWith('.xlsx')

  const viewerUrl = isWordOrExcelFile
    ? 'https://docs.google.com/gview?url=http://localhost:5000/api/files/download-file/afb6fde4-1211-4b56-b035-819145668547i&embedded=true'
    : data

  return (
    <GenericDialogLayout title={t('preview-document')} pop={pop} sx={{ height: '80vh' }}>
      {loading && (
        <Flex.Column width={'100%'} height={'99%'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex.Column>
      )}
      {data && <iframe src={viewerUrl} width={'100%'} height={'99%'} style={{ border: 'none' }} onLoad={handleLoad} />}
    </GenericDialogLayout>
  )
}
