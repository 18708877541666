import React from 'react'
import { getCustomerUsers } from '../../../http'
import { permissionBinding, permissionLevel } from '../../../constant'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import { FacilityTypes, RoleTypes } from '../../../enums'
import { ILocalityGroup } from '../../../generated-types'
import { invalidateAccessGroups, useGetLocalityGroupUsers } from '../../../api/access-group'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LocalitiesInLocalityGroups from './LocalitiesInLocalityGroups'
import { AuthorizationUsersMapping } from '@lovoldsystem/authorization.client'
import { IAuthUserInitialValues } from '@lovoldsystem/authorization.client/components/users/authorizationMapping.action'
import { FunctionElementsParentsIds } from '../../../enums/auth'
import { Card } from 'src/styles/Index'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

interface props {
  localityGroup: ILocalityGroup
}

const LocalityGroupDetailPanel: React.FC<props> = ({ localityGroup }) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation('access-control')
  const { data } = useGetLocalityGroupUsers(customer.id, localityGroup.id)
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const theme = useTheme()
  return (
    <Box className="px-4 py-2 pt-2 pb-2 shadow-md">
      <Stack spacing={2}>
        <Card className="p-8-core" sx={{ boxShadow: `0px 1px 2px ${getThemeColor(theme, MUIThemeColors.black)}29` }}>
          <Typography fontWeight={'600'} color={getThemeColor(theme, MUIThemeColors.primaryMain)} variant="subtitle1">
            {t('localities', { ns: 'locality-group' })}
          </Typography>
          <LocalitiesInLocalityGroups localityGroupId={localityGroup.id} />
        </Card>
        <AuthorizationUsersMapping
          invalidateUsers={() => {
            invalidateAccessGroups.getLocalityGroupUsers(customer.id, localityGroup.id)
          }}
          showDialog={showDialog}
          showConfirmDialog={showConfirmDialog}
          permissionBinding={permissionBinding}
          title={t('locality-group-users')}
          functionElementParents={[FunctionElementsParentsIds.LocalityPortal]}
          permissionLevel={permissionLevel}
          initialValues={
            {
              facilityId: localityGroup.id,
              facilityName: localityGroup.name,
              type: FacilityTypes.localityGroup,
              facilityTypeText: t('locality-group'),
            } as IAuthUserInitialValues
          }
          clientId={customer.id}
          roleTypes={[RoleTypes.Locality]}
          facilityUsers={data || []}
          useGetUsers={getCustomerUsers}
        />
      </Stack>
    </Box>
  )
}

export default LocalityGroupDetailPanel
