import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useCreateMainComponent, invalidateMainComponentCache } from '../../../api/main-component'
import { invalidateMainComponentTypeCache } from '../../../api/main-component-type'
import { IMainComponent } from '../../../generated-types/main-component'
import { ShowDialogProps } from '../../../show-dialog'
import MainComponentForm from './MainComponentForm'
import { getTranslatedText } from '../../../utils/translations'

interface CreateMainComponentDialogProps extends ShowDialogProps {
  customerId: EntityId
  localityId?: EntityId
  componentTypeId?: number
  mainComponentTypeName?: string
  initialValues?: IMainComponent
}
export const CreateMainComponentDialog: React.FC<CreateMainComponentDialogProps> = ({
  pop,
  customerId,
  localityId,
  componentTypeId,
  mainComponentTypeName,
  initialValues,
}) => {
  const { t } = useTranslation(['main-component', 'common', 'certificate'])

  const { mutateAsync, error } = useCreateMainComponent(customerId)

  useEffect(() => {
    if (error != null) {
      if (error['data'] == 'SameMainComponentName') {
        toast(t('sameMainComponentName'), { type: 'error' })
      }
      if (error['data'] == 'SerialNumberExists') {
        toast(t('sameSerialNumber'), { type: 'error' })
      }
      if (error['data'] == 'NotAValidCertificate') {
        toast(t('NotAValidCertificate', { ns: 'certificate' }), { type: 'error' })
      }
      if (error['data'] == 'ValidUntilLessThanCertificateDate') {
        toast(t('ValidUntilLessThanCertificateDate', { ns: 'certificate' }), { type: 'error' })
      }
    }
  }, [error, t])

  const onSubmit = async (form, isCloneable = false) => {
    await mutateAsync(
      { ...form, selectedLocalityId: localityId },
      {
        onSuccess: () => {
          toast(t('successfullyAdded', { ns: 'common-api-message' }), { type: 'success' })
          invalidateMainComponentCache.getMainComponentsByComponentType(customerId, form.typeId as EntityId)
          invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId)
          invalidateMainComponentCache.invalidateEquipment(customerId, { localityId })
          if (!isCloneable) {
            pop()
          }
        },
      },
    )
  }

  return (
    <MainComponentForm
      title={
        mainComponentTypeName
          ? `${t('addMainComponent')} - ${getTranslatedText(mainComponentTypeName)}`
          : t('addMainComponent')
      }
      onSubmit={onSubmit}
      onSubmitAlter={onSubmit}
      onCancel={() => pop()}
      initialValues={
        initialValues ??
        ({
          mainComponentTypeName: mainComponentTypeName,
          typeId: componentTypeId,
        } as IMainComponent)
      }
      customerId={customerId}
    />
  )
}
