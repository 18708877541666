import * as React from 'react'
import Box from '@mui/material/Box'
import { FormCheckbox, FormFileCoreUpload, FormSelectField, FormTextField } from '../../components/FormCore'
import { t as translate } from 'i18next'
import { IDeviationPriority } from '../../generated-types/deviation-priority'
import { Grid } from '@mui/material'
import { IDeviationMeasure } from '../../generated-types/deviation-measure'
import { IUser } from '../../generated-types'
import { EnumToSelectTypes } from '../../generated-types/select-type'
import { useGetCustomerUsers } from '../../api/customer'
import BasicDatePicker from '../../components/DateTimePicker'
import { IFormType } from '../../generated-types/form-type'
import KeywordsSelectField from './KeywordsSelectField'
import { useEffect } from 'react'
import { UserDetail, getUserDetail } from '../../utils/getUserDetails'
import { useFormContext } from 'react-hook-form'
import { ReactComponent as FileIcon } from 'src/assets/svg/fileUploadIcon.svg'
import { StyledTypography } from 'src/screens/localities/documents/components/CreateDocumentDialog'

interface ISubmitDeviationFormStepProps {
  customerId: EntityId
  localityId?: EntityId
  formType?: IFormType
  scheduledTaskId?: EntityId
}

const DeviationFinalForm: React.FC<ISubmitDeviationFormStepProps> = ({
  customerId,
  localityId,
  formType = IFormType.Create,
  scheduledTaskId,
}) => {
  const { getValues, watch } = useFormContext()

  const deviationPriorities = EnumToSelectTypes(IDeviationPriority, 'deviation')
  const deviationMeasures = EnumToSelectTypes(IDeviationMeasure, 'deviation')

  const [customerUsers, setCustomerUsers] = React.useState([] as IUser[])
  const { data: customerAdminUserList } = useGetCustomerUsers(customerId)

  useEffect(() => {
    setCustomerUsers(customerAdminUserList as IUser[])
  }, [customerAdminUserList])

  const updateFormConfig = { display: formType == IFormType.Update ? 'none' : 'block' }

  return (
    <Box width={'100%'}>
      {localityId && <FormTextField type="hidden" name="localityId" value={localityId} />}
      {getValues('mooring')?.id && <FormTextField type="hidden" name="mooringId" value={getValues('mooring')?.id} />}
      {getValues('position') && getValues('position').id && (
        <FormTextField type="hidden" name="positionId" value={getValues('position')?.id} />
      )}
      {getValues('component') && getValues('component').id && (
        <FormTextField type="hidden" name="componentId" value={getValues('component')?.id} />
      )}
      {getValues('mainComponent') && getValues('mainComponent').id && (
        <FormTextField type="hidden" name="mainComponentId" value={getValues('mainComponent')?.id} />
      )}
      {scheduledTaskId && <FormTextField type="hidden" name="scheduledTaskId" value={scheduledTaskId} />}
      {getValues('type') != undefined && getValues('type') >= 0 && (
        <FormTextField name="type" type="hidden" value={getValues('type')} />
      )}
      <FormTextField name="title" label={translate('title', { ns: 'common' })} />
      <Box sx={updateFormConfig}>
        <Grid container>
          <Grid item xs={6} sm={4}>
            <FormSelectField
              name="priority"
              data={deviationPriorities ?? []}
              label={translate('priority', { ns: 'common' })}
              getOptionLabel={option => option.value}
              renderOption={(props: any, option) => (
                <Box {...props} key={option}>
                  {translate(option.value.toLocaleLowerCase(), { ns: 'common' })}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o.id === v.id}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormSelectField
              name="responsiblePersonId"
              data={customerUsers ?? []}
              label={translate('responsiblePerson', { ns: 'deviation' })}
              getOptionLabel={option => getUserDetail(option, UserDetail.FullName)}
              renderOption={(props: any, option) => (
                <Box {...props} key={option.id}>
                  {getUserDetail(option, UserDetail.FullName)}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o.id === v.id}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <BasicDatePicker name="dueDate" label={translate('dueDate', { ns: 'common' })} />
          </Grid>
        </Grid>
      </Box>
      <KeywordsSelectField />
      <Grid container>
        <Grid item xs={6} sm={4}>
          <FormSelectField
            name="measure"
            data={deviationMeasures ?? []}
            label={translate('measure', { ns: 'deviation' })}
            getOptionLabel={option => option.value}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {option.value}
              </Box>
            )}
            isOptionEqualToValue={(o, v) => o.id === v.id}
          />
        </Grid>
        <Grid item xs={6} sm={4} sx={updateFormConfig}>
          <FormTextField name="performedBy" label={translate('performedBy', { ns: 'common' })} />
        </Grid>
      </Grid>
      <FormTextField name="description" label={translate('description', { ns: 'common' })} multiline rows={2} />
      <FormTextField name="reason" label={translate('reason', { ns: 'deviation' })} multiline rows={2} />
      <FormTextField name="measures" label={translate('measures', { ns: 'deviation' })} multiline rows={2} />
      <Grid container>
        <Grid item xs={6} md={3} lg={3}>
          <FormCheckbox
            sx={{ color: '#00000061' }}
            name="visualDeviation"
            styledLabelComponent={
              <StyledTypography>{translate('visualDeviation', { ns: 'deviation' })}</StyledTypography>
            }
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <FormCheckbox
            sx={{ color: '#00000061', '.MuiFormControlLabel-label': { fontWeight: '400' } }}
            name="measuredDeviation"
            styledLabelComponent={
              <StyledTypography>{translate('measuredDeviation', { ns: 'deviation' })}</StyledTypography>
            }
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <FormCheckbox
            sx={{ color: '#00000061' }}
            name="normalWear"
            disabled={watch('abnormalWear')}
            styledLabelComponent={<StyledTypography>{translate('normalWear', { ns: 'deviation' })}</StyledTypography>}
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <FormCheckbox
            sx={{ color: '#00000061' }}
            name="abnormalWear"
            disabled={watch('normalWear')}
            styledLabelComponent={<StyledTypography>{translate('abnormalWear', { ns: 'deviation' })}</StyledTypography>}
            defaultValue={false}
          />
        </Grid>
      </Grid>
      <FormFileCoreUpload
        label={translate('fileName', { ns: 'common' })}
        name="fileIds"
        mapFileKey="id"
        multiple
        FileIcon={FileIcon}
        height="175px"
        initialFiles={getValues('files')}
      />
    </Box>
  )
}

export default DeviationFinalForm
