import { t } from 'i18next'
import { FC, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  invalidatePositionCache,
  useGenerateComponentsForPositions,
  useConfirmOlexPositions,
} from '../../../../api/positions'
import { GridActionButton, GridActionButtonProps } from '../../../../components/common/GridActionButton'
import { useDialogCtx } from '../../../../hooks/context-hooks'
import { CreatePositionDialog } from '../../positions/CreatePositionDialog'
import { SetPositionTemplateDialog } from '../SetPositionTemplateDialog'
import {
  AutorenewRounded,
  AddCircleOutline,
  AssignmentTurnedInOutlined,
  FileUpload,
  ControlPoint,
} from '@mui/icons-material'
import { OlexFileUploader } from '../../../../components/OlexFileUploader'
import { IMooringType, IPosition, IPositionType } from '../../../../generated-types'
import { OlexUploadConfirmDialog } from '../../../../components/OlexUploadConfirmDialog'
import { invalidateMooringCache } from '../../../../api/moorings'
import { useTheme } from '@mui/material'
import { ManageTemplatesDialog } from '../ManageTemplatesDialog'
import { invalidateComponentsCache } from 'src/api/components'
import { EnlargeModal } from '../Components/EnlargeModal'
import { getThemeColor } from 'src/utils'
import { GenericMapSvgContainer } from '../../positions/MapSvgContainer'
import { ReactComponent as SVGIcon } from 'src/assets/svg/svg-action-btn.svg'
import { MUIThemeColors } from 'src/mui-theme'

interface UploadOlexProps extends GridActionButtonProps { 
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  mooringType: IMooringType
}

export const UploadOlex:FC<UploadOlexProps> = ({ customerId, localityId, mooringId, mooringType,...rest }) => {
  const theme = useTheme()

  const { mutateAsync: confirmPositionsAsync, isLoading: isConfirming } = useConfirmOlexPositions(
    customerId,
    localityId,
  )

  const handleConfirm = (updatedPositions: IPosition[], pop) => {
    confirmPositionsAsync({ positions: updatedPositions, mooringId: mooringId as EntityId }).then(() => {
      toast(t('updatedSuccessfully', { ns: 'common' }), { type: 'success' })
      invalidateMooringCache.getMooringsForMap(customerId as EntityId, localityId as EntityId)
      invalidatePositionCache.getPositions(customerId as EntityId, localityId as EntityId, mooringId as EntityId)
      invalidateMooringCache.getGetMooring(customerId as EntityId, localityId, mooringId, {
        isWithAllInfo: true,
      })
      invalidatePositionCache.getPositionsWithMinimalData(customerId as EntityId, localityId as EntityId, mooringId as EntityId)
      pop(true)
    })
  }

  const { showDialog } = useDialogCtx()

  const onUploadOlex = async () => {
    const added = await showDialog(
      OlexFileUploader,
      {
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        componentProps: {
          customerId: customerId,
          localityId: localityId,
          mooringId: mooringId,
          handleConfirm: handleConfirmDialog,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const handleConfirmDialog = async (newPositions: IPosition[]) => {
    await showDialog(
      OlexUploadConfirmDialog,
      {
        opts: {
          maxWidth: '80vw',
          fullWidth: true,
        },
        componentProps: {
          isConfirming,
          updatedPositions: newPositions,
          mooringType,
          handleConfirm,
        },
      },
      undefined,
      true,
    )
  }

  return (
    <>
      <GridActionButton
        onClick={onUploadOlex}
        toolTipTitle={t('upload-olex', { ns: 'mooring' })}
        bgColor={getThemeColor(theme,MUIThemeColors.white)}
        height="44px"
        width="44px"
        buttonIcon={
          <FileUpload htmlColor={getThemeColor(theme,MUIThemeColors.primaryMain)} style={{ fontSize: '30px' }} />
        }
        {...rest}
      />
    </>
  )
}

interface GenerateComponentsProps extends GridActionButtonProps { 
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  selectedIds: EntityId[]
  selectedPositionTypes: IPositionType[]
}

export const GenerateComponents : FC<GenerateComponentsProps> = ({ customerId, localityId, mooringId, selectedIds, selectedPositionTypes,...rest }) => {
  const theme = useTheme()
  const { showConfirmDialog } = useDialogCtx()
  const { mutateAsync } = useGenerateComponentsForPositions(customerId, localityId, mooringId)

  const onGenerateClick = async () => {
    showConfirmDialog(
      t('generateComponents', { ns: 'mooring' }),
      t('areYouSureGenerateComponents', { ns: 'mooring' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await mutateAsync({ selectedPositionsIds: selectedIds as number[] })
          .then(({ data }: { data: string }) => {
            invalidatePositionCache.getPositions(customerId as EntityId, localityId, mooringId)
            selectedIds.map(selectedId =>
              invalidateComponentsCache.getComponents(customerId as EntityId, selectedId as EntityId),
            )
            invalidatePositionCache.getPositionsWithMinimalData(customerId as EntityId, localityId as EntityId, mooringId as EntityId)
            toast.success(t(data, { ns: 'component' }))
            pop()
          })
          .catch(({ data }: { data: string }) => {
            toast.error(t(data, { ns: 'common-api-message' }))
          })
      },
    )
  }

  return (
    <GridActionButton
      onClick={onGenerateClick}
      toolTipTitle={t('generate-components', { ns: 'mooring' })}
      bgColor={
        selectedIds.length > 0 && !selectedPositionTypes?.includes(IPositionType.Cage)
          ? getThemeColor(theme,MUIThemeColors.white)
          : getThemeColor(theme,MUIThemeColors.secondaryLight)
      }
      height="44px"
      width="44px"
      buttonIcon={
        <AutorenewRounded
          style={{ fontSize: '30px' }}
          htmlColor={
            selectedIds.length > 0 && !selectedPositionTypes?.includes(IPositionType.Cage)
              ? getThemeColor(theme,MUIThemeColors.primaryMain)
              : getThemeColor(theme,MUIThemeColors.secondaryDark)
          }
        />
      }
      disabled={selectedIds.length == 0 || selectedPositionTypes?.includes(IPositionType.Cage)}
      {...rest}
    />
  )
}

interface AssignTemplatesProps extends GridActionButtonProps { 
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  selectedIds: EntityId[]
  positions: IPosition[]
  selectedPositionTypes?: IPositionType[]
}

export const AssignTemplates:FC<AssignTemplatesProps> = ({
  customerId,
  localityId,
  mooringId,
  selectedIds,
  positions,
  selectedPositionTypes : selectedTypes,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const selectedPositions = selectedIds.map(id => positions.find(row => row.id === id))
  const handleOnClick = () => {
    const positionTypes = new Set(selectedPositions.map(x => x?.type))
    if (positionTypes.size > 1) {
      toast(t('selected-position-type-validation', { ns: 'mooring' }), { type: 'error' })
    } else {
      setOpen(true)
    }
  }

  const selectedPositionTypes = useMemo(() => selectedTypes ?? selectedPositions.map(x => x?.type), [selectedPositions,selectedTypes])

  return (
    <>
      <GridActionButton
        onClick={handleOnClick}
        toolTipTitle={t('assign-template', { ns: 'mooring' })}
        bgColor={
          selectedIds.length > 0 && !selectedPositionTypes?.includes(IPositionType.Cage)
            ? getThemeColor(theme,MUIThemeColors.white)
            : getThemeColor(theme,MUIThemeColors.secondaryLight)
        }
        height="44px"
        width="44px"
        buttonIcon={
          <AssignmentTurnedInOutlined
            style={{ fontSize: '30px' }}
            htmlColor={
              selectedIds.length > 0 && !selectedPositionTypes?.includes(IPositionType.Cage)
                ? getThemeColor(theme,MUIThemeColors.primaryMain)
                : getThemeColor(theme,MUIThemeColors.secondaryDark)
            }
          />
        }
        disabled={selectedPositionTypes?.includes(IPositionType.Cage) || selectedIds.length == 0}
        {...rest}
      />
      <SetPositionTemplateDialog
        open={open}
        setOpen={setOpen}
        customerId={customerId}
        localityId={localityId}
        mooringId={mooringId}
        selectedPositions={(selectedPositions ?? []) as IPosition[]}
      />
    </>
  )
}

interface CreatePositionProps extends GridActionButtonProps { 
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
}

export const CreatePosition : FC<CreatePositionProps> = ({ customerId, localityId, mooringId, ...rest}) => {
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  const onAddPosition = async () => {
    const added = await showDialog(
      CreatePositionDialog,
      {
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          customerId: customerId,
          localityId: localityId,
          mooringId: mooringId,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <GridActionButton
      onClick={onAddPosition}
      toolTipTitle={t('add-position', { ns: 'mooring' })}
      bgColor={getThemeColor(theme,MUIThemeColors.white)}
      height="44px"
      width="44px"
      buttonIcon={
        <AddCircleOutline style={{ fontSize: '30px' }} htmlColor={getThemeColor(theme,MUIThemeColors.primaryMain)} />
      }
      {...rest}
    />
  )
}

interface SVGButtonProps extends GridActionButtonProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
}

export const ViewSVGButton: FC<SVGButtonProps> = ({ customerId, localityId, mooringId, ...rest }) => {
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  const enlargeSVG = async () => {
    await showDialog(
      EnlargeModal,
      {
        opts: {
          maxWidth: '95vw',
          fullWidth: true,
          fullScreen: true,
        },
        sx: {
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              backgroundColor: `${getThemeColor(theme,MUIThemeColors.white)}`,
              borderRadius: '8px',
            },
            padding: '20px',
            boxShadow: `0px 2px 8px ${getThemeColor(theme,MUIThemeColors.secondaryLight)}`,
          },
        },
        componentProps: {
          content: (
            <GenericMapSvgContainer
              customerId={customerId}
              localityId={localityId}
              mooringId={mooringId as EntityId}
              height={'95vh'}
            />
          ),
          pop: () => {},
          iconTop: 20,
          iconRight: 20,
        },
        isAutoCloseDisabled: false,
      },
      undefined,
      true,
    )
  }

  return (
    <GridActionButton
      onClick={enlargeSVG}
      toolTipTitle={t('view-svg', { ns: 'mooring' })}
      bgColor={getThemeColor(theme,MUIThemeColors.white)}
      height="44px"
      width="44px"
      buttonIcon={
        <SVGIcon
          style={{ fontSize: '30px', paddingLeft: '4px' }}
          color={getThemeColor(theme,MUIThemeColors.primaryMain)}
        />
      }
      {...rest}
    />
  )
}

export const ManageTemplates = ({ selectedIds, mooringId, positions, customerId, selectedPositionTypes }) => {
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  const onManageTemplates = async () => {
    const added = await showDialog(
      ManageTemplatesDialog,
      {
        opts: {
          maxWidth: '100%',
          fullWidth: true,
        },
        componentProps: {
          positionId: selectedIds[0],
          mooringId: mooringId,
          positions: positions,
          customerId: customerId,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <GridActionButton
      onClick={onManageTemplates}
      toolTipTitle={t('assign-template', { ns: 'mooring' })}
      bgColor={getThemeColor(theme,MUIThemeColors.white)}
      height="44px"
      width="153px"
      buttonIcon={
        <ControlPoint
          style={{ fontSize: '30px' }}
          htmlColor={
            selectedIds.length == 1 && !selectedPositionTypes?.includes(IPositionType.Cage)
              ? getThemeColor(theme,MUIThemeColors.primaryMain)
              : getThemeColor(theme,MUIThemeColors.secondaryDark)
          }
        />
      }
      buttonText={t('template', { ns: 'mooring' })}
      disabled={
        selectedIds.length == 0 || selectedIds.length > 1 || selectedPositionTypes?.includes(IPositionType.Cage)
      }
    />
  )
}
