import { Box } from '@mui/system'
import { FC, useCallback } from 'react'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { AlertDialog } from '../../../show-dialog'
import { AuthorizationUsersMapping } from '@lovoldsystem/authorization.client'
import { permissionBinding, permissionLevel } from '../../../constant'
import { FacilityTypes, RoleTypes } from '../../../enums'
import { Paper, Stack } from '@mui/material'
import { getCustomerUsers } from '../../../http'
import { ServiceStationGroupsTable } from '../service-station-group/ServiceStationGroupsTable'
import {
  invalidateServiceStationCache,
  serviceStationEndpoints,
  useRemoveServiceStationGroupFromServiceStation,
} from '../../../api/service-station'
import { toast } from 'react-toastify'
import { MainButton } from '../../../components/MainButton'
import { useTranslation } from 'react-i18next'
import { AddServiceStationGroupsDialog } from './AddServiceStationGroups'
import { IServiceStationGroup } from '../../../generated-types'
import { serviceStationQueryKey } from '../../../query-keys'
import { useGetAccessGroupForServiceStation } from '../../../api/access-group'
import { IAuthUserInitialValues } from '@lovoldsystem/authorization.client/components/users/authorizationMapping.action'
import { FunctionElementsParentsIds } from '../../../enums/auth'

export const ServiceStationContainer: FC<{
  serviceStationId: EntityId
  serviceStationName: string
  groups?: IServiceStationGroup[]
}> = ({ serviceStationId, serviceStationName, groups }) => {
  const { t } = useTranslation('service-station')
  const { customer } = useCustomerCtx()
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { data } = useGetAccessGroupForServiceStation(customer.id, serviceStationId)
  const { mutateAsync: removeServiceStationGroup } = useRemoveServiceStationGroupFromServiceStation(
    customer.id,
    serviceStationId,
  )

  const handleServiceStationGroupRemove = useCallback(async id => {
    showDialog(
      AlertDialog,
      {
        componentProps: {
          title: t('remove-service-station-group', { ns: 'service-station' }),
          acceptText: t('remove', { ns: 'common' }),
          text: t('remove-confirmation-message', { ns: 'service-station' }),
          onConfirm: async pop => {
            await removeServiceStationGroup(id, {
              onSuccess: () => {
                toast.success(t('service-station-group-removed-successfully', { ns: 'service-station' }))
                invalidateServiceStationCache.getServiceStationGroups(customer.id, serviceStationId)
              },
              onError: () => {
                toast.error(t('service-station-group-removed-error', { ns: 'service-station' }))
              },
            })
            pop()
          },
        },
      },
      undefined,
      true,
    )
  }, [])

  const handleServiceStationGroups = useCallback(() => {
    showDialog(
      AddServiceStationGroupsDialog,
      {
        componentProps: {
          customerId: customer.id,
          title: t('manage-service-station-groups', { ns: 'service-station' }),
          serviceStationId: serviceStationId,
          groups: groups,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }, [])

  return (
    <Box className="px-4 py-2 mt-2 mb-2 shadow-md">
      <Stack spacing={2}>
        <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', p: 2, borderRadius: '5px' }}>
          <ScreenTitle title={t('service-station-groups', { ns: 'service-station' })} />
          <ServiceStationGroupsTable
            queryUrl={serviceStationEndpoints.getServiceStationGroups(
              customer.id as number,
              serviceStationId as number,
            )}
            height="300px"
            generateQueryKey={(page, pageSize) =>
              serviceStationQueryKey.GET_SERVICE_STATION_GROUPS(customer.id, serviceStationId, { page, pageSize })
            }
            handleRemove={handleServiceStationGroupRemove}
            toolbarOptions={[
              {
                label: t('add-service-station-group', { ns: 'service-station-group' }),
                Component: (
                  <MainButton onClick={handleServiceStationGroups}>
                    {t('add-service-station-group', { ns: 'service-station-group' })}
                  </MainButton>
                ),
              },
            ]}
          />
        </Paper>

        {customer.id && (
          <AuthorizationUsersMapping
            invalidateUsers={() => {
              invalidateServiceStationCache.getUsers(customer.id, serviceStationId)
            }}
            showDialog={showDialog}
            showConfirmDialog={showConfirmDialog}
            title={t('service-station-users', { ns: 'service-station' })}
            permissionLevel={permissionLevel}
            functionElementParents={[FunctionElementsParentsIds.ServiceStationPortal]}
            initialValues={
              {
                facilityId: serviceStationId as number,
                facilityName: serviceStationName,
                type: FacilityTypes.serviceStation,
                facilityTypeText: t('service-station', { ns: 'service-station' }),
              } as IAuthUserInitialValues
            }
            clientId={customer.id}
            roleTypes={[RoleTypes.ServiceStation]}
            facilityUsers={data || []}
            useGetUsers={getCustomerUsers}
            permissionBinding={permissionBinding}
          />
        )}
      </Stack>
    </Box>
  )
}
