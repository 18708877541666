import { Box, Select, MenuItem, FormHelperText, InputLabel, FormControl, Autocomplete, Chip, Grid } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { AppForm, AppFormProps, FormFileCoreUpload, FormTextField } from '../../../components/FormCore'
import { FileType } from '../../../enums'
import { IProduct, ISupplier, IUnit } from '../../../generated-types'
import { useZodForm } from '../../../hooks/zod-form'
import { useGetProductsBySupplierId } from '../../../api/product'
import { useGetAdminSuppliers } from '../../../api/supplier'
import { useGetUnits } from '../../../api/unit'
import BasicDatePicker from '../../../components/DateTimePicker'
import { useGetProductCertificationByProductId } from '../../../api/product-certification'
import { useGetComponents } from '../../../api/components'
import { Controller } from 'react-hook-form'
import { APIResultCount } from '../../../constant'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { MainButton } from '../../../components/MainButton'
import { toast } from 'react-toastify'
import { AppTextField } from 'src/components/AppTextField'
import { Spinner } from 'src/components/Spinner'

interface ComponentFormProps extends AppFormProps {
  customerId: EntityId
  positionId: EntityId
  title: string
  isLoading: boolean
}

export const ComponentForm: React.FC<ComponentFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  customerId,
  positionId,
  title,
  isLoading,
}) => {
  const { t: translate } = useTranslation(['component', 'common'])

  const schema = z.object({
    productId: z.union([z.number(), z.string().nonempty(translate('productIsRequired'))]),
    productNumber: z.string().nonempty(translate('productNumberIsRequired')),
    productDescription: z.string().nonempty(translate('productDescriptionIsRequired')),
    supplierId: z.union([z.number(), z.string().nonempty(translate('supplierIsRequired'))]),
    quantity: z.union([
      z
        .number()
        .min(1, { message: translate('quantityIsRequired') })
        .positive(translate('quantityCantBeNegative')),
      z
        .string()
        .min(1, { message: translate('quantityIsRequired') })
        .refine(v => !isNaN(Number(v)) && Number(v) > 0, { message: translate('quantityCantBeNegative') }),
    ]),
    positionDrawingNumber: z.union([
      z.number().min(1, translate('drawingInNumberIsRequired')).positive(translate('drawingInNumberCantBeNegative')),
      z
        .string()
        .min(1, translate('drawingInNumberIsRequired'))
        .refine(v => !isNaN(Number(v)) && Number(v) > 0, { message: translate('drawingInNumberCantBeNegative') }),
    ]),
    unitId: z.union([z.number(), z.string().nonempty(translate('unitIsRequired'))]),
    notes: z.string(),
    installationDate: z.date().or(z.string()),
    imageUrl: z.string().optional().or(z.null()),
    overriddenLifetime: z.string(),
    minimumBreakingLoad: z.string(),
    potencialCertificateBN: z.string().optional(),
    fileId: z.union([z.string(), z.number()]).optional().or(z.null()),
  })

  const parsedInitialValues = initialValues
    ? {
        ...initialValues,
        quantity: initialValues.quantity ? String(initialValues.quantity) : '',
        positionDrawingNumber: initialValues.positionDrawingNumber ? String(initialValues.positionDrawingNumber) : '',
        overriddenLifetime: initialValues.overriddenLifetime ? String(initialValues.overriddenLifetime) : '',
        minimumBreakingLoad: initialValues.minimumBreakingLoad ? String(initialValues.minimumBreakingLoad) : '',
        potencialCertificateBN: initialValues.potencialCertificateBN
          ? String(initialValues.potencialCertificateBN)
          : '',
        imageUrl: initialValues.imageUrl ? String(initialValues.imageUrl) : '',
        notes: initialValues.notes ? String(initialValues.notes) : '',
      }
    : undefined

  const form = useZodForm(schema, { defaultValues: parsedInitialValues })
  const { setValue, control } = useZodForm(schema)
  const isUpdateForm = parsedInitialValues && parsedInitialValues.id

  const [supplier, setSupplier] = useState<ISupplier | null>(null)
  const [product, setProduct] = useState<IProduct | null>(null)
  const [batchNumbers, setBatchNumbers] = useState<string[]>([])
  const [defaultBatchNumbers, setDefaultBatchNumbers] = useState<string[]>([])
  const [valueBatchNumbers, setValueBatchNumbers] = useState<string>()
  const [unitId, setUnitId] = useState<number>(parsedInitialValues?.unitId)

  const { data: products, isLoading: isProductLoading } = useGetProductsBySupplierId(
    supplier?.id ? supplier.id : 0,
    0,
    APIResultCount.Max,
  )
  const { data: suppliers, isLoading: isSupplierLoading } = useGetAdminSuppliers(0, APIResultCount.Max)
  const { data: units, isLoading: isUnitsLoading } = useGetUnits()
  const { data: certifications, isLoading: isCertificationsLoading } = useGetProductCertificationByProductId(
    product?.id as EntityId,
  )
  const { data: components, isLoading: isComponentLoading } = useGetComponents(
    customerId as EntityId,
    positionId as EntityId,
  )

  useEffect(() => {
    const batchNumbers = initialValues?.potencialCertificateBNs ? String(initialValues.potencialCertificateBNs) : ''
    if (batchNumbers && batchNumbers.length > 0) {
      setDefaultBatchNumbers(batchNumbers.split(',').map(option => option))
      setValueBatchNumbers(batchNumbers)
    }
  }, [initialValues])

  useEffect(() => {
    if (suppliers && suppliers?.items.length) {
      setSupplier((suppliers?.items.find(s => s.id === parsedInitialValues?.supplierId) as ISupplier) || null)
    }
  }, [suppliers])

  useEffect(() => {
    setProduct((products?.items.find(p => p.id === parsedInitialValues?.productId) as IProduct) || null)
  }, [products])

  useEffect(() => {
    if (certifications?.length) {
      let tempArray: string[] = []
      certifications.forEach(
        certificate => (tempArray = [...tempArray, ...certificate.batchNumbers.split(',').map(option => option)]),
      )
      setBatchNumbers(tempArray)
    } else {
      setBatchNumbers([])
    }
  }, [product?.id, certifications])

  useEffect(() => {
    if (product) {
      form.setValue('productNumber', product?.number)
      form.setValue('productDescription', product?.description)
      form.setValue('unitId', product?.unitId)
      setValue('productDescription', product?.description)
      setValue('productNumber', product?.number, { shouldValidate: true })
      setValue('unitId', product?.unitId, { shouldValidate: true })
      setUnitId(product?.unitId)
    }
  }, [product])

  useEffect(() => {
    if (!isUpdateForm && components && components?.items?.length > 0) {
      let componentsSorted = [...components.items]
      componentsSorted = componentsSorted?.sort((a, b) => a.positionDrawingNumber - b.positionDrawingNumber)
      for (let i = 0; i < components.items?.length; i++) {
        if (componentsSorted[i]?.positionDrawingNumber + 1 !== componentsSorted[i + 1]?.positionDrawingNumber) {
          form.setValue('positionDrawingNumber', (componentsSorted[i]?.positionDrawingNumber + 1).toString())
          break
        } else if (componentsSorted[i]?.positionDrawingNumber !== 1 && i == 0) {
          form.setValue('positionDrawingNumber', (i + 1).toString())
          break
        }
      }
    } else if (!isUpdateForm) {
      form.setValue('positionDrawingNumber', '1')
    }
  }, [isUpdateForm, components])

  const handleSelectIDChange = (value, name, onAction) => {
    const id = value
    form.setValue(name, id)
    form.clearErrors(name)
    onAction(id)
  }

  const handleSubmit = useCallback(
    f => {
      f.supplierId = supplier?.id
      f.productId = product?.id
      f.unitId = unitId
      f.potencialCertificateBNs = valueBatchNumbers || (defaultBatchNumbers.join(',') as string)
      f.id = isUpdateForm
      let isError = false

      components?.items
        .filter(component => component.positionDrawingNumber.toString() == f.positionDrawingNumber.toString())
        .forEach(component => {
          if (isUpdateForm && component.id != isUpdateForm) {
            isError = true
            toast.error(translate('duplicateDrawingNumber', { ns: 'component' }))
            return
          }
          if (!isUpdateForm) {
            isError = true
            toast.error(translate('duplicateDrawingNumber', { ns: 'component' }))
            return
          }
        })

      if (!isError) {
        onSubmit(f)
      }
    },
    [supplier, product, unitId, valueBatchNumbers, defaultBatchNumbers, components],
  )

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      removedCloseButton={false}
      actions={[
        <MainButton
          key="submit-btn"
          onClick={() => {
            form.handleSubmit(handleSubmit)()
          }}
          loading={isLoading}
        >
          {isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      {isLoading ||
      isUnitsLoading ||
      isProductLoading ||
      isSupplierLoading ||
      isComponentLoading ||
      isCertificationsLoading ? (
        <Spinner />
      ) : (
        <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={parsedInitialValues}>
          {isUpdateForm && <FormTextField name="id" type="hidden" />}
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="supplierId"
                options={suppliers?.items as ISupplier[]}
                onChange={(_, value) => {
                  setSupplier(suppliers?.items.find(s => s.id === value?.id) as ISupplier)

                  form.setValue('supplierId', value?.id as string | number)
                  form.clearErrors('supplierId')
                }}
                getOptionLabel={option => option.name ?? (option.name || null)}
                value={supplier}
                renderOption={(props, o) => (
                  <MenuItem key={o.id} value={o.id} {...props}>
                    {o.name}
                  </MenuItem>
                )}
                renderInput={params => (
                  <AppTextField
                    {...params}
                    name="supplierId"
                    label={translate('supplier', { ns: 'common' })}
                    error={!!form.formState.errors.supplierId}
                  />
                )}
              />
              {form.formState.errors.supplierId && (
                <FormHelperText sx={{ py: 1, px: 1 }}>
                  {translate('supplierIs', { ns: 'component' })}
                  {form.formState.errors.supplierId.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="productId"
                options={(products?.items as IProduct[]) ?? []}
                onChange={(_, value) => {
                  setProduct(products?.items.find(s => s.id === value?.id) as IProduct)

                  form.setValue('productId', value?.id as string | number)
                  form.clearErrors('productId')
                }}
                value={product}
                getOptionLabel={option => option.description + option.number}
                renderOption={(props, o) => (
                  <MenuItem key={o.id} value={o.id} {...props}>
                    {o.description}, {o.number}
                  </MenuItem>
                )}
                renderInput={params => (
                  <AppTextField
                    {...params}
                    name="productId"
                    label={translate('product', { ns: 'common' })}
                    error={!!form.formState.errors.productId}
                  />
                )}
              />
              {form.formState.errors.productId && (
                <FormHelperText sx={{ py: 1, px: 1 }}>
                  {translate('productIs', { ns: 'component' })}
                  {form.formState.errors.productId.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          {product?.id && (
            <>
              <Grid container>
                <Grid item xs={6} sm={4}>
                  <FormTextField
                    name="productNumber"
                    label={translate('productNumber', { ns: 'component' })}
                    disabled={isUpdateForm}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormTextField
                    name="productDescription"
                    label={translate('productDescription', { ns: 'component' })}
                  />
                </Grid>
                <Grid item xs={6} sm={4} px={1} py={1}>
                  <FormControl variant="filled" fullWidth error={!!form.formState.errors.unitId}>
                    <InputLabel id="unitId">{translate('unit', { ns: 'component' })}</InputLabel>
                    <Controller
                      name="unitId"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => {
                        if (unitId) {
                          value = unitId
                        }
                        return (
                          <Select
                            fullWidth
                            name="unitId"
                            value={value}
                            onChange={event => {
                              onChange(event)
                              handleSelectIDChange(event.target.value, 'unitId', setUnitId)
                            }}
                            defaultValue={unitId}
                            displayEmpty
                            label={translate('unit', { ns: 'component' })}
                            variant="filled"
                          >
                            {units?.items.map((unit: IUnit) => (
                              <MenuItem key={unit.id} value={unit.id}>
                                {unit.name} ({unit.abbreviation})
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />
                    {form.formState.errors.unitId && (
                      <FormHelperText sx={{ py: 1, px: 1 }}>
                        {translate('unitIs', { ns: 'component' })}
                        {form.formState.errors.unitId.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={4}>
                  <Autocomplete
                    multiple
                    componentName="batchnumbers"
                    id="batchNumbersList"
                    options={batchNumbers}
                    value={defaultBatchNumbers}
                    freeSolo
                    clearOnBlur
                    onBlur={a => {
                      const value = a.currentTarget.getElementsByTagName('input')[0].getAttribute('value')
                      if (value) {
                        setValueBatchNumbers(b => (b ? b + `,${value}` : `${value}`))
                        setDefaultBatchNumbers(b => [...b, ...[value]])
                      }
                    }}
                    onChange={(_, v) => {
                      setDefaultBatchNumbers(b => v)
                      setValueBatchNumbers(v.join(',') as string)
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value?.map((option: string, index: number) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                          sx={{ blockSize: 25, fontSize: 12 }}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={params => (
                      <AppTextField
                        {...params}
                        name="batchNumbersList"
                        label={translate('batchNumbers', { ns: 'product-certification' })}
                        placeholder={translate('addBatchNumber', { ns: 'product-certification' })}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormTextField
                    inputProps={{ type: 'number' }}
                    name="quantity"
                    label={translate('quantity', { ns: 'component' })}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormTextField
                    inputProps={{ type: 'number' }}
                    name="positionDrawingNumber"
                    label={translate('drawingInNumber', { ns: 'component' })}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={4}>
                  <FormTextField
                    inputProps={{ type: 'number' }}
                    name="overriddenLifetime"
                    label={translate('overriddenLifetime', { ns: 'component' })}
                  />
                  {product?.expectedLifetime ? (
                    <FormHelperText sx={{ py: 1, px: 1 }} id="component-helper-text">
                      {translate('expectedLifetime', { ns: 'component' })}: {product?.expectedLifetime}
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormTextField
                    inputProps={{ type: 'number' }}
                    name="minimumBreakingLoad"
                    label={translate('minimumBreakingLoad', { ns: 'component' })}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <BasicDatePicker name="installationDate" label={translate('installationDate', { ns: 'component' })} />
                </Grid>
              </Grid>
              <FormTextField name="notes" label={translate('notes', { ns: 'component' })} multiline rows={4} />
              <Box py={1}>
                <FormFileCoreUpload
                  label={translate('image', { ns: 'component' })}
                  name="imageUrl"
                  mapFileKey="url"
                  type={FileType.Image}
                  initialFiles={initialValues?.file}
                  height="150px"
                  getUploadedFiles={e => getUploadedFile(e, form)}
                />
              </Box>
            </>
          )}
        </AppForm>
      )}
    </GenericDialogLayout>
  )
}

const getUploadedFile = (e, form) => {
  form.setValue('fileId', e.id)
}
