import { useTranslation } from 'react-i18next'
import { invalidateComponentsCache } from '../../../api/components'
import { invalidateConnectedPositionCache } from '../../../api/connectedPositions'
import { invalidateMooringCache } from '../../../api/moorings'
import { invalidatePositionCache, useGetPositionsWithMinimalData, useUpdatePosition } from '../../../api/positions'
import { ShowDialogProps } from '../../../show-dialog'
import { PositionForm } from './components/PositionsForm'
import { toast } from 'react-toastify'
import { useCallback } from 'react'
import { IPosition } from '../../../generated-types'
import { invalidateLocalityCache } from 'src/api/localities'

interface UpdatePositionProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  positionId: EntityId
  position: IPosition
}
export const UpdatePositionDialog: React.FC<UpdatePositionProps> = ({
  pop,
  customerId,
  localityId,
  mooringId,
  positionId,
  position,
}) => {
  const { t } = useTranslation(['position', 'common'])
  const { data: positionsList } = useGetPositionsWithMinimalData(customerId, localityId, mooringId, {
    corners: true,
    allPositions: false,
  })

  const { mutateAsync: updatePositionAsync, isLoading } = useUpdatePosition(
    customerId,
    localityId,
    mooringId,
    position?.id as EntityId,
  )
  const onSubmit = useCallback(
    async form => {
      await updatePositionAsync(form, {
        onSuccess: () => {
          invalidateConnectedPositionCache.getConnectedPositions(
            customerId as EntityId,
            mooringId as EntityId,
            localityId as EntityId,
          )
          invalidatePositionCache.getPositions(customerId, localityId, mooringId)
          invalidatePositionCache.getPositionsWithMinimalData(customerId, localityId, mooringId)
          invalidateComponentsCache.getComponents(customerId as EntityId, positionId as EntityId)
          invalidateMooringCache.getGetMooring(customerId as EntityId, localityId as EntityId, mooringId as EntityId, {
            isWithAllInfo: true,
          })
          invalidateMooringCache.getMapSvg(customerId as EntityId, localityId as EntityId, mooringId as EntityId)
          invalidateLocalityCache.getCageStatus(customerId, localityId as EntityId)
          invalidateMooringCache.getMooringsForMap(customerId as EntityId, localityId)
          toast.success(t('updatedSuccessfully', { ns: 'common' }))
          pop()
        },
      })
    },
    [customerId, localityId, mooringId, pop, positionId, t, updatePositionAsync],
  )

  return (
    <>
      {position && (
        <PositionForm
          onSubmit={onSubmit}
          onCancel={() => pop()}
          initialValues={position}
          loading={isLoading}
          positions={positionsList}
          customerId={customerId}
          localityId={localityId}
          title = {t('updatePosition', { ns: 'position' })}
          isDialog = {true}
        />
      )}
    </>
  )
}
