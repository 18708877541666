/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { AppForm, AppFormProps } from '../../components/FormCore'
import { useZodForm } from '../../hooks/zod-form'
import { useTranslation } from 'react-i18next'
import { IDeviationType } from '../../generated-types/deviation-type'
import { FC, ReactNode, useEffect, useState } from 'react'
import { IComponent, IMooring, IPosition, IPositionType } from '../../generated-types'
import { IDeviationFormWizardType, IDeviationStepperFormStep } from '../../generated-types/form-wizard'
import { IFormType } from '../../generated-types/form-type'
import { IMainComponent } from '../../generated-types/main-component'
import DeviationFinalForm from './DeviationFinalForm'
import RenderStepContent from './RenderStepContent'
import { GenericDialogLayout } from '../../components/GenericDialogLayout'
import { Stack, styled } from '@mui/material'
import { MainButton } from '../../components/MainButton'
import { deviationSchema } from './deviationSchema'
import { getTranslatedText } from '../../utils/translations'
import { useGetLocality } from '../../api/localities'
import { CustomizedConnector, DetailContainer, StepLabelStyled } from './DeviationStepperForm.style'
import { ILocalityFrequency } from 'src/generated-types/locality-frequency'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'
import { GlobalStyled } from 'src/styles/globalPaddingStyles'
import { getMooringName } from 'src/utils/function'

export interface IDeviationStepperFormProps extends AppFormProps {
  customerId?: EntityId
  localityId?: EntityId
  mooring?: IMooring
  positionId?: EntityId
  component?: IComponent
  mainComponent?: IMainComponent
  position?: IPosition
  localityFrequencyIds?: Array<EntityId>
  isDisableWizard?: boolean
  type: IDeviationType
  formWizard?: IDeviationFormWizardType
  formType?: IFormType
  form?: any
  title: string
  isLoading: boolean
  scheduledTaskId?: EntityId
  service?: ILocalityFrequency
  lgModal?: boolean
}

export const DeviationStepperForm: FC<IDeviationStepperFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  localityId,
  mooring,
  positionId,
  component,
  mainComponent,
  position,
  localityFrequencyIds,
  isDisableWizard,
  type,
  initialValues,
  formWizard = IDeviationFormWizardType.Common,
  formType = IFormType.Create,
  form,
  title,
  isLoading,
  scheduledTaskId,
  service,
  lgModal,
}) => {
  const { data } = useGetLocality(customerId as EntityId, localityId as EntityId)
  const { t: translate } = useTranslation(['deviation', 'common', 'task', 'position'])
  const [disabled, setDisabled] = useState(false)
  const [stepHistory, setStepHistory] = useState([IDeviationStepperFormStep.Step_1])

  const defaultForm = useZodForm(deviationSchema, {
    defaultValues: {
      customerId,
      localityId,
      mooringId: mooring?.id,
      positionId,
      localityFrequencyIds,
      scheduledTaskId,
    },
  })

  const commonDeviationSteps = [
    translate('selectPositionTask'),
    translate('selectComponentResource'),
    translate('fillDeviationForm'),
  ]

  const mooringDeviationSteps = [
    translate('selectPositionTask'),
    translate('selectComponentResource'),
    translate('fillDeviationForm'),
  ]

  const positionDeviationSteps = [translate('selectComponentResource'), translate('fillDeviationForm')]
  const localityServiceDeviationSteps = [
    translate('selectPositionTask'),
    translate('selectComponentResource'),
    translate('fillDeviationForm'),
  ]

  const steps =
    formWizard == IDeviationFormWizardType.Common
      ? commonDeviationSteps
      : formWizard == IDeviationFormWizardType.Component
        ? localityServiceDeviationSteps
        : formWizard == IDeviationFormWizardType.Position
          ? positionDeviationSteps
          : mooringDeviationSteps

  const [activeStep, setActiveStep] = useState(
    isDisableWizard
      ? IDeviationStepperFormStep.Step_5
      : formWizard == IDeviationFormWizardType.Component
        ? IDeviationStepperFormStep.Step_4
        : IDeviationStepperFormStep.Step_1,
  )

  const addValueToHistory = value => {
    setStepHistory(stepsHistory => {
      const stepHistoryArray = stepsHistory
      if (stepHistoryArray[stepHistoryArray.length - 1] != value) {
        stepHistoryArray.push(value)
        return stepHistoryArray
      } else {
        return stepHistoryArray
      }
    })
  }

  const handleNext = () => {
    addValueToHistory(activeStep)
    if (formWizard == IDeviationFormWizardType.Common && defaultForm.getValues('type') == IDeviationType.Position) {
      setActiveStep(prevActiveStep => (prevActiveStep == 0 ? prevActiveStep + 2 : prevActiveStep + 1))
    } else if (
      (formWizard == IDeviationFormWizardType.Common &&
        (defaultForm.getValues('type') == IDeviationType.Equipment ||
          defaultForm.getValues('type') == IDeviationType.MainComponent)) ||
      formWizard == IDeviationFormWizardType.Position
    ) {
      setActiveStep(prevActiveStep => (prevActiveStep == 0 ? prevActiveStep + 3 : prevActiveStep + 1))
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setStepHistory(stepHistory => {
      const stepHistoryArray = stepHistory
      const lastStep = stepHistoryArray.pop()
      if (lastStep) {
        setActiveStep(lastStep)
      } else {
        setActiveStep(IDeviationStepperFormStep.Step_1)
      }
      return stepHistoryArray
    })
  }

  useEffect(() => {
    defaultForm.reset({
      ...defaultForm.getValues(),
      position,
      mooring,
      component,
      mainComponent,
      type,
      locality: data,
      localityFrequencyIds: localityFrequencyIds as EntityId[],
    })
  }, [position, mooring, component, mainComponent, type, data])

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      topActions={
        isDisableWizard ||
          (!(formWizard == IDeviationFormWizardType.Position) && activeStep == IDeviationStepperFormStep.Step_1)
          ? undefined
          : [
            <Box key="stepper-header" sx={{ width: '100%', paddingX: '24px' }}>
              <Stepper
                activeStep={activeStep - 2}
                sx={{ paddingTop: 1, width: '100%', minWidth: '600px' }}
                connector={<CustomizedConnector />}
              >
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {}
                  const labelProps: {
                    optional?: ReactNode
                  } = {}
                  return (
                    <Step key={index} {...stepProps}>
                      <StepLabelStyled {...labelProps}>{activeStep == index + 2 ? label : label}</StepLabelStyled>
                    </Step>
                  )
                })}
              </Stepper>
            </Box>,
          ]
      }
      actions={[
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          key={'stepper-form-actions'}
          width={'100%'}
        >
          {!isDisableWizard &&
            activeStep != IDeviationStepperFormStep.Step_1 &&
            !(formWizard == IDeviationFormWizardType.Component && activeStep == IDeviationStepperFormStep.Step_4) && (
              <MainButton onClick={handleBack} variant="outlined" startIcon={<ChevronLeftIcon />}>
                {translate('back', { ns: 'common' })}
              </MainButton>
            )}
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep == IDeviationStepperFormStep.Step_5 ||
            isDisableWizard ||
            (formWizard == IDeviationFormWizardType.Position && activeStep == IDeviationStepperFormStep.Step_4) ? (
            <>
              <MainButton onClick={onCancel} variant="outlined">
                {translate('cancel', { ns: 'common' })}
              </MainButton>
              <MainButton
                variant="contained"
                onClick={() => {
                  const submit = (form ?? defaultForm).handleSubmit(onSubmit)
                  submit()
                }}
                loading={isLoading}
                data-cy="Submit"
              >
                {formType == IFormType.Update
                  ? translate('update', { ns: 'common' })
                  : translate('addDeviation', { ns: 'deviation' })}
              </MainButton>
            </>
          ) : (
            activeStep != IDeviationStepperFormStep.Step_1 && <></>
          )}
        </Stack>,
      ]}
    >
      <GlobalStyled>
        <AppForm form={form ? form : defaultForm} onSubmit={onSubmit} hasInitialValues={initialValues}>
          {activeStep != 0 &&
            (defaultForm.getValues('locality')?.id ||
              defaultForm.getValues('mooring')?.id ||
              defaultForm.getValues('position')?.id ||
              defaultForm.getValues('component')?.id ||
              defaultForm.getValues('mainComponent')?.id ||
              localityFrequencyIds) &&
            formType == IFormType.Create && (
              <DetailBox padding={'8px'}>
                <DetailContainer
                  direction={'row'}
                  spacing={2}
                  alignItems={'center'}
                  height={!lgModal ? '60px' : '56px'}
                  padding={'6px'}
                >
                  {renderDetailSection(
                    activeStep,
                    defaultForm,
                    localityFrequencyIds,
                    formType,
                    formWizard,
                    translate,
                    service,
                    lgModal,
                  )}
                </DetailContainer>
              </DetailBox>
            )}
          {isDisableWizard ? (
            <DeviationFinalForm
              customerId={customerId as EntityId}
              localityId={localityId}
              formType={formType}
              scheduledTaskId={scheduledTaskId}
            />
          ) : (
            <RenderStepContent
              step={activeStep}
              setActiveStep={setActiveStep}
              customerId={customerId as EntityId}
              localityId={localityId as EntityId}
              formWizard={formWizard}
              setDisabled={setDisabled}
              handleNext={handleNext}
              lgModal={lgModal}
            />
          )}
        </AppForm>
      </GlobalStyled>
    </GenericDialogLayout>
  )
}

const renderDetailSection = (
  activeStep,
  defaultForm,
  localityFrequencyIds,
  formType,
  formWizard,
  translate,
  service,
  lgModal,
) => {
  let deviationPath = ''
  if (
    activeStep != 0 &&
    (defaultForm.getValues('locality')?.id ||
      defaultForm.getValues('mooring')?.id ||
      defaultForm.getValues('position')?.id ||
      defaultForm.getValues('component')?.id ||
      defaultForm.getValues('mainComponent')?.id ||
      localityFrequencyIds) &&
    formType == IFormType.Create
  ) {
    if (activeStep == IDeviationStepperFormStep.Step_3 && !(formWizard == IDeviationFormWizardType.Component)) {
      deviationPath =
        deviationPath +
        translate('mooring', { ns: 'common' }) +
        (defaultForm.getValues('position')?.id || defaultForm.getValues('scheduledTaskId') ? '  >  ' : '')
    } else {
      if (localityFrequencyIds) {
        deviationPath =
          deviationPath +
          translate('locality-frequency', { ns: 'common' }) +
          (defaultForm.getValues('position')?.id || defaultForm.getValues('mooring')?.id ? '  >  ' : '')
      }
      if (defaultForm.getValues('scheduledTaskId')) {
        deviationPath =
          deviationPath +
          translate('task-deviation', { ns: 'task' }) +
          (defaultForm.getValues('position')?.id ||
            defaultForm.getValues('mooring')?.id ||
            defaultForm.getValues('mainComponent')?.id
            ? '  >  '
            : '')
      }
      if (defaultForm.getValues('position')?.id || defaultForm.getValues('mooring')?.id) {
        deviationPath =
          deviationPath +
          translate('mooring', { ns: 'common' }) +
          ' : ' +
          (getMooringName(defaultForm.getValues('mooring')?.name ?? '') ??
          getMooringName(defaultForm.getValues('position')?.mooringName ?? '') ??
            translate('n/a', { ns: 'common' })) +
          (defaultForm.getValues('position')?.id || defaultForm.getValues('component')?.id ? '  >  ' : '')
      }
      if (defaultForm.getValues('position')?.id && formWizard == IDeviationFormWizardType.Component && service) {
        deviationPath =
          deviationPath +
          translate('service', { ns: 'deviation' }) +
          ' : ' +
          service?.name +
          '-' +
          ((service?.frequency?.title ?? translate('n/a', { ns: 'common' })) + defaultForm.getValues('position')?.id
            ? '  >  '
            : '')
      }
      if (defaultForm.getValues('position')?.id) {
        deviationPath =
          deviationPath +
          `${translate('position', { ns: 'common' })} : ${translate(
            IPositionType[defaultForm.getValues('position').type], {ns: 'position'}
          )} - ${defaultForm.getValues('position')?.name ?? translate('n/a', { ns: 'common' })}` +
          (defaultForm.getValues('component')?.id || defaultForm.getValues('mainComponent')?.id ? '  >  ' : '')
      }
      if (defaultForm.getValues('component')?.id) {
        deviationPath =
          deviationPath +
          `${translate('component', { ns: 'common' })} : ${defaultForm.getValues('component')?.productId ?? translate('n/a', { ns: 'common' })
          } - ${defaultForm.getValues('component').productDescription ?? translate('n/a', { ns: 'common' })}` +
          (defaultForm.getValues('mainComponent')?.id ? '  >  ' : '')
      }
      if (defaultForm.getValues('mainComponent')?.id) {
        deviationPath =
          deviationPath +
          `${translate('main-component', { ns: 'common' })} : ${getTranslatedText(
            defaultForm.getValues('mainComponent')?.type?.name,
          )} ${defaultForm.getValues('mainComponent')?.name ?? translate('n/a', { ns: 'common' })}`
      }
    }
  }
  return (
    <DynamicTextTypography
      text={deviationPath}
      maxLength={lgModal ? 100 : 80}
      fontSize={'18px'}
      fontWeight={400}
      padding={'10px'}
    />
  )
}

const DetailBox = styled(Box)({
  padding: 2,
  marginBottom: 2,
  marginTop: 5,
})