import { Autocomplete, Box, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useGetLocalities } from '../../../../api/localities'
import {
  AppForm,
  AppFormProps,
  FormSelectField,
  FormSubmitButton,
  FormTextField,
} from '../../../../components/FormCore'
import { CenteredSpinner } from '../../../../components/Spinner'
import { ILocality, IMooring } from '../../../../generated-types'
import { useZodForm } from '../../../../hooks/zod-form'
import { useCallback, useState } from 'react'
import { APIResultCount } from '../../../../constant'
import { useGetMoorings, useGetMooringsForService } from '../../../../api/moorings'
import { AppTextField } from 'src/components/AppTextField'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { getMooringName } from 'src/utils/function'
import { useGetFrequencies } from 'src/api/frequency'

export interface AddLocalityToServiceFormProps extends AppFormProps {
  customerId: EntityId
  serviceId?: EntityId
  title: string
  isAddButtonDisabled?: boolean
}

export const AddLocalityToServiceForm: React.FC<AddLocalityToServiceFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  customerId,
  serviceId,
  title,
  isAddButtonDisabled,
}) => {
  const { t: translate } = useTranslation(['customer-locality', 'common', 'locality-frequency'])
  const [selectedLocalityId, setSelectedLocalityId] = useState<EntityId | undefined>()
  const { data: frequencies } = useGetFrequencies(customerId as EntityId, serviceId as EntityId)

  const localityServiceSchema = z.object({
    localityId: z.number(),
    mooringIds: z
      .array(z.object({ id: z.number() }))
      .nonempty()
      .transform(data => data.map(({ id }) => id)),
    serviceId: z.number().optional(),
    frequencyId: z
      .object({
        id: z.number().min(1),
      })
      .transform(value => value.id),
    scheduledDate: z.string().nonempty(translate('provideValidInput', { ns: 'common' })),
    endDate: z.string().transform(value => (value === '' ? null : value)),
  })

  const form = useZodForm(localityServiceSchema, { defaultValues: { ...initialValues, ...{ mooringIds: [] } } })
  const { data, isLoading } = useGetLocalities(customerId, { page: 0, pageSize: APIResultCount.Max })

  const formatInputDate = (date: Date) => {
    return date.toISOString().split('T')[0]
  }
  if (isLoading) return <CenteredSpinner />

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
      <GenericDialogLayout
        title={title}
        actions={[
          <FormSubmitButton
            key="mooring-submit"
            createText={translate('add', { ns: 'common' })}
            isLoading={isAddButtonDisabled}
            disabled={isAddButtonDisabled}
          />,
        ]}
        removedCloseButton={false}
        pop={onCancel}
      >
        <Autocomplete
          options={(data?.items as ILocality[]) ?? []}
          autoHighlight
          getOptionLabel={o => o.name}
          onChange={(_, value) => {
            form.setValue('localityId', (value as ILocality).id)
            setSelectedLocalityId((value as ILocality).id as EntityId)
          }}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {o.name}
            </MenuItem>
          )}
          renderInput={params => (
            <AppTextField
              {...params}
              label={translate('selectLocality', { ns: 'customer-locality' })}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        {selectedLocalityId && (
          <SelectMooring
            customerId={customerId}
            localityId={selectedLocalityId}
            serviceId={serviceId as EntityId}
            disabled={false}
            data-cy="selectLocality"
          />
        )}

        <Box
          sx={theme => ({
            border: '1px solid',
            borderColor: theme.palette.secondaryMain.light,
            padding: 2,
            borderRadius: 1,
            marginY: 1,
            width: '97%',
            marginLeft: '8px',
          })}
        >
          <Typography variant="body1">{translate('next-occurance', { ns: 'locality-frequency' })}</Typography>
          <FormSelectField
            name="frequencyId"
            label={translate('service-frequency', { ns: 'locality-frequency' })}
            data={frequencies?.items ?? []}
            getOptionLabel={option => option.title}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {option.title}
              </Box>
            )}
            isOptionEqualToValue={(o, v) => o.id === v.id}
          />
          <FormTextField
            name="scheduledDate"
            label={translate('scheduled-date', { ns: 'locality-frequency' })}
            type="date"
            defaultValue={formatInputDate(new Date())}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <FormTextField
          name="endDate"
          label={translate('endDate', { ns: 'common' })}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </GenericDialogLayout>
    </AppForm>
  )
}

const SelectMooring: React.FC<{
  customerId: EntityId
  localityId: EntityId
  serviceId: EntityId
  disabled: boolean
}> = ({ customerId, localityId, serviceId, disabled = true }) => {
  const { data: moorings, isLoading } = useGetMoorings(
    customerId,
    localityId as EntityId,
    { getOnlyActiveMooring: true },
    0,
    APIResultCount.Max,
  )
  const { t: translate } = useTranslation(['mooring'])

  const { data: alreadyAddedMoorings, isLoading: isAlreadyAddedMooringsLoading } = useGetMooringsForService(
    customerId,
    serviceId as EntityId,
    0,
    10000,
  )

  const isMooringInService = useCallback(
    mooringId => {
      return alreadyAddedMoorings?.items.some(mooring => mooring.id === mooringId)
    },
    [alreadyAddedMoorings],
  )

  if (isLoading || isAlreadyAddedMooringsLoading) return <CenteredSpinner />

  return (
    <FormSelectField
      disabled={disabled}
      name="mooringIds"
      multiple
      data={(moorings?.items.filter(f => !isMooringInService(f.id)) as IMooring[]) ?? []}
      label={translate('selectMooring', { ns: 'mooring' })}
      getOptionLabel={option => getMooringName(option.name)}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderOption={(props: any, option) => (
        <Box {...props} key={option.id}>
          {getMooringName(option.name)}
        </Box>
      )}
    />
  )
}
