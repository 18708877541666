import { Box } from '@mui/material'
import { useEffect } from 'react'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { useGetMapSvg } from '../../../api/moorings'
import { AppRoutesNavigation } from '../../../app-routes'
import { Spinner } from '../../../components/Spinner'

export const GenericMapSvgContainer: React.FC<{
  customerId?: EntityId
  localityId?: EntityId
  mooringId?: EntityId
  navigator?: NavigateFunction
  height?: number | string
  backGroundColor?: string
  topBarAction?: React.ReactNode
}> = ({ customerId, localityId, mooringId, navigator, height, backGroundColor,topBarAction }) => {
  const { customerId: pathCustomerId, localityId: pathLocalityId, mooringId: pathMooringId } = useParams()
  const { data, isLoading, isSuccess } = useGetMapSvg(
    customerId ?? (pathCustomerId ?? 0 as EntityId),
    localityId ?? (pathLocalityId ?? 0 as EntityId),
    mooringId ?? (pathMooringId ?? 0 as EntityId),
  )

  useEffect(() => {
    if (data) {
      navigationFunction('positionId-')
      navigationFunction('positionTextId-')
    }
  }, [data])

  const navigationFunction = container => {
    const elements = document.querySelectorAll(`[id^="${container}"]`)
    elements.forEach(element => {
      element.addEventListener('click', () => {
        const id = element.id.replace(container, '')
        navigator &&
          navigator(AppRoutesNavigation.Localities.position(customerId || '', localityId || '', mooringId || '', id))
      })
    })
  }

  if (isLoading) return <Spinner />

  return (
    <Box>
      {isSuccess && topBarAction}
      <div
        dangerouslySetInnerHTML={{ __html: data || '' }}
        style={{ height: height, width: '100%', background: backGroundColor, borderRadius: '6px' }}
      />
    </Box>
  )
}
export const MapSvgContainer: React.FC<{
  customerId?: EntityId
  localityId?: EntityId
  mooringId?: EntityId
  height?: number | string
  backgroundColor?: string
}> = ({ customerId, localityId, mooringId, height, backgroundColor }) => {
  const navigator = useNavigate()
  return (
    <GenericMapSvgContainer
      customerId={customerId}
      localityId={localityId}
      mooringId={mooringId}
      navigator={navigator}
      height={height}
      backGroundColor={backgroundColor}
    />
  )
}
export const MapSvgContainerModal: React.FC<{
  customerId?: EntityId
  localityId?: EntityId
  mooringId?: EntityId
  height?: number | string
}> = ({ customerId, localityId, mooringId, height }) => {
  return (
    <GenericMapSvgContainer customerId={customerId} localityId={localityId} mooringId={mooringId} height={height} />
  )
}
