import { Marker, Tooltip } from 'react-leaflet'
import { useState, useMemo, useLayoutEffect, memo } from 'react'
import { useMap } from 'react-leaflet'
import { IMooring, IPositionType, IMooringType } from '../../../../../generated-types'
import { CreateLayerGroup } from '../ComponentLayer'
import { minZoom } from '../constraints'
import { midpoint } from '../utils'
import { LatLngExpression } from 'leaflet'
import L from 'leaflet'
import locationIcon from 'src/assets/svg/location.svg'
import locationDeviationIcon from 'src/assets/svg/location_deviation.svg'
import { validatePosition } from '../MapBoundsComponent'
import { ICluster } from 'src/generated-types/cluster'

export const CreateMooring: React.FC<{
  mooring: IMooring
  type?: IPositionType
  mapPosition: L.LatLngExpression
  removeType?: IMooringType
  isFleet?: boolean
  isModalView: boolean
  navigateLocality?: () => void
  clusters?: ICluster[]
  showCageStatusPopOver?: boolean
  onPositionSelect?: (positionId: number, positionName: string) => void
  mooringsLength?: number
}> = memo(
  ({
    mooring,
    type,
    clusters,
    mapPosition,
    removeType = null,
    isFleet,
    isModalView,
    navigateLocality,
    showCageStatusPopOver,
    onPositionSelect,
    mooringsLength,
  }) => {
    const map = useMap()
    const [viewContent, setViewContent] = useState(map.getZoom() > minZoom ? true : false)
    const center = useMemo(() => {
      const positions = mooring.positions
      if (positions) {
        const corners = positions.filter(p => p.type == IPositionType.Corner)
        if (corners.length > 2) {
          return midpoint(
            corners[0].latitude,
            corners[0].longitude,
            corners[corners.length - 1].latitude,
            corners[corners.length - 1].longitude,
            0.5,
          ) as LatLngExpression
        }
        if (corners[0]?.latitude && corners[0]?.longitude) {
          return [corners[0]?.latitude, corners[0]?.longitude] as LatLngExpression
        }
      }
      return [0, 0] as LatLngExpression
    }, [mooring.positions])

    const isCluster = useMemo(() => {
      if (clusters && clusters?.length > 0) {
        const insideClusterIndex = clusters.findIndex(
          c => c.localities.findIndex(m => m.id == mooring.localityId) != -1,
        )

        if (insideClusterIndex != -1) {
          return true
        }
      }

      return false
    }, [clusters, mooring, map])

    useLayoutEffect(() => {
      if (map.getZoom() < minZoom) {
        setViewContent(false)
      } else {
        setViewContent(true)
      }
      map.addEventListener('zoom', () => {
        if (map.getZoom() < minZoom) {
          setViewContent(false)
        } else {
          setViewContent(true)
        }
      })
      return () => {
        map.removeEventListener('zoom')
      }
    }, [mooringsLength])

    const circleEvent = useMemo(() => {
      return {
        click() {
          if (navigateLocality) {
            navigateLocality()
          } else {
            map.fitBounds(
              (mooring?.positions?.filter(x => validatePosition(x)) || []).map(p => [p.latitude, p.longitude]),
              {
                animate: false,
                duration: 100,
                padding: [20, 20],
                maxZoom: 16,
              },
            )
          }
        },
      }
    }, [center, navigateLocality])

    if ((removeType && mooring.type == removeType) || isCluster == true) {
      return <></>
    }

    if (viewContent) {
      return (
        <CreateLayerGroup
          positions={mooring.positions}
          type={type}
          mapPosition={mapPosition}
          isFleet={mooring.type == IMooringType.Barge || isFleet}
          steelFrame={mooring.type == IMooringType.SteelCage}
          isModalView={isModalView}
          clusters={clusters}
          showCageStatusPopOver={showCageStatusPopOver}
          mooringType={mooring?.type}
          onPositionSelect={onPositionSelect}
        />
      )
    } else {
      if (center) {
        return (
          <Marker
            eventHandlers={circleEvent}
            position={center}
            icon={L.icon({
              iconUrl:
                mooring?.deviationsCount > 0 ||
                mooring?.positions?.map(x => x.deviationsCount)?.reduce((sum, current) => sum + current, 0) > 0
                  ? locationDeviationIcon
                  : locationIcon,
              iconSize: [25, 38],
            })}
          >
            <Tooltip direction={'top'} offset={[0, -10]}>
              {mooring?.name}
            </Tooltip>
          </Marker>
        )
      }
    }
    return <></>
  },
)
