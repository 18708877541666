import { Box, Grid, InputBase, Typography, styled, useTheme } from '@mui/material'
import { t } from 'i18next'
import { MdSearch } from 'react-icons/md'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { MainButton } from 'src/components/MainButton'
import { ShowDialogProps } from 'src/show-dialog'
import { EmptyStatusCard } from 'src/components/mooring/EmptyStatusCard'
import PositionDrawingEmptyStatus2x from '../../../assets/Common 2@2x.png'
import NoTemplatesAvailable from '../../../assets/noTemplatesAvailable.png'
import EmptyTemplateImage from '../../../assets/EmptyTemplateImage.svg'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import {
  invalidatePositionDrawingCache,
  useAddPositionDrawing,
  useGetPositionDrawing,
  useGetPositionDrawingInMooring,
  useGetSimplePositionDrawings,
} from 'src/api/position-drawing'
import { IPosition, IPositionType } from 'src/generated-types'
import PositionDrawingCard from './Components/PositionDrawingCard'
import { IPositionDrawing } from 'src/generated-types/position-drawing'
import { CustomizeComponentContainer } from './CustomizeComponentContainer'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { useUpdatePositionDrawingsOfaMooring } from 'src/api/moorings'
import { toast } from 'react-toastify'
import { IPositionDrawingProduct } from 'src/generated-types/position-drawing-product'
import { ALREADY_EXISTS } from 'src/constant'
interface ManageTemplatesProps extends ShowDialogProps {
  positionId?: EntityId
  mooringId: EntityId
  positions?: Array<IPosition>
  customerId: EntityId
}

const SolidBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.gray70.main};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.silent[theme.palette.mode]};
`

const DashedBox = styled(Box)`
  border: 2px dashed ${({ theme }) => theme.palette.gray70.main};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.silent[theme.palette.mode]};
`
const ScrollableBox = styled(Box)({
  width: 'auto !important',
  maxHeight: '550px',
  borderRadius: '8px',
  overflowY: 'auto',
})

export const ManageTemplatesDialog: React.FC<ManageTemplatesProps> = ({
  pop,
  positionId,
  positions,
  mooringId,
  customerId,
}) => {
  const { t: translate } = useTranslation(['mooring', 'common', 'main-component'])
  const [selectedTemplate, setSelectedTemplate] = useState<IPositionDrawing>()
  const [searchInput, setSearchInput] = useState<string>('')
  const position = (positions || []).filter(x => x.id == positionId)
  const { data: positionDrawings } = useGetSimplePositionDrawings(
    searchInput,
    position != null && position.length > 0 ? (position[0].type as IPositionType) : undefined,
    customerId as number,
  )
  const { data: positionDrawing } = useGetPositionDrawing(selectedTemplate?.id ?? 0)
  const { data: selectedPositionDrawings } = useGetPositionDrawingInMooring(customerId, mooringId)
  const [customizedTemplates, setCustomizedTemplates] = useState<IPositionDrawing[]>([])
  const [positionDrawingProductsList, setPositionDrawingProducts] = useState<IPositionDrawingProduct[]>()
  const [positionDrawingProductsListToCompare, setPositionDrawingProductsListToCompare] =
    useState<IPositionDrawingProduct[]>()
  const theme = useTheme()
  const { showWarningDialog } = useDialogCtx()
  const mutation = useUpdatePositionDrawingsOfaMooring(customerId, mooringId)
  const { mutateAsync, error } = useAddPositionDrawing()

  const onSearchInputChange = (searchInput: string) => {
    setSearchInput(searchInput)
  }

  useEffect(() => {
    if (positionDrawing?.positionDrawingProducts != null) {
      const productsToUpdate = positionDrawing.positionDrawingProducts.map(p => ({ ...p }))
      setPositionDrawingProductsListToCompare(productsToUpdate)
      setPositionDrawingProducts(productsToUpdate)
    }
  }, [positionDrawing?.id])

  useEffect(() => {
    const tempDrawings: IPositionDrawing[] = []
    const selectedDrawings: IPositionDrawing[] = selectedPositionDrawings?.items as IPositionDrawing[]
    selectedDrawings &&
      selectedDrawings.forEach(e => {
        if (customizedTemplates.find(c => c.id == e.id) == null) tempDrawings.push(e)
      })
    setCustomizedTemplates(pre => [...tempDrawings, ...pre])
  }, [selectedPositionDrawings])

  const handleConfirmDelete = async (id: number) => {
    await showWarningDialog(
      translate('are-you-sure-to-delete'),
      translate('are-you-sure-to-delete-message'),
      undefined,
      pop => {
        setCustomizedTemplates(customizedTemplates.filter(drawing => drawing.id !== id))
        pop()
      },
    )
  }

  const positionDrawingUpdate = async (
    positionDrawings: IPositionDrawing[],
    initialPositionDrawings?: IPositionDrawing[],
  ) => {
    positionDrawings.forEach(p => {
      p.positionDrawingProducts = p.positionDrawingProducts.filter(x => typeof x.id !== 'string')
    })
    await mutation.mutateAsync({ positionDrawings, initialPositionDrawings }).then(() => {
      if (positionDrawings.length == 0 && initialPositionDrawings?.length == 0) {
        toast(translate('no-changes-to-update-in-selectedtemplates'), { type: 'error' })
      } else {
        invalidatePositionDrawingCache.getPositionDrawingInMooring(customerId, mooringId)
        invalidatePositionDrawingCache.getSimplePositionDrawings()
        invalidatePositionDrawingCache.getPositionDrawingById(selectedTemplate?.id ?? 0)
        toast(t('updatedSuccessfully', { ns: 'common' }), { type: 'success' })
        pop()
      }
    })
  }

  const checkPositionDrawingProductsUpdated = (
    defaultProducts: IPositionDrawingProduct[],
    updatedProducts: IPositionDrawingProduct[],
  ): boolean => {
    return !(JSON.stringify(defaultProducts) === JSON.stringify(updatedProducts))
  }

  useEffect(() => {
    if (error && error['data'] == ALREADY_EXISTS) {
      toast(translate('already-exists'), { type: 'error' })
    }
  }, [error])

  const addDrawing = (newDrawing: IPositionDrawing, positionDrawingProducts: IPositionDrawingProduct[]) => {
    const isProductsUpdated = checkPositionDrawingProductsUpdated(
      positionDrawingProductsListToCompare as IPositionDrawingProduct[],
      positionDrawingProducts as IPositionDrawingProduct[],
    )

    newDrawing.mooringId = mooringId as EntityId

    if (isProductsUpdated) {
      newDrawing.positionDrawingProducts = positionDrawingProducts as IPositionDrawingProduct[]
      newDrawing.isCloned = true
      newDrawing.customerId = customerId as number
      mutateAsync(newDrawing as IPositionDrawing).then(() => {
        invalidatePositionDrawingCache.getPositionDrawingInMooring(customerId, mooringId)
        invalidatePositionDrawingCache.getSimplePositionDrawings()
        invalidatePositionDrawingCache.getPositionDrawingById(newDrawing.id)
      })
    } else {
      if (setCustomizedTemplates != undefined) {
        setCustomizedTemplates((customizedTemplates: IPositionDrawing[]) => {
          if (customizedTemplates.find(c => c.id === newDrawing.id) != null) {
            toast(translate('templateAlreadySelected'), { type: 'warning' })
            return customizedTemplates
          } else return [...customizedTemplates, newDrawing]
        })
      }
    }
  }

  return (
    <GenericDialogLayout
      sx={{ height: '95vh' }}
      title={t('manage-templates', { ns: 'mooring' })}
      pop={pop}
      actions={[
        <MainButton
          key={'cancel'}
          onClick={() => {
            pop(false)
          }}
        >
          {t('cancel', { ns: 'common' })}
        </MainButton>,
        <MainButton
          key={'assignTemplates'}
          onClick={() => positionDrawingUpdate(customizedTemplates, selectedPositionDrawings?.items)}
        >
          {t('updateTemplates', { ns: 'mooring' })}
        </MainButton>,
      ]}
    >
      <Grid container px={1} columnSpacing={2}>
        <Grid item xs={2.5}>
          <SolidBox height={'660px'}>
            <Box padding={'8px'}>
              <Typography fontWeight={'600'} variant="subtitle1">
                {t('default-templates', { ns: 'mooring' })}
              </Typography>
            </Box>
            <Box className="flex flex-wrap-reverse items-center" sx={{ paddingLeft: '8px' }}>
              <Box
                className="flex items-center gap-3 p-2"
                sx={{ borderRadius: '8px' }}
                style={{ backgroundColor: theme.palette.white[theme.palette.mode] }}
              >
                <InputBase
                  style={{ width: 'auto' }}
                  autoFocus={false}
                  placeholder={t('search', { ns: 'common' }) + '...'}
                  inputProps={{ 'aria-label': 'search' }}
                  defaultValue={''}
                  onChange={event => onSearchInputChange(event.target.value)}
                />
                <Box className="ml-3 ">
                  <MdSearch color={theme.palette.searchIcon.main} size={24} />
                </Box>
              </Box>
            </Box>
            <Box padding={'8px'}>
              <ScrollableBox>
                {positionDrawings != undefined && positionDrawings?.length > 0 ? (
                  positionDrawings?.map(value => {
                    return (
                      <PositionDrawingCard
                        key={value.id}
                        positionDrawing={value}
                        setSelectedTemplate={p => {
                          setSelectedTemplate(p)
                        }}
                        border={`1px solid ${theme.palette.gray70.main}`}
                        isDefaultDrawing={true}
                        toolTipText={''}
                        isSelected={selectedTemplate?.id == value.id}
                      />
                    )
                  })
                ) : (
                  <EmptyStatusCard
                    image={NoTemplatesAvailable}
                    header={translate('no-templates-available-header')}
                    content={translate('no-templates-available-content')}
                    imageWidth={'330px'}
                  />
                )}
              </ScrollableBox>
            </Box>
          </SolidBox>
        </Grid>
        <Grid item xs={7}>
          <Box height={'660px'}>
            <Grid item xs={12}>
              <Box
                height={'300px'}
                boxShadow={selectedTemplate != null ? `0px 3px 6px ${theme.palette.veryDarkTransparentBlack.main}` : ''}
                border={selectedTemplate == null ? `1px solid ${theme.palette.gray70.main}` : ''}
                borderRadius={'8px'}
              >
                {selectedTemplate == null ? (
                  <Box>
                    <EmptyStatusCard
                      image={EmptyTemplateImage}
                      header={translate('no-template-selected-header')}
                      content={translate('no-template-selected-content')}
                      imageWidth={'250px'}
                      height={'300px'}
                    />
                  </Box>
                ) : (
                  <img
                    src={positionDrawing?.fileUrl}
                    style={{ maxHeight: '300px', width: '1045px', borderRadius: '4px' }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box paddingTop={'4px'}>
                <Box
                  bgcolor={theme.palette.white[theme.palette.mode]}
                  height={'355px'}
                  border={`1px solid ${theme.palette.gray70.main}`}
                  borderRadius={'4px'}
                  padding={'2px'}
                >
                  {selectedTemplate == null ? (
                    <EmptyStatusCard
                      image={PositionDrawingEmptyStatus2x}
                      header={translate('no-templates-header')}
                      content={translate('no-templates-content')}
                      imageWidth={'330px'}
                    />
                  ) : (
                    <CustomizeComponentContainer
                      positionDrawing={positionDrawing}
                      setCustomizedTemplates={setCustomizedTemplates}
                      setSelectedTemplate={setSelectedTemplate}
                      addDrawing={addDrawing}
                      initialProducts={positionDrawingProductsList}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <DashedBox height={'660px'}>
            <Box padding={'8px'}>
              <Typography fontWeight={'600'} variant="subtitle1">
                {t('selected-templates', { ns: 'mooring' })}
              </Typography>
            </Box>
            <Box padding={'8px'}>
              <Box>
                {selectedPositionDrawings?.items == null ? (
                  <EmptyStatusCard
                    image={PositionDrawingEmptyStatus2x}
                    header={translate('no-selected-templates-header')}
                    content={translate('no-selected-templates-content')}
                    imageWidth={'330px'}
                  />
                ) : (
                  <>
                    {customizedTemplates?.map(value => {
                      return (
                        <PositionDrawingCard
                          key={value.id}
                          positionDrawing={value}
                          border={`1px dashed ${theme.palette.gray70.main}`}
                          icon={<DeleteOutlineRoundedIcon htmlColor={theme.palette.primaryMain[theme.palette.mode]} />}
                          isDefaultDrawing={false}
                          toolTipText={translate('delete', { ns: 'common' })}
                          handleConfirmDelete={handleConfirmDelete}
                        />
                      )
                    })}
                  </>
                )}
              </Box>
            </Box>
          </DashedBox>
        </Grid>
      </Grid>
    </GenericDialogLayout>
  )
}
