import { Box, Paper, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { invalidateLocalityCache, useGetLocalityGroups } from '../../../api/localities'
import { Delete } from '@mui/icons-material'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { useDeleteLocalityGroup } from '../../../api/locality-groups-locality-links'
import AssignLocalityGroup from './AssignLocalityGroup'
import GenericTable from 'src/components/table/GenericTable'
import { MainButton } from 'src/components/MainButton'

const LocalityGroupsInLocality = ({ localityId }) => {
  const { t } = useTranslation(['access-control', 'common', 'locality'])
  const { showConfirmDialog, showDialog } = useDialogCtx()
  const { customer } = useCustomerCtx()
  const { data } = useGetLocalityGroups(customer.id, localityId)
  const { mutateAsync: deleteLocalityGroup } = useDeleteLocalityGroup(customer.id, localityId)

  const deleteLocalityGroupAction = id => {
    showConfirmDialog(
      t('delete-confirm-locality-group-title', { ns: 'locality' }),
      t('delete-confirm-locality-group-description', { ns: 'locality' }),
      { acceptText: t('yes', { ns: 'common' }), cancelText: t('no', { ns: 'common' }) },
      async pop => {
        await deleteLocalityGroup(id)
        invalidateLocalityCache.getLocalityGroups(customer.id, localityId)
        pop()
      },
    )
  }

  const assignLocality = () => {
    showDialog(AssignLocalityGroup, {
      componentProps: {
        customerId: customer.id,
        localityId,
      },
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      title: t('assign-locality-group'),
    })
  }

  const localityGroupsColumn: GridColDef[] = [
    {
      field: 'name',
      headerName: t('name', { ns: 'common' }),
      flex: 2,
      renderCell: params => params.row.name,
    },
    {
      field: 'description',
      headerName: t('description', { ns: 'common' }),
      flex: 2,
      renderCell: params => params.row.description,
    },
    {
      field: 'actions',
      headerName: '',
      maxWidth: 50,
      renderCell: params => {
        return (
          <Box className="cursor-pointer">
            <Delete color="primary" onClick={() => deleteLocalityGroupAction(params?.id)} />
          </Box>
        )
      },
    },
  ]

  return (
    <Box component={Paper} padding={1} height={200}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant="h6">{t('locality-groups')}</Typography>
        <MainButton onClick={assignLocality}>{t('assign-locality-group', { common: 'access-control' })}</MainButton>
      </Box>
      <GenericTable
        id={'locality-locality-groups'}
        columns={localityGroupsColumn}
        data={data || []}
        getRowId={row => row.id}
        height={150}
        className={'scroll-grid'}
      />
    </Box>
  )
}

export default LocalityGroupsInLocality
