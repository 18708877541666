import React, { useEffect, useMemo, useState } from 'react'
import { useGetAllAudits } from '../../../api/audit-logs'
import { IAuditLog } from '../../../generated-types/audit-logs'
import Button from '@mui/material/Button'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { GridColumns, useGridApiRef } from '@mui/x-data-grid-pro'
import GenericTable from '../../../components/table/GenericTable'
import { Grid, Modal, styled, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

const AuditLogsTable: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [json, setJson] = useState({} as any)
  const [title, setTitle] = useState('')
  const [rows, setRows] = useState<IAuditLog[]>([])
  const { t: translate } = useTranslation(['audit-logs', 'common'])

  const { data, isLoading } = useGetAllAudits()

  useEffect(() => {
    if (data) {
      setRows(data)
    }
  }, [data])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const apiRef = useGridApiRef()

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'tableName',
        headerName: translate('tableName', { ns: 'audit-logs' }),
        flex: 1,
      },
      {
        field: 'userName',
        headerName: translate('uName', { ns: 'audit-logs' }),
        flex: 1,
      },
      {
        field: 'dateTime',
        headerName: translate('dateTime', { ns: 'audit-logs' }),
        flex: 1,
        valueGetter: value => moment(value.value).format('MM/DD/YYYY hh:mm:ss A'),
      },
      {
        field: 'type',
        headerName: translate('type', { ns: 'audit-logs' }),
        flex: 0.6,
        renderCell: value => {
          return <Typography>{translate(value.value.toString().toLowerCase(), { ns: 'audit-logs' })}</Typography>
        },
      },
      {
        field: 'changes',
        headerName: translate('changes', { ns: 'audit-logs' }),
        flex: 0.75,
        cellClassName: 'font-weight-semi-bold',
        renderCell: ({ row }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setTitle(translate('updatedData', { ns: 'audit-logs' }))
              switch (row.type) {
                case 'Create':
                  setTitle(translate('createdData', { ns: 'audit-logs' }))
                  setJson({
                    NewData: row.newData,
                  })
                  break
                case 'Update':
                  setJson({
                    Keys: row.keyValues,
                    Changes: row.changedColumns,
                    OldData: row.oldData,
                    NewData: row.newData,
                  })
                  break
                case 'Delete':
                  setTitle(translate('deletedData', { ns: 'audit-logs' }))
                  setJson({
                    Keys: row.keyValues,
                    OldData: row.oldData,
                  })
                  break
                default:
                  setJson({
                    Keys: row.keyValues,
                    Changes: row.changedColumns,
                    OldData: row.oldData,
                    NewData: row.newData,
                  })
              }
              handleOpen()
            }}
          >
            {translate('viewChange', { ns: 'audit-logs' })}
          </Button>
        ),
      },
    ],
    [],
  )

  return (
    <>
      <GenericTable
        id={'auditing-table'}
        columns={STATIC_COLUMNS}
        data={rows}
        defaultColumnOption={[...STATIC_COLUMNS.map(r => r.headerName as string)]}
        enableColumnOptions={true}
        enablePagination={true}
        enableExport={false}
        enableToolbar={true}
        pageSize={20}
        pageOptions={[10, 20, 50, 100]}
        rowColor={'#e1e1e1'}
        enableFilter={false}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="json-modal-title"
        aria-describedby="json-modal-description"
      >
        <DetailContainer style={{ height: window.innerHeight - 100, overflow: 'scroll' }}>
          <DetailTitle variant="h5" id="json-modal-title">
            {title}
          </DetailTitle>
          <Grid container>
            <Grid xs={12}>
              <JsonContainer variant="body1" id="json-modal-description">
                {json?.Keys?.Id ? `Id : ${json?.Keys?.Id}` : ''}
              </JsonContainer>
            </Grid>
            <Grid xs={12}>
              <JsonContainer variant="body1" id="json-modal-description">
                {json?.Changes ? `Changes : [${json?.Changes}]` : ''}
              </JsonContainer>
            </Grid>
            <Grid xs={12}>
              <Table sx={{ marginTop: '10px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Affected Column</TableCell>
                    {Object.keys(json?.NewData ?? {}).length > 0 && (
                      <TableCell sx={{ fontWeight: 'bold' }}> New Data</TableCell>
                    )}
                    {Object.keys(json?.OldData ?? {}).length > 0 && (
                      <TableCell sx={{ fontWeight: 'bold' }}>Old Data</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {json?.Changes?.map((value, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{value}</TableCell>
                      <TableCell>{json?.NewData?.[value]?.toString()}</TableCell>
                      <TableCell>{json?.OldData?.[value]?.toString()}</TableCell>
                    </TableRow>
                  )
                })}
                {!json?.Changes && json?.NewData
                  ? Object.keys(json?.NewData)?.map((value, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{value}</TableCell>
                          <TableCell>{json?.NewData?.[value]?.toString()}</TableCell>
                        </TableRow>
                      )
                    })
                  : ''}
                {!json?.Changes && json?.OldData
                  ? Object.keys(json?.OldData)?.map((value, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{value}</TableCell>
                          <TableCell>{json?.OldData?.[value]?.toString()}</TableCell>
                        </TableRow>
                      )
                    })
                  : ''}
              </Table>
              {/* {json.NewData && json.OldData ? '' : json.NewData ? '' : ''} */}
            </Grid>
          </Grid>
        </DetailContainer>
      </Modal>
    </>
  )
}

const DetailContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  backgroundColor: getThemeColor(theme, MUIThemeColors.white),
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: 'none',
}))

const DetailTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}))

const JsonContainer = styled(Typography)({
  whiteSpace: 'pre',
  overflow: 'hidden',
})

export default AuditLogsTable
