import { useTranslation } from 'react-i18next'
import React from 'react'
import { Button, Stack, styled } from '@mui/material'
import { MapComponent } from '../screens/localities/components/map/MapComponent'
import { IMooringType, IPosition } from '../generated-types'
import { CancelButton } from './CancelButton'
import { ShowDialogProps } from 'src/show-dialog'
import { GenericDialogLayout } from './GenericDialogLayout'

export const MapOuterStack = styled(Stack)(() => ({
  height: '70vh',
  width: '100%',
}))
interface OlexUploadConfirmDialogProps extends ShowDialogProps {
  isConfirming: boolean
  updatedPositions: IPosition[]
  mooringType: IMooringType
  handleConfirm: (updatedPositions: IPosition[], pop) => void
  setFileName?: React.Dispatch<React.SetStateAction<null>> | undefined
  setUpdatedPositions: React.Dispatch<React.SetStateAction<IPosition[]>>
}

export const OlexUploadConfirmDialog: React.FC<OlexUploadConfirmDialogProps> = ({
  isConfirming,
  updatedPositions,
  mooringType,
  handleConfirm,
  setFileName,
  setUpdatedPositions,
  pop,
}) => {
  const { t } = useTranslation(['work-assignment', 'common'])
  return (
    <GenericDialogLayout
      title={t('verify-positions')}
      removedCloseButton
      pop={pop}
      actions={[
        <Stack
          className="flex justify-end gap-4 pt-4"
          key={'map-confirm-positions'}
          direction="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <CancelButton
            onClick={() => {
              pop(true)
              setFileName && setFileName(null)
              setUpdatedPositions([])
            }}
          >
            {t('cancel', { ns: 'common' })}
          </CancelButton>
          <Button variant="contained" disabled={isConfirming} onClick={() => handleConfirm(updatedPositions, pop)}>
            {t('verify-positions')}
          </Button>
        </Stack>,
      ]}
    >
      <MapOuterStack direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
        {
          <MapComponent
            positions={updatedPositions as IPosition[]}
            key={'olex-map-updated-confirm-positions'}
            mooringType={mooringType}
            layerControl={false}
            zoomControl={false}
            attributionControl={false}
            isModalView={true}
          />
        }
      </MapOuterStack>
    </GenericDialogLayout>
  )
}
