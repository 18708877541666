import { Grid } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IDocument } from 'src/generated-types/document'
import { bytesToSize, getFormattedDate } from 'src/utils'
import { ContentTypography, TitleTypography } from '../../deviations/DeviationDetailSection'
import { Flex } from 'src/styles/flexComponent'
import { StyledDetailCard } from 'src/styles/Index'
import { getLocalDateTime, getMooringName } from 'src/utils/function'

export const DocumentDetailsSection: FC<{ data: IDocument }> = ({ data }) => {
  const { t } = useTranslation(['document', 'common', 'certificate'])
  return (
    <StyledDetailCard elevation={0} sx={{ padding: '26px 24px', marginRight: '16px', marginLeft: '8px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy="documentType">
            <TitleTypography variant="subtitle2">{t('documentType', { ns: 'certificate' })}</TitleTypography>
            <ContentTypography variant="subtitle1">
              {t(data?.documentCategory?.categoryName as string, { ns: 'document' })}
            </ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy="documentDueDate">
            <TitleTypography variant="subtitle2">{t('dueDate', { ns: 'common' })}</TitleTypography>
            <ContentTypography variant="subtitle1">
              {getFormattedDate(getLocalDateTime(data?.dueDate))}
            </ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy="version">
            <TitleTypography variant="subtitle2">{t('version', { ns: 'document' })}</TitleTypography>
            <ContentTypography variant="subtitle1">{data?.version ?? '-'}</ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy="fileType">
            <TitleTypography variant="subtitle2">{t('fileContentType', { ns: 'file-upload' })}</TitleTypography>
            <ContentTypography variant="body1">{data?.fileContentType ?? '-'}</ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy="fileSize">
            <TitleTypography variant="subtitle2">{t('fileSize', { ns: 'file-upload' })}</TitleTypography>
            <ContentTypography variant="subtitle1">{bytesToSize(data?.fileContentLength as number)}</ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy="documentNotes">
            <TitleTypography variant="subtitle2">{t('notes', { ns: 'common' })}</TitleTypography>
            <ContentTypography variant="subtitle1">{data?.notes ?? '-'}</ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column>
            <TitleTypography variant="subtitle2">{t('mooring', { ns: 'common' })}</TitleTypography>
            <ContentTypography variant="subtitle1">
              {data?.mooring?.name ? getMooringName(data?.mooring?.name) : '-'}
            </ContentTypography>
          </Flex.Column>
        </Grid>
      </Grid>
    </StyledDetailCard>
  )
}
