import { t as translate } from 'i18next'
import { useGetPositions } from '../../../api/positions'
import { GridColumns } from '@mui/x-data-grid-pro'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { IMooring, IPosition, IPositionType } from '../../../generated-types'
import { APIResultCount } from '../../../constant'
import { useFormContext } from 'react-hook-form'
import { useGetMoorings } from 'src/api/moorings'
import { Flex } from 'src/styles/flexComponent'
import MooringSelectCard from '../MooringSelectCard'
import { Padding } from 'src/styles/paddingComponent'
import GenericTable from 'src/components/table/GenericTable'
import { PositionTableFilter } from 'src/screens/localities/moorings/PositionTableFilter'
import { Box } from '@mui/material'
import { getMooringName } from 'src/utils/function'

interface ISelectPositionStepProps {
  customerId: EntityId
  localityId: EntityId
  setDisabled: Dispatch<SetStateAction<boolean>>
  handleNext: () => void
}
const SelectPositionStep: FC<ISelectPositionStepProps> = ({ customerId, localityId, setDisabled, handleNext }) => {
  const { getValues, reset } = useFormContext()
  const [filteredTypes, setFilteredTypes] = useState<IPositionType[]>([])
  const [searchInput, setSearchInput] = useState('')
  const [mooringId, setMooringId] = useState<number>()
  const { data: positions, isLoading, refetch } = useGetPositions(customerId, localityId, mooringId, 0, 1000)
  const { data: moorings } = useGetMoorings(
    customerId,
    localityId,
    { getOnlyActiveMooring: true },
    0,
    APIResultCount.Max,
  )

  const filteredPositions = useMemo(() => {
    const filteredPositions =
      filteredTypes.length === 0
        ? positions?.items
        : positions?.items?.filter(p => filteredTypes.includes(p.type)) ?? []
    return filteredPositions?.filter(
      p =>
        p.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) ||
        p.mooringName?.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) ||
        translate(IPositionType[p.type]).toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()),
    )
  }, [filteredTypes, positions?.items, searchInput])

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: translate('name', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
        filterable: true,
      },
      {
        field: 'type',
        headerName: translate('type', { ns: 'common' }),
        flex: 1,
        valueGetter: value => translate(IPositionType[value.value], { ns: 'position' }),
      },
      {
        field: 'mooringName',
        headerName: translate('mooring', { ns: 'common' }),
        flex: 1,
        renderCell: params => {
          return getMooringName(params.value)
        },
      },
    ],
    [],
  )

  const columns: GridColumns = useMemo(() => [...STATIC_COLUMNS], [])

  useEffect(() => {
    if (getValues('position')?.id === undefined) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [getValues('position'), setDisabled])

  useEffect(() => {
    refetch()
  }, [getValues('mooring')?.id])

  const handleRowClick = useCallback(
    params => {
      if (params.row.id !== getValues('position')?.id) {
        reset({
          ...getValues(),
          position: params.row as IPosition,
          mooring: { id: params.row.mooringId, name: params.row.mooringName } as IMooring,
          component: undefined,
          mainComponent: undefined,
        })
      } else {
        reset({
          ...getValues(),
          position: params.row as IPosition,
          mooring: { id: params.row.mooringId, name: params.row.mooringName } as IMooring,
        })
      }
      setDisabled(false)
      handleNext()
    },
    [handleNext, setDisabled],
  )

  return (
    <>
      <Flex.Row overflow={'auto'} className="p-8-core">
        {moorings?.items.map(mooring => (
          <Padding.pr16 key={mooring.id} height={'72px'} minWidth={'246px'}>
            <MooringSelectCard
              mooring={mooring}
              onClick={() => setMooringId(mooring.id)}
              isSelected={mooring?.id == mooringId}
            />
          </Padding.pr16>
        ))}
      </Flex.Row>
      <Box width={'99.9%'} paddingX={'8px'} pt={'16px'}>
        <GenericTable
          columns={columns}
          data={[...([...(filteredPositions || [])]?.sort(x => parseInt(x.name))?.reverse() || [])] as IPosition[]}
          enableToolbar={true}
          height={350}
          enableToolbarFilter={true}
          toolbarFilter={
            <PositionTableFilter
              setFilteredTypes={setFilteredTypes}
              filteredTypes={filteredTypes}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
            />
          }
          loading={isLoading}
          onRowClick={handleRowClick}
          selectionModel={getValues('position') ? [getValues('position').id] : []}
          id={'create-deviation-positions'}
          autoHeight={false}
          sortModel={[{ field: 'name', sort: 'asc' }]}
        />
      </Box>
    </>
  )
}

export default SelectPositionStep
