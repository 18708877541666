import { Box, useTheme } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { UserTable } from './UserTable'
import { t } from 'i18next'
import { RoleTypes } from '../../../enums'
import { permissionBinding, permissionLevel } from '../../../constant'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { getCustomerUsers } from '../../../http'
import { invalidateCustomer } from '../../../api/customer'
import { AssignUsers } from '@lovoldsystem/authorization.client'
import { FunctionElementsParentsIds } from '../../../enums/auth'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

export const UsersContainer: FC<{ isAdminLayout?: boolean; isGlobalServiceProvider?: boolean }> = ({
  isAdminLayout,
  isGlobalServiceProvider,
}) => {
  const { t: translate } = useTranslation(['users', 'common'])
  const { id } = useParams()
  const { showDialog } = useDialogCtx()
  const theme = useTheme()
  const location = useLocation()

  const handleNewUserAdd = () => {
    showDialog(
      AssignUsers,
      {
        title: translate('add-client-users', { ns: 'users' }),
        componentProps: {
          clientId: id as any,
          getUsersAction: getCustomerUsers,
          roleTypes:
            isGlobalServiceProvider || (location?.state as object)?.['isGlobalServiceProvider']
              ? [RoleTypes.ServiceStation, RoleTypes.Client]
              : [RoleTypes.Client],
          assignCompleted: () => {
            invalidateCustomer.getCustomerUsersForAdmins(id as EntityId, true)
          },
          permissionLevel: permissionLevel,
          permissionBinding: permissionBinding,
          assignToAlert: t('permission-types', { ns: 'user-resources' }),
          readOnly: true,
          functionElementParents:
            isGlobalServiceProvider || (location?.state as object)?.['isGlobalServiceProvider']
              ? [FunctionElementsParentsIds.SystemPortal, FunctionElementsParentsIds.ServiceStationPortal]
              : [
                  FunctionElementsParentsIds.SystemPortal,
                  FunctionElementsParentsIds.LocalityPortal,
                  FunctionElementsParentsIds.ServiceStationPortal,
                ],
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
    )
  }

  return (
    <CommonPageLayout
      titleSection={{
        title: translate('users', { ns: 'users' }),
      }}
      topAction={[
        {
          buttonText: translate('addUser', { ns: 'users' }),
          buttonIcon: <AddCircleOutline />,
          onClick: handleNewUserAdd,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
      enableBackButton={isAdminLayout}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <UserTable
          isGlobalServiceProvider={isGlobalServiceProvider || (location?.state as object)?.['isGlobalServiceProvider']}
          customerId={id as EntityId}
          isAdminLayout={isAdminLayout}
        />
      </Box>
    </CommonPageLayout>
  )
}
