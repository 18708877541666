import * as React from 'react'
import Box from '@mui/material/Box'
import { ShowDialogProps } from '../../../../show-dialog'
import { CloseRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'

interface EnlargeModalProps extends ShowDialogProps {
  content: React.ReactElement
  height?: string | undefined
  iconTop?: number
  iconRight?: number
}

export const EnlargeModal: React.FC<EnlargeModalProps> = ({ pop, content, height, iconTop = 30, iconRight = 30 }) => {
  return (
    <Box height={height}>
      <IconButton style={{ position: 'absolute', top: iconTop, right: iconRight, zIndex: 9999 }} onClick={pop}>
        <CloseRounded/>
      </IconButton>
      {content}
    </Box>
  )
}
