import L from 'leaflet'
import { useState } from 'react'
import { MapContainer, Pane } from 'react-leaflet'
import { ILocality } from 'src/generated-types'
import { BoatController } from '../map/components/BoatController'
import { BaseTileLayer } from '../map/components/MapStaticContent'
import { maxZoom } from '../map/constraints'
import { MapBaseContainer } from '../map/LeafletMapContainer'
import MapBoundsComponent from '../map/MapBoundsComponent'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import LocalityLayer from './LocalityLayer'
import VesselLayers from '../map/layers/VesselLayers'
import { LocationController } from '../map/components/LocationController'
import { GestureHandlingComponent } from '../map/components/GestureHandlingComponent'

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

export interface MapComponentProps {
  localities?: ILocality[]
  zoom?: number
  navigateLocalityWhenClick?: boolean
  customUrl?: string
  gestureHandling?: boolean
}

const LocalityLandingMap = ({
  localities,
  zoom,
  customUrl,
  gestureHandling = true
}: MapComponentProps) => {
  const [vessel, setVessel] = useState(true)
  const [enableLocality, setEnableLocality] = useState(true)
  const zoomLevel = zoom ?? 16

  return (
    <MapBaseContainer id="controller-div">
      <MapContainer
        zoom={zoomLevel}
        zoomDelta={0.25}
        maxZoom={maxZoom}
        zoomSnap={0}
        className="w-full h-full rounded-lg"
      >
        {<MapBoundsComponent localities={localities} />}
        <BaseTileLayer customUrl={customUrl} />
        <Pane name="boats" style={{ zIndex: 404 }} />
        <Pane name="circlePoints" style={{ zIndex: 600 }} />
        <BoatController
          enable={vessel}
          onClick={() => {
            setVessel(vesselData => !vesselData)
          }}
        />
        {enableLocality && <LocalityLayer localities={localities} enableCluster={true} />}
        {vessel && <VesselLayers />}
        <LocationController
          onClick={() => {
            setEnableLocality(isLocality => !isLocality)
          }}
        />
        {gestureHandling && <GestureHandlingComponent/>}
      </MapContainer>
    </MapBaseContainer>
  )
}

export default LocalityLandingMap
