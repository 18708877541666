import { useTranslation } from 'react-i18next'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { Box, FormHelperText, css, styled } from '@mui/material'
import { MainButton } from '../MainButton'
import { GenericDialogLayout } from '../GenericDialogLayout'
import { EmptyStatusCard } from '../mooring/EmptyStatusCard'
import OlexFileIcon from '../../assets/svg/OlextFileIcon.svg' // This needs to get as a prop after design is completed to show uploaded file

const p_12px = css`
  padding: 12px;
`

const DragAndDropBox = styled(Box)`
  border: 1px dashed ${props => props.theme.palette.paleBlue[props.theme.palette.mode]};
  border-radius: 6px;
  height: 315px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${p_12px}
`

interface CommonFileUploaderDialogProps {
  disabled?: boolean
  acceptedFileTypes?: string[]
  maxFileSize?: number
  required?: boolean
  onUploadFile?: (fileObj: any) => Promise<void>
  onCancel?: () => any
  title: string
  uploadButtonText?: string
  emptyStatus: ReactElement
}

export const CommonFileUploaderDialog: React.FC<CommonFileUploaderDialogProps> = ({
  onCancel,
  maxFileSize,
  acceptedFileTypes,
  disabled = false,
  required = false,
  onUploadFile,
  title,
  uploadButtonText,
  emptyStatus,
}) => {
  const { t } = useTranslation('file-upload')
  const [uploadedFile, setUploadedFile] = useState<any>()
  const id = useMemo(() => uuid(), [])
  const [isValidFile, setIsValidFile] = useState<boolean>(false)

  useEffect(() => {
    if (
      !acceptedFileTypes ||
      (acceptedFileTypes.includes(uploadedFile?.type) && (!maxFileSize || uploadedFile?.size < maxFileSize))
    ) {
      setIsValidFile(true)
    }
  }, [acceptedFileTypes, maxFileSize, uploadedFile])

  const onDrop: DropzoneOptions['onDrop'] = async uploadedFiles => {
    const uploadedFile = uploadedFiles[0]

    if (acceptedFileTypes)
      if (!acceptedFileTypes.includes(uploadedFile.type)) {
        toast(`${t('invalid-type')} ${uploadedFile.name} ${t('allowed-types')} ${acceptedFileTypes.join(', ')}`, {
          type: 'error',
        })
      }

    if (maxFileSize && uploadedFile.size > maxFileSize) {
      toast(
        `${t('file-size-big')} ${uploadedFile.name} ${t('upload-size')} ${uploadedFile.size} ${t(
          'max-allowed',
        )} ${maxFileSize}`,
        { type: 'error' },
      )
    }
    setUploadedFile(uploadedFile)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 104857600,
  })

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      removedCloseButton={false}
      actions={[
        <MainButton
          disabled={!isValidFile}
          key="cert-submit"
          onClick={() => onUploadFile && onUploadFile(uploadedFile)}
        >
          {uploadButtonText ? uploadButtonText : t('upload', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      <DragAndDropBox {...(disabled ? () => { } : getRootProps())}>
        <input id={id} {...getInputProps()} />
        {!isValidFile ? (
          emptyStatus
        ) : (
          <EmptyStatusCard image={OlexFileIcon} header={uploadedFile?.name} content={''} imageWidth={''} />
        )}
        {required == true && uploadedFile?.size === 0 && (
          <FormHelperText error={true} sx={{ textAlign: 'center' }}>
            ({t('provideValidFile', { ns: 'common' })})
          </FormHelperText>
        )}
      </DragAndDropBox>
    </GenericDialogLayout>
  )
}