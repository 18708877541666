import { IConnectedPosition } from '../generated-types'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId, mooringId: EntityId, positionId: EntityId) =>
    `customers/${customerId}/moorings/${mooringId}/positions/${positionId}/connected-positions`

export const connectedPositionEndpoints = {
    getConnectedPositions(customerId: EntityId, mooringId: EntityId, positionId: EntityId) {
        return `${baseUrl(customerId, mooringId, positionId)}`
    },
    getConnectedPosition(customerId: EntityId, mooringId: EntityId, positionId: EntityId, id: EntityId) { return `${baseUrl(customerId, mooringId, positionId)}/${id}` },
    createConnectedPosition(customerId: EntityId, mooringId: EntityId, positionId: EntityId) { return `${baseUrl(customerId, mooringId, positionId)}` },
    updateConnectedPosition(customerId: EntityId, mooringId: EntityId, positionId: EntityId, id: EntityId) { return `${baseUrl(customerId, mooringId, positionId)}/${id}` },
    deleteConnectedPosition(customerId: EntityId, mooringId: EntityId, positionId: EntityId) { return `${baseUrl(customerId, mooringId, positionId)}` }
}

export const invalidateConnectedPositionCache = {
    getConnectedPosition(customerId: EntityId, mooringId: EntityId, positionId: EntityId, id: EntityId) { queryClient.invalidateQueries(connectedPositionEndpoints.getConnectedPosition(customerId, mooringId, positionId, id)) },
    getConnectedPositions(customerId: EntityId, mooringId: EntityId, positionId: EntityId, page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) {
        queryClient.invalidateQueries(connectedPositionEndpoints.getConnectedPositions(customerId, mooringId, positionId))
    },
}

export const useGetConnectedPositions = (customerId: EntityId, mooringId: EntityId, positionId: EntityId, page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
    useGet<IPaginatedResult<IConnectedPosition>>(connectedPositionEndpoints.getConnectedPositions(customerId, mooringId, positionId), { page, pageSize })

export const useGetConnectedPosition = (customerId: EntityId, mooringId: EntityId, positionId: EntityId, id: EntityId) =>
    useGet<IConnectedPosition>(connectedPositionEndpoints.getConnectedPosition(customerId, mooringId, positionId, id))

export const useAddConnectedPosition = (customerId: EntityId, mooringId: EntityId, positionId: EntityId) =>
    usePost<IConnectedPosition, IConnectedPosition>(connectedPositionEndpoints.createConnectedPosition(customerId, mooringId, positionId))

export const useUpdateConnectedPosition = (customerId: EntityId, mooringId: EntityId, positionId: EntityId, id: EntityId) =>
    usePut<IConnectedPosition, IConnectedPosition>(connectedPositionEndpoints.updateConnectedPosition(customerId, mooringId, positionId, id))

export const useDeleteConnectedPosition = (customerId: EntityId, mooringId: EntityId, positionId: EntityId) =>
    useDelete<IConnectedPosition>(connectedPositionEndpoints.deleteConnectedPosition(customerId, mooringId, positionId))