import { useEffect } from 'react'
import { AppForm, AppFormProps, FormSubmitButton } from '../../../../components/FormCore'
import { CenteredSpinner } from '../../../../components/Spinner'
import { useZodForm } from '../../../../hooks/zod-form'
import { useTranslation } from 'react-i18next'
import { Autocomplete, Box, MenuItem, TextField } from '@mui/material'
import { ShowDialogProps } from '../../../../show-dialog'
import { z } from 'zod'
import { CancelButton } from '../../../../components/CancelButton'
import {
  invalidateMainComponentCache,
  useAddMainComponentToLocality,
  useGetOutgoingMainComponents,
} from '../../../../api/main-component'
import { styled } from '@mui/system'
import { IMainComponent } from '../../../../generated-types/main-component'
import { invalidateCertificateCache } from '../../../../api/Certificates'
import { toast } from 'react-toastify'
import { NO_VALID_CERTIFICATE } from '../../../../constant'
import BasicDatePicker from '../../../../components/DateTimePicker'
import { invalidatePositionCache } from '../../../../api/positions'
import { invalidateLocalityCache } from 'src/api/localities'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { getUTCDate } from 'src/utils/function'
import { invalidateMooringCache } from 'src/api/moorings'

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primaryMain[theme.palette.mode],
  fontWeight: 700,
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const MainComponentSchema = z.object({
  mainComponentId: z.number(),
  effectiveDate: z.date().or(z.string().transform(v => getUTCDate(new Date(v)) || new Date(v))),
  selectedLocality: z.number().optional(),
})

export interface MainComponentAddFormProps extends AppFormProps {
  customerId: EntityId
  positionId: EntityId
  localityId: EntityId
}

const MainComponentAddForm: React.FC<MainComponentAddFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  localityId,
  initialValues,
}) => {
  const { t } = useTranslation(['position', 'common'])

  const form = useZodForm(MainComponentSchema, {
    defaultValues: { ...initialValues },
  })

  const { data, isLoading, isFetching } = useGetOutgoingMainComponents(customerId, localityId)

  useEffect(() => {
    if (data?.items.length > 0) {
      form.setValue('mainComponentId', data ? data?.items[0]?.id : '')
    }
  }, [data?.items])

  if (isLoading || isFetching) return <CenteredSpinner />

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
      <Box py={1} px={1}>
        <Autocomplete
          onChange={(_, value) => {
            form.setValue('mainComponentId', (value as IMainComponent).id)
          }}
          options={data?.items as IMainComponent[]}
          autoHighlight
          getOptionLabel={o => o.name}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {o.name}
            </MenuItem>
          )}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          defaultValue={data ? data.items[0] : ''}
        />
      </Box>
      <Box py={1}>
        <BasicDatePicker label={t('effectiveDate', { ns: 'main-component' })} name="effectiveDate" />
      </Box>
      <Box className="flex justify-end gap-4 pt-4">
        <FormSubmitButton createText={t('Add', { ns: 'common' })} disabled={data?.items?.length == 0} />
        <CancelButton onClick={onCancel}>{t('cancel', { ns: 'common' })}</CancelButton>
      </Box>
    </AppForm>
  )
}

interface MainComponentAddProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  positionId: EntityId
}

export const MainComponentAddComponent: React.FC<MainComponentAddProps> = ({
  pop,
  customerId,
  localityId,
  mooringId,
  positionId,
}) => {
  const { t } = useTranslation(['main-component', 'common'])
  const { mutateAsync, isLoading } = useAddMainComponentToLocality(customerId, positionId)
  const onSubmit = async form => {
    form.selectedLocalityId = localityId
    await mutateAsync(form, {
      onSuccess: () => {
        invalidateLocalityCache.getLocalityWithActivityCounts(customerId, localityId)
        invalidateLocalityCache.getLocality(customerId, localityId)
        invalidateMainComponentCache.getMainComponents(customerId, localityId, mooringId, positionId)
        invalidateMainComponentCache.getOutgoingMainComponents(customerId, localityId)
        invalidateCertificateCache.getCertificatesByPosition(customerId, positionId)
        invalidationActivityLogCache.getCageActivityLogs()
        invalidateLocalityCache.getCageStatus(customerId, localityId)
        invalidatePositionCache.getCagePositionDeviations(customerId, localityId, positionId)
        invalidateMooringCache.getMooringsForMap(customerId, localityId)
        invalidateMooringCache.getGetMooring(customerId, localityId, mooringId)
        toast.success(t('main-component-attached-to-cage-toast', { ns: 'main-component' }))
        pop()
      },
      onError: (error:any) => {
        if (error?.data == 'ExceedMaxCountForCage') {
          toast(t('exceedMaxCountForCage', { ns: 'main-component' }), { type: 'error' })
        }
        if (error && error['data'] == NO_VALID_CERTIFICATE) {
          toast(t('NoValidCertificate', { ns: 'service-station' }), { type: 'error' })
        }
      },
    })
  }

  return (
    <GenericDialogLayout title={t('addMainComponent', { ns: 'main-component' })}>
      <MainComponentAddForm
        onSubmit={onSubmit}
        onCancel={pop}
        customerId={customerId}
        localityId={localityId}
        positionId={positionId}
      />
    </GenericDialogLayout>
  )
}
