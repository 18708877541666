import { ReactElement, useState } from 'react'
import { IPosition } from '../generated-types'
import { useUploadOlex } from '../api/work-assignments'
import React from 'react'
import { ShowDialogProps } from '../show-dialog'
import { CommonFileUploaderDialog } from './common/CommonFileUploaderDialog'
import { useTranslation } from 'react-i18next'
import { EmptyStatusCard } from './mooring/EmptyStatusCard'
import OlexFileIcon from '../assets/svg/OlextFileIcon.svg'
import { Box, CircularProgress, styled } from '@mui/material'

export const CircularProgressBox = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  background-color: ${props => props.theme.palette.white[props.theme.palette.mode]};
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface OlexFileUploaderProps extends ShowDialogProps {
  UploadButton?: ReactElement
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  handleConfirm:(updatedPositions: IPosition[]) => void
}
export const OlexFileUploader: React.FC<OlexFileUploaderProps> = ({
  pop,
  customerId,
  localityId,
  mooringId,
  handleConfirm,
}) => {
  const { mutateAsync: uploadOlexAsync } = useUploadOlex(customerId, localityId, mooringId)
  const { t } = useTranslation('file-upload')
  const [isLoading, setIsLoading] = useState(false)

  const onUploadOlex = async fileObj => {
    setIsLoading && setIsLoading(true)
    const formData = new FormData()
    formData.append('file', fileObj)
    await uploadOlexAsync(formData).then(({ data }) => {
      handleConfirm(data)
    })
    setIsLoading && setIsLoading(false)
  }

  return (
    <>
      <CommonFileUploaderDialog
        onUploadFile={onUploadOlex}
        onCancel={pop}
        title={t('upload-olex', { ns: 'mooring' })}
        key="id"
        uploadButtonText={t('upload-olex', { ns: 'mooring' })}
        acceptedFileTypes={['application/gzip']}
        required={true}
        emptyStatus={
          <EmptyStatusCard
            image={OlexFileIcon}
            header={t('dragndrop-title', { ns: 'file-upload' })}
            content={t('dragndrop-content', { ns: 'file-upload' })}
            imageWidth={'50px'}
          />
        }
      />
      {isLoading && (
        <CircularProgressBox>
          <CircularProgress style={{ height: 20, width: 20 }} />
        </CircularProgressBox>
      )}
    </>
  )
}
