import { Box, FormHelperText, Grid, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppFormProps,
  AppForm,
  FormTextField,
  FormSelectField,
  FormCheckbox,
  FormSubmitButton,
} from '../../../../components/FormCore'
import { IPosition, IPositionType } from '../../../../generated-types'
import { useZodForm } from '../../../../hooks/zod-form'
import { ConnectedPositionsFields } from './ConnectedPositionField'
import { GenericDialogLayout } from '../../../../components/GenericDialogLayout'
import { getUpdatePositionSchema, showLatLang } from '../schema'
import { MainButton } from '../../../../components/MainButton'
import { AddPositionDrawingModalComponent } from './AddPositionDrawingModalComponent'
import { IPositionDrawing } from '../../../../generated-types/position-drawing'
import { useDialogCtx } from 'src/hooks/context-hooks'

export interface PositionFormProps extends AppFormProps {
  initialValues?: IPosition
  loading?: boolean
  positions?: IPosition[]
  title?: string
  isDialog?: boolean
  customerId: EntityId
  localityId: EntityId
}

export const PositionForm: React.FC<PositionFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  loading,
  positions,
  title,
  isDialog = true,
  customerId,
  localityId,
}) => {
  const { t } = useTranslation(['position', 'common'])
  const { showDialog } = useDialogCtx()
  const [selectedDrawing, setSelectedDrawing] = useState<IPositionDrawing | undefined>({
    id: initialValues?.positionDrawingId as number,
    drawingNumber: initialValues?.positionDrawingNumber as string,
    fileUrl: initialValues?.positionDrawingFileUrl as string,
  } as IPositionDrawing)
  const [positionType, setPositionType] = useState<IPositionType | undefined>(initialValues?.type)

  const positionTypes = Object.keys(IPositionType)
    .filter(v => isNaN(Number(v)))
    .map(t => IPositionType[t])
  const isUpdateForm = initialValues && initialValues.id > 0

  const form = useZodForm(getUpdatePositionSchema((initialValues?.type as IPositionType) ?? positionType), {
    defaultValues: { ...initialValues, connectedPositions: initialValues?.connectedPositions ?? [] },
  })
  useEffect(() => {
    if (initialValues) {
      form.reset({
        ...initialValues,
        connectedPositions: initialValues?.connectedPositions ?? [],
        positionDrawingId: selectedDrawing?.id,
      })
      setSelectedDrawing({
        id: selectedDrawing?.id,
        drawingNumber: selectedDrawing?.drawingNumber,
        fileUrl: selectedDrawing?.fileUrl,
      } as IPositionDrawing)
    }
  }, [form, initialValues, selectedDrawing?.id])

  useEffect(() => {
    form.setValue('positionDrawingId', selectedDrawing?.id)
  }, [selectedDrawing])

  const onClickSelectPositionDrawing = useCallback(async () => {
    const added = await showDialog(
      AddPositionDrawingModalComponent,
      {
        componentProps: {
          customerId: customerId as EntityId,
          localityId: localityId as EntityId,
          mooringId: initialValues?.mooringId as EntityId,
          positionId: initialValues?.id,
          positionDrawingId: selectedDrawing?.id as EntityId,
          positionType: (initialValues?.type ?? positionType) as IPositionType,
          setSelectedPositionDrawing: setSelectedDrawing as any,
          isUpdateForm: isUpdateForm,
          selectedPositionDrawing: selectedDrawing,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )

    if (!added) return
  }, [
    customerId,
    localityId,
    initialValues?.mooringId,
    initialValues?.id,
    initialValues?.positionDrawingId,
    initialValues?.type,
    positionType,
    isUpdateForm,
    selectedDrawing?.id,
    setSelectedDrawing,
  ])

  const Form = useMemo(() => {
    return (
      <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues != undefined}>
        <div id="positionForm-drawing-select" />
        <FormTextField name="mooringId" type="hidden" autoFocus={false} />
        <FormTextField name="positionDrawingId" type="hidden" autoFocus={false} />
        <FormTextField name="name" label={t('name', { ns: 'common' })} autoFocus={false} />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name="type"
              disabled={isUpdateForm ? true : false}
              data={positionTypes}
              label={t('type', { ns: 'common' })}
              getOptionLabel={option => t(IPositionType[option], { ns: 'position' })}
              renderOption={(props: any, option) => (
                <Box {...props} key={option}>
                  {t(IPositionType[option], { ns: 'position' })}
                </Box>
              )}
              onChange={(_, value) => {
                form.setValue('type', value)
                setPositionType(value as IPositionType)
              }}
            />
          </Grid>
          {positionType != null && positionType != IPositionType.Cage && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                {`${t('selected-drawing', { ns: 'position' })}: ${
                  selectedDrawing?.drawingNumber ?? t('n/a', { ns: 'common' })
                }`}
              </Typography>
              <Box
                className={`${''} rounded-lg`}
                sx={{
                  backgroundImage: `url(${selectedDrawing?.fileUrl})`,
                  backgroundColor: '#EBF9FC',
                  height: 100,
                  width: 150,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                onClick={onClickSelectPositionDrawing}
              >
                {!(initialValues?.positionDrawingFileUrl ?? selectedDrawing?.fileUrl) &&
                  t('click-to-update', { ns: 'common' })}
              </Box>
              {form.formState.errors.positionDrawingId && (
                <FormHelperText error={true} sx={{ textAlign: 'left' }}>
                  {t(`${form.formState.errors.positionDrawingId.message}`)}
                </FormHelperText>
              )}
            </Grid>
          )}
        </Grid>
        {showLatLang(positionType as IPositionType) && (
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormTextField
                inputProps={{ type: 'number', required: true }}
                name="latitude"
                label={t('latitude', { ns: 'common' })}
                autoFocus={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                inputProps={{ type: 'number', required: true }}
                name="longitude"
                label={t('longitude', { ns: 'common' })}
                autoFocus={false}
              />
            </Grid>
          </Grid>
        )}
        {form.getValues('type') == (IPositionType.MooringLine as number) && (
          <Stack
            direction="row"
            sx={{
              width: '49%',
            }}
          >
            <ConnectedPositionsFields
              positions={positions as IPosition[]}
              positionId={form.getValues('id')}
              name={'connectedPositions'}
              connectingPositionType={IPositionType.MooringLine as number}
            />
            <FormHelperText id="connctedPositions-error" error>
              {form.formState.errors?.connectedPositions?.message}
            </FormHelperText>
          </Stack>
        )}
        <FormCheckbox name="isActive" label={t('active', { ns: 'common' })} autoFocus={false} />
        <FormCheckbox name="mooringAnalyseAppliance" label={t('mooringAnalyseAppliance', { ns: 'position' })} />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormTextField
              type="number"
              name="mbl"
              required={true}
              label={t('minimumBreakingLoad', { ns: 'position' })}
              autoFocus={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              type="number"
              name="accidentLoad"
              required={true}
              label={t('accident-load', { ns: 'position' })}
              autoFocus={false}
            />
          </Grid>
          {(form.getValues('type') == (IPositionType.Cage as number) || positionType == IPositionType.Cage) && (
            <Grid item xs={12} sm={6}>
              <FormTextField type="number" name="width" label={t('width', { ns: 'position' })} autoFocus={false} />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormTextField
              inputProps={{ type: 'number' }}
              name="depth"
              label={t('depth', { ns: 'main-component' })}
              autoFocus={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField type="string" name="reference" label={t('reference', { ns: 'common' })} autoFocus={false} />
          </Grid>
        </Grid>
        <FormTextField
          type="string"
          name="note"
          label={t('note', { ns: 'common' })}
          multiline
          rows={4}
          autoFocus={false}
        />
        {!isDialog && (
          <Box className="flex justify-end gap-4 pt-4">
            <FormSubmitButton
              disabled={loading}
              isLoading={loading}
              createText={isUpdateForm ? t('update', { ns: 'common' }) : t('add', { ns: 'common' })}
            />
          </Box>
        )}
      </AppForm>
    )
  }, [
    form,
    isUpdateForm,
    positionType,
    initialValues,
    isDialog,
    selectedDrawing,
    loading,
    positions,
    onClickSelectPositionDrawing,
    t,
    onSubmit,
    positionTypes,
  ])

  return (
    <>
      {isDialog ? (
        <GenericDialogLayout
          title={title as string}
          pop={onCancel}
          removedCloseButton={false}
          actions={[
            <MainButton
              key="position-form-btn"
              loading={loading}
              onClick={() => {
                form.handleSubmit(onSubmit)()
              }}
            >
              {isUpdateForm ? t('update', { ns: 'common' }) : t('add', { ns: 'common' })}
            </MainButton>,
          ]}
        >
          {Form}
        </GenericDialogLayout>
      ) : (
        { Form }
      )}
    </>
  )
}
