import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { ServiceStationGroupsTable } from './ServiceStationGroupsTable'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { ServiceStationGroupDialog } from './ServiceStationGroupDialog'
import { FC, useCallback } from 'react'
import { ServiceStationGroupContainer } from './ServiceStationGroupContainer'
import { AlertDialog } from '@lovoldsystem/ui'
import { toast } from 'react-toastify'
import {
  invalidateServiceStationGroupsQueries,
  serviceStationGroupsEndpoints,
  useDeleteServiceStationGroup,
  useUpdateServiceStationGroup,
} from '../../../api/service-station-group'
import { IServiceStationGroup } from '../../../generated-types'
import { ResourceScreenProps } from '../../../generated-types/resource-screen'
import { serviceStationGroupQueryKey } from '../../../query-keys'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { AddCircleOutline } from '@mui/icons-material'

export const ServiceStationGroupsScreen: FC<ResourceScreenProps> = ({
  renderTableExpand,
  query,
  params,
  generateQueryKey,
  handleUpdate,
  handleDelete,
  handleAdd,
  isActionAvailable = true,
  isTableExpandable = true,
}) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation('service-station-group')
  const { showDialog } = useDialogCtx()
  const theme = useTheme()

  const handleGroupAdd = useCallback(() => {
    showDialog(
      ServiceStationGroupDialog,
      {
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        componentProps: {
          customerId: customer.id,
          title: t('add-service-station-group', { ns: 'service-station-group' }),
        },
      },
      undefined,
      true,
    )
  }, [customer.id, t])

  const { mutateAsync: deleteServiceStationGroup } = useDeleteServiceStationGroup(customer.id)
  const { mutateAsync: updateServiceStationGroup } = useUpdateServiceStationGroup(customer.id)

  const handleGroupEdit = useCallback(
    (initialValues: IServiceStationGroup) => {
      showDialog(
        ServiceStationGroupDialog,
        {
          opts: {
            maxWidth: 'sm',
            fullWidth: true,
          },
          componentProps: {
            customerId: customer.id,
            title: t('update-service-station-group', { ns: 'service-station-group' }),
            initialValues: initialValues,
            onSubmit: async values => {
              await updateServiceStationGroup({ ...values })
                .then(() => {
                  toast.success(t('service-station-group-updated-successfully', { ns: 'service-station-group' }))
                })
                .catch(() => {
                  toast.error(t('service-station-group-update-error', { ns: 'service-station-group' }))
                })
            },
          },
        },
        undefined,
        true,
      )
    },
    [customer.id, t],
  )

  const handleGroupDelete = useCallback(
    async id => {
      showDialog(
        AlertDialog,
        {
          componentProps: {
            title: t('delete-service-station-group', { ns: 'service-station-group' }),
            acceptText: t('delete', { ns: 'common' }),
            text: t('delete-confirmation-message', { ns: 'service-station-group' }),
            onConfirm: async pop => {
              await deleteServiceStationGroup(id, {
                onSuccess: () => {
                  invalidateServiceStationGroupsQueries.getServiceStationGroups(customer.id as EntityId)
                  toast.success(t('service-station-group-deleted-successfully', { ns: 'service-station-group' }))
                },

                onError: () => {
                  toast.error(t('service-station-group-deleted-error', { ns: 'service-station-group' }))
                },
              })
              pop()
            },
          },
        },
        undefined,
        true,
      )
    },
    [customer.id],
  )

  return (
    <CommonPageLayout
      titleSection={{ title: t('service-station-group') }}
      topAction={[
        {
          buttonText: t('service-station-group', { ns: 'page-container' }),
          buttonIcon: <AddCircleOutline />,
          onClick: handleAdd ?? handleGroupAdd,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <ServiceStationGroupsTable
          queryUrl={query ?? serviceStationGroupsEndpoints.getServiceStationGroups(customer.id)}
          queryParams={params}
          generateQueryKey={
            generateQueryKey ??
            ((page: number, pageSize: number) =>
              serviceStationGroupQueryKey.GET_SERVICE_STATION_GROUPS(customer.id, { page, pageSize }))
          }
          getDetailPanelContent={
            isTableExpandable
              ? renderTableExpand ??
                (({ row }) => {
                  return <ServiceStationGroupContainer serviceStationGroupId={row.id} serviceStationName={row.name} />
                })
              : undefined
          }
          handleEdit={isActionAvailable ? handleUpdate ?? handleGroupEdit : undefined}
          handleRemove={isActionAvailable ? handleDelete ?? handleGroupDelete : undefined}
          height="80vh"
        />
      </Box>
    </CommonPageLayout>
  )
}
