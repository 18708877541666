import { Box } from '@mui/system'
import { AxiosError } from 'axios'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LogoHeader } from '../components/LogoHeader'
import { ScreenTitle } from '../components/ScreenTitle'
import { useGetUserInvite, useRegisterUser } from '../api/user'
import { useTranslation } from 'react-i18next'
import { UserRegistrationForm } from 'src/forms/UserRegistrationForm'
import { Spinner } from 'src/components/Spinner'
import { toast } from 'react-toastify'

export const UserRegistrationScreen = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation('user-register')

  const { data, isLoading, error: getError } = useGetUserInvite(code as string)
  const { mutateAsync, error: postError, isLoading: isRegistering } = useRegisterUser()

  useEffect(() => {
    if (getError) {
      toast.error(t('invalid-invite-code', { ns: 'user-register' }))
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    }
  }, [getError])

  const handleSubmit = async form => {
    mutateAsync({ ...form, code }).then(() => navigate('/login'))
  }

  return (
    <>
      <LogoHeader />
      <Box className="flex flex-col items-center justify-center pt-6">
        {postError && <ErrMessage err={postError} />}
        <ScreenTitle title={t('register', { ns: 'user-register' })} />
        <Box className="w-6/12 pt-2">
          {isLoading ? (
            <Spinner />
          ) : (
            <UserRegistrationForm
              onSubmit={handleSubmit}
              initialValues={{ email: data?.userEmail }}
              isLoading={isRegistering}
              disabled={isLoading || getError != null}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

const ErrMessage: React.FC<{ err: AxiosError }> = ({ err }) => {
  const { t } = useTranslation('user-register')
  return (
    <Box className="w-6/12 p-6 my-2 text-2xl font-semibold text-center text-white bg-red-400 rounded-lg">
      {(err as any).data.errors.code[0] ?? t('error', { ns: 'user-register' })}
    </Box>
  )
}
