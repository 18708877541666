import { Box, Stack, Typography, styled, useTheme } from '@mui/material'
import React from 'react'
import { IFile } from 'src/generated-types'
import { flex } from 'src/styles/flexCss'
import { FileUrlLoader } from '../input/CommonFileUploader'
import { padding } from 'src/styles/paddingCss'
import { bytesToSize, getThemeColor } from 'src/utils'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { Flex } from 'src/styles/flexComponent'
import { useTranslation } from 'react-i18next'
import { MUIThemeColors } from 'src/mui-theme'
import { GridActionButton } from '../GridActionButton'
import { convertFirstLetterToUpperCase } from 'src/utils/function'
import { Padding } from 'src/styles/paddingComponent'

interface IDocumentViewer {
  file: IFile,
  enablePreview: boolean
  disabled: boolean
  dueDate?: string
  document: boolean
  onFileClick?: (e, file, callbackData) => void
  onEdit?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: IFile, callbackData: any) => void
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: IFile, callbackData: any) => void
  isDeletable?: boolean
  backgroundColor?: string
  callbackData?: any
}

const DocumentViewer: React.FC<IDocumentViewer> = ({ file, enablePreview, disabled, document, isDeletable, onFileClick, onEdit, onDelete, dueDate, backgroundColor, callbackData }) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  return (
    <DocumentCard sx={{ cursor: onFileClick ? 'pointer' : 'default' }} style={{ backgroundColor: backgroundColor }} onClick={e => (onFileClick ? onFileClick(e, file, callbackData) : undefined)}>
      <Flex.Column paddingTop={'4px'}>
        <IconContainer >
          <FileUrlLoader contentTypeValue={file?.contentType} fileUrl={file?.url} openOnClick={enablePreview} />
        </IconContainer>
      </Flex.Column>
      <Box flex={1}>
        <Typography variant="body1" fontWeight={700}>
          {convertFirstLetterToUpperCase(file?.filename)}
        </Typography>
        <Flex.Row>
          {
            file?.contentLength != 0 &&
            <Typography variant="caption" color={getThemeColor(theme, MUIThemeColors.secondaryDark)} fontWeight={500}>
              {bytesToSize(file?.contentLength)}
            </Typography>
          }
          {
            file?.contentLength != 0 && file?.createdByUser?.firstName && <Padding.ph4>.</Padding.ph4>
          }
          {
            file?.createdByUser?.firstName &&
            <Typography variant="caption" color={getThemeColor(theme, MUIThemeColors.secondaryDark)} fontWeight={500} className='pl-2-core'>
              {t('createdBy')} {file?.createdByUser?.firstName} {file?.createdByUser?.lastName}
            </Typography>
          }
          {
            dueDate && file?.createdByUser?.firstName && <Padding.ph4>.</Padding.ph4>
          }
          {
            dueDate &&
            <Typography variant="caption" color={getThemeColor(theme, MUIThemeColors.secondaryDark)} fontWeight={500} className='pl-2-core'>
              {t('dueDate')} {dueDate}
            </Typography>
          }
        </Flex.Row>
      </Box>
      <Flex.Row justifyContent={'center'} alignItems={'center'} gap={0.5}>
        {document && (
          <>
            {onEdit && (
              <GridActionButton
                onClick={e => onEdit(e, file, callbackData)}
                height='32px'
                width='32px'
                disabled={disabled}
                shadowWeight={0}
                buttonIcon={<EditOutlined sx={{ color: getThemeColor(theme, MUIThemeColors.secondaryDark) }} />}
              />
            )}
          </>
        )}
        {isDeletable && onDelete && (
          <GridActionButton
            onClick={e => {
              e.stopPropagation()
              onDelete(e, file, callbackData)
            }}
            height='32px'
            width='32px'
            shadowWeight={0}
            disabled={disabled}
            buttonIcon={<DeleteOutline sx={{ color: getThemeColor(theme, MUIThemeColors.secondaryDark) }} />}
          />
        )}
      </Flex.Row>
    </DocumentCard>
  )
}



export default DocumentViewer

//styled components
const DocumentCard = styled(Stack)`
    ${flex.row}
    border:1px solid ${props => getThemeColor(props.theme, MUIThemeColors.secondaryLight)};
    ${padding.p10}
    border-radius: 4px;
    flex:1;
`

const IconContainer = styled(Box)`
    height:32px;
    ${padding.pl10}
    ${padding.pr10}
`