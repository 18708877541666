import { GridColumns } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import GenericTable from '../../../components/table/GenericTable'
import { invalidateCustomer, useGetCustomerUsersForAdmins } from '../../../api/customer'
import { Chip, Typography, useTheme } from '@mui/material'
import { AppRoutesNavigation } from '../../../app-routes'
import { Delete } from '@mui/icons-material'
import {
  UpdateDirectPermissionDialog,
  UpdateUserRoleDialog,
  toast,
  useDeleteAssignUser,
} from '@lovoldsystem/authorization.client'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { RoleTypes } from '../../../enums'
import { permissionBinding, permissionLevel } from '../../../constant'
import { UserStatus } from '../../../enums/user-status'
import { FunctionElementsParentsIds } from '../../../enums/auth'
import { getUserDetail } from '@lovoldsystem/core.client'
import { UserDetail } from 'src/utils/getUserDetails'
import { useNavigate } from 'react-router-dom'

export interface UserTableProps {
  customerId: EntityId
  isAdminLayout?: boolean
  isGlobalServiceProvider: boolean
}
export const UserTable: React.FC<UserTableProps> = ({ customerId, isAdminLayout, isGlobalServiceProvider }) => {
  const { t: translate } = useTranslation(['users', 'common'])
  const { data, refetch } = useGetCustomerUsersForAdmins(customerId, true)
  const theme = useTheme()
  const navigate = useNavigate()
  const { mutateAsync } = useDeleteAssignUser()
  const { showDialog, showConfirmDialog } = useDialogCtx()

  const handleRoleUpdate = ({ clientUserLinkId, roleId }: { clientUserLinkId: number; roleId: number }) => {
    showDialog(
      UpdateUserRoleDialog,
      {
        title: translate('change-role', { ns: 'role' }),
        componentProps: {
          clientId: customerId,
          roleTypes: isGlobalServiceProvider ? [RoleTypes.Client, RoleTypes.ServiceStation] : [RoleTypes.Client],
          roleId: roleId,
          permissionBinding: permissionBinding,
          permissionLevels: permissionLevel,
          clientUserLinkId: clientUserLinkId,
          invalidateCallback: () => {
            refetch()
            invalidateCustomer.getCustomerById(customerId)
          },
          functionElementParents: isGlobalServiceProvider
            ? [FunctionElementsParentsIds.SystemPortal, FunctionElementsParentsIds.ServiceStationPortal]
            : [
                FunctionElementsParentsIds.SystemPortal,
                FunctionElementsParentsIds.LocalityPortal,
                FunctionElementsParentsIds.ServiceStationPortal,
              ],
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
    )
  }

  const handleDelete = (clientId: number) => {
    showConfirmDialog(
      translate('delete-confirm-role-title', { ns: 'access-control' }),
      translate('delete-confirm-role-description', { ns: 'access-control' }),
      { acceptText: translate('yes', { ns: 'common' }), cancelText: translate('no', { ns: 'common' }) },
      async pop => {
        await mutateAsync(clientId, {
          onSuccess: () => {
            pop()
            refetch()
            invalidateCustomer.getCustomerById(customerId)
          },
        })
      },
    )
  }

  const updateDirectPermissions = clientUserLinkId => {
    showDialog(
      UpdateDirectPermissionDialog,
      {
        componentProps: {
          clientUserLinkId,
          isDisabled: false,
          permissionLevels: permissionLevel,
          permissionBinding: permissionBinding,
          isWithLayout: true,
          functionElementParents: isGlobalServiceProvider
            ? [FunctionElementsParentsIds.SystemPortal, FunctionElementsParentsIds.ServiceStationPortal]
            : [
                FunctionElementsParentsIds.LocalityPortal,
                FunctionElementsParentsIds.ServiceStationPortal,
                FunctionElementsParentsIds.SystemPortal,
              ],
        },
        opts: {
          maxWidth: 'md',
        },
        title: translate('access-control:assign-users'),
      },
      undefined,
      true,
    )
  }

  const columns: GridColumns = useMemo(
    () => [
      { field: 'firstName', headerName: translate('firstName', { ns: 'users' }), flex: 1 },
      { field: 'lastName', headerName: translate('lastName', { ns: 'users' }), flex: 1 },
      { field: 'email', headerName: translate('email', { ns: 'users' }), flex: 1 },
      { field: 'phoneNumber', headerName: translate('phoneNumber', { ns: 'users' }), flex: 1 },
      {
        field: 'roles',
        headerName: translate('roles', { ns: 'access-control' }),
        flex: 2,
        renderCell: ({ row }) => {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {row.clientUserMappings && row.clientUserMappings.length > 0 ? (
                row.clientUserMappings?.map(clientUserLink => {
                  return clientUserLink?.roleId ? (
                    <Chip
                      style={{
                        background: theme.palette.primary.main,
                        color: 'white',
                        marginLeft: 2,
                        marginTop: 2,
                        cursor: 'pointer',
                      }}
                      label={translate(clientUserLink?.roleName || '', { ns: 'access-control' })}
                      onClick={() => {
                        handleRoleUpdate({
                          clientUserLinkId: clientUserLink?.clientUserLinkId,
                          roleId: clientUserLink?.roleId,
                        })
                      }}
                      onDelete={() => {
                        handleDelete(clientUserLink?.clientUserLinkId)
                      }}
                      deleteIcon={<Delete style={{ color: 'white', height: 19 }} />}
                    />
                  ) : null
                })
              ) : (
                <Typography ml={2} variant="h6" fontSize={'1rem'}>
                  {translate('no-client-roles', { ns: 'access-control' })}
                </Typography>
              )}
              {(row?.clientUserMappings ?? [])
                .filter(ul => ul.roleId == null)
                .map(clientUserLink => (
                  <Chip
                    key={clientUserLink.clientUserLinkId}
                    onClick={() => {
                      updateDirectPermissions(clientUserLink.clientUserLinkId)
                    }}
                    onDelete={() => {
                      showConfirmDialog(
                        translate('delete-confirm-direct-title', { ns: 'access-control' }),
                        translate('delete-confirm-direct-description', { ns: 'access-control' }),
                        {
                          acceptText: translate('yes', { ns: 'common' }),
                          cancelText: translate('no', { ns: 'common' }),
                        },
                        async pop => {
                          await mutateAsync(clientUserLink.clientUserLinkId, {
                            onSuccess: () => {
                              pop()
                              refetch()
                            },
                          })
                        },
                      )
                    }}
                    deleteIcon={<Delete style={{ color: 'white', height: 19 }} />}
                    style={{
                      background: theme.palette.success.main,
                      color: 'white',
                      marginLeft: 2,
                      cursor: 'pointer',
                    }}
                    label={translate('access-control:direct-permissions')}
                  />
                ))}
            </div>
          )
        },
      },
      {
        field: 'status',
        headerName: translate('status', { ns: 'users' }),
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ value }) => (
          <Chip
            label={translate(UserStatus[value].toString(), { ns: 'users' })}
            color={value == UserStatus.Pending ? 'warning' : value == UserStatus.Complete ? 'success' : 'error'}
          />
        ),
      },
    ],
    [translate],
  )

  return (
    <GenericTable
      id="users-table"
      data={data ?? []}
      getRowHeight={() => 'auto'}
      columns={columns}
      enableToolbar={true}
      onCellClick={({ row, field }) => {
        if (field === 'actions' || field === 'roles' || field === 'status') {
          return
        }
        !row.isCustomerAdmin
          ? isAdminLayout
            ? navigate(AppRoutesNavigation.Admin.customerUserResources(customerId, row.id.toString()), {
                state: {
                  name: getUserDetail(row, UserDetail.FullName),
                  isGlobalServiceProvider: isGlobalServiceProvider,
                },
              })
            : navigate(AppRoutesNavigation.Customer.userResources(customerId, row.id.toString()), {
                state: {
                  name: getUserDetail(row, UserDetail.FullName),
                  isGlobalServiceProvider: isGlobalServiceProvider,
                },
              })
          : toast.error(translate('customer-admin-message'))
      }}
      autoHeight={false}
      onRowClick={() => {}} // for enable row cursor pointer
      height={'81vh'}
    />
  )
}
