import { Autocomplete, Box, Chip } from '@mui/material'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { IPosition, IConnectedPosition, IPositionType } from '../../../../generated-types'
import { AppTextField } from 'src/components/AppTextField'

export const ConnectedPositionsFields: React.FC<{
  positions: IPosition[]
  positionId: EntityId
  name: string
  defaultValue?: IConnectedPosition[]
  connectingPositionType?: number
}> = ({ positions, name, defaultValue = null, positionId, connectingPositionType }) => {
  const { field } = useController({ name, defaultValue })
  const handleChange = val => field.onChange(val)
  const { t } = useTranslation(['position', 'common'])
  const getIsDisabled = field => {
    return connectingPositionType == IPositionType.MooringLine ? field.length == 1 : field.length == 2
  }

  return (
    <Autocomplete
      value={field?.value ?? []}
      fullWidth
      multiple
      options={
        (positions as IPosition[])
          ?.filter(x => x.type == IPositionType.Corner)
          ?.map(x => {
            return {
              positionConnectedId: x.id,
              positionId: positionId || 0,
              positionConnected: x,
            } as IConnectedPosition
          }) ?? []
      }
      renderOption={(props, option) => {
        return (
          <Box component="li" sx={{ display: 'flex', gap: '10px' }} {...props} key={option.positionConnectedId}>
            <Box>{option?.positionConnected?.name}</Box>
          </Box>
        )
      }}
      isOptionEqualToValue={(option, value) => option.positionConnectedId === value.positionConnectedId}
      getOptionLabel={option => option?.positionConnected?.name}
      onChange={(_, v) => {
        const selectedPositions = v as IConnectedPosition[]
        const isValidNumberOfPositions =
          connectingPositionType == IPositionType.MooringLine
            ? selectedPositions?.length < 2
            : selectedPositions?.length <= 2
        if (selectedPositions && isValidNumberOfPositions) {
          handleChange(selectedPositions)
        } else {
          toast.error(
            t(
              `${connectingPositionType == IPositionType.MooringLine
                ? 'mooringLineConnectedPositionsLimit'
                : 'connectedPositionsLimit'
              }`,
              { ns: 'work-assignment-position' },
            ),
          )
        }
      }}
      renderTags={(value: IConnectedPosition[], getTagProps) =>
        value?.map((option: IConnectedPosition, index: number) => {
          const { key, className: _, ...rest } = getTagProps({ index })
          return (
            <Chip
              key={key}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ ml: 0.1, mr: 0.1, mt: 0.5 }}
              label={option?.positionConnected?.name}
              {...rest}
            />
          )
        })
      }
      renderInput={params => (
        <AppTextField
          {...params}
          label={t('connectedPositions')}
          name="connectedPositions"
          placeholder={t('connectedPositions')}
          required={true}
          InputProps={{ ...params.InputProps, endAdornment: null }}
          disabled={getIsDisabled(field?.value ?? ([] as IConnectedPosition[]))}
        />
      )}
    />
  )
}
