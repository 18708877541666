import { LatLngTuple } from 'leaflet'
import React, { useEffect, useMemo } from 'react'
import { useMap } from 'react-leaflet'
import { ILocality, IMooring, IPosition, IPositionType } from '../../../../generated-types'

interface IMapBoundsComponent {
  localities?: ILocality[]
  moorings?: IMooring[]
  positions?: IPosition[]
  zoom?: number
}

const MapBoundsComponent: React.FC<IMapBoundsComponent> = ({ localities, moorings, positions, zoom }) => {
  const map = useMap()
  const boundaries: LatLngTuple[] = useMemo(() => {
    const positionArray: LatLngTuple[] = []
    if (localities) {
      localities.forEach(locality => {
        locality.moorings?.forEach(mooring => {
          if (mooring?.positions?.length > 0) {
            positionArray.push([mooring?.positions[0]?.latitude, mooring?.positions[0]?.longitude])
          }
        })
      })
    } else if (moorings) {
      moorings?.forEach(mooring => {
        if (mooring?.positions?.length > 0) {
          mooring?.positions?.forEach(position => {
            if (validatePosition(position)) {
              positionArray.push([position?.latitude, position?.longitude])
            }
          })
        }
      })
    } else if (positions) {
      positions?.forEach(position => {
        if (validatePosition(position)) {
          positionArray.push([position?.latitude, position?.longitude])
        }
      })
    }
    return positionArray
  }, [localities, moorings, positions])

  useEffect(() => {
    if (boundaries.length > 0) {
      map.fitBounds(boundaries, {
        animate: false,
        padding: [25, 25],
        duration: 100,
        maxZoom: localities ? 10 : undefined,
      })
    }
  }, [boundaries])

  useEffect(() => {
    if (zoom && zoom != 16) {
      map.setZoom(zoom)
    }
  }, [zoom])

  return null
}

export function validatePosition(position: IPosition): boolean {
  return (
    ((position.type === IPositionType.MooringLine || position.type === IPositionType.Corner) &&
      position?.latitude != 0) ||
    position?.longitude != 0
  )
}

export default MapBoundsComponent
