import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import i18n from '../../../../i18n'
import { styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

const localizer = momentLocalizer(moment)

const TaskCalendar = ({ onClick, events, onMonthChange }) => {
  const { t } = useTranslation(['common', 'task'])
  const calendarTranslations = {
    next: t('next', { ns: 'common' }),
    previous: t('back', { ns: 'common' }),
    today: t('today', { ns: 'task' }),
    month: t('month', { ns: 'common' }),
    day: t('day', { ns: 'common' }),
  }

  return (
    <StyledCalendar
      culture={i18n.language}
      startAccessor={event => {
        return new Date(event.startDate)
      }}
      endAccessor={event => {
        return event.endDate ? new Date(event.endDate) : new Date(event.startDate)
      }}
      localizer={localizer}
      events={events}
      onSelectEvent={onClick}
      onNavigate={date => onMonthChange(date)}
      views={{ week: false, month: true, day: true }}
      popup
      messages={calendarTranslations}
    />
  )
}

const StyledCalendar = styled(Calendar)({
  height: 500,
})

export default TaskCalendar
