import { IMainComponentType } from 'src/generated-types/main-component-type'
import { IGetCertificatesParams } from '../../../../api/Certificates'
import { CertificatesTableGeneric } from '../../../../components/CertificatesTableGeneric'
import { Context } from 'react'
export interface ICertificatesTableProps {
  mainComponentId?: EntityId
  mainComponentType?: IMainComponentType
  customerId?: EntityId
  positionId?: EntityId
  certificatesParams: IGetCertificatesParams
  showcertificatesHeader?: boolean
  height?: number
  context?: Context<any> | null
  setDisableAddCertificate?: (disable: boolean) => void
}
export const CertificateTable: React.FC<ICertificatesTableProps> = ({
  mainComponentId,
  mainComponentType,
  customerId,
  positionId,
  showcertificatesHeader = false,
  height,
  certificatesParams,
  context,
  setDisableAddCertificate,
}) => {
  return (
    <CertificatesTableGeneric
      mainComponentId={mainComponentId as EntityId}
      mainComponentType={mainComponentType}
      customerId={customerId ?? 0}
      positionId={positionId ?? 0}
      showCertificatesHeader={showcertificatesHeader}
      height={height}
      certificateParams={certificatesParams}
      context={context}
      setDisableAddCertificate={setDisableAddCertificate}
    />
  )
}

export const CertificateTableLocality: React.FC<ICertificatesTableProps> = ({
  customerId,
  positionId,
  certificatesParams,
  showcertificatesHeader = true,
  height,
  context,
}) => {
  return (
    <CertificatesTableGeneric
      mainComponentId={0}
      customerId={customerId as EntityId}
      positionId={positionId as EntityId}
      showCertificatesHeader={showcertificatesHeader}
      height={height}
      certificateParams={certificatesParams}
      context={context}
    />
  )
}
