import {
  GridActionsCellItem,
  gridClasses,
  GridColumns,
  GridEventListener,
  GridNativeColTypes,
} from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { invalidatePositionCache, useDeletePosition } from '../../../../api/positions'
import GenericTable from '../../../../components/table/GenericTable'
import { IPosition, IPositionType } from '../../../../generated-types'
import { toast } from 'react-toastify'
import { MdDelete, MdEdit, MdEditNote } from 'react-icons/md'
import { useDialogCtx, useMooringsCtx } from '../../../../hooks/context-hooks'
import {
  CreatePositionDeviationDialog,
  ICreateDeviationDialogProps,
} from '../../deviations/CreatePositionDeviationDialog'
import { IDeviationFormWizardType } from '../../../../generated-types/form-wizard'
import { IDeviationType } from '../../../../generated-types/deviation-type'
import { UpdatePositionDialog } from '../../positions/UpdatePositionDialog'
import PositionsTableTemplateRender, { PositionsTableStaticColumns } from '../Helpers/PositionsTableHelper'
import { AppRoutesNavigation } from '../../../../app-routes'
import styled from '@emotion/styled'
import { Flex } from 'src/styles/flexComponent'
import PositionInformationContainer from '../PositionInformationContainer'
import { useNavigation } from 'src/context/NavigationContext'
import { CONNECTED_POSITIONS_EXIST, POSITIONS_CONNECTED_TO_OTHER_POSITIONS } from 'src/constant'
import { useGetPositionDrawingInMooring } from 'src/api/position-drawing'

interface IPositionTable {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  onRowClick?: GridEventListener<'rowClick'> | undefined
  isSelectable?: boolean
  positions: IPosition[]
  checkboxSelection?: boolean
}

export const CustomStyledTable = styled(GenericTable)({
  '& .MuiDataGrid-columnHeaders': {
    display: 'none',
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0 !important',
  },
  '.inner-table': {
    '& .MuiDataGrid-columnHeaders': {
      display: 'block',
    },
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '40px !important',
    },
    [`& .${gridClasses.columnHeadersInner}`]: {
      lineHeight: '40px',
    },
  },
})

export const PositionTable: React.FC<IPositionTable> = ({
  customerId,
  localityId,
  mooringId,
  positions,
  checkboxSelection = false,
}) => {
  const { setSelectedPosition, setSelectedPositions } = useMooringsCtx()
  const { t } = useTranslation(['mooring', 'common', 'position', 'deviation'])
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const [expandedPositionId, setExpandedPositionId] = useState<number>()
  const { navigateTo } = useNavigation()
  const { data: availablePositionDrawings, isLoading } = useGetPositionDrawingInMooring(
    customerId as EntityId,
    mooringId as EntityId,
  )

  useEffect(() => {
    const position = positions.find(x => x.id === expandedPositionId)
    setSelectedPosition(position)
  }, [expandedPositionId, positions])

  const { mutateAsync: deletePosition } = useDeletePosition(
    customerId as EntityId,
    localityId as EntityId,
    mooringId as EntityId,
  )

  const positionDetailPanelContent = (row: IPosition) => {
    return (
      <PositionInformationContainer
        customerId={customerId}
        positionId={row?.id}
        localityId={localityId}
        mooringId={mooringId}
      />
    )
  }

  const onUpdate = useCallback(
    async (position: IPosition) => {
      showDialog(
        UpdatePositionDialog,
        {
          opts: {
            maxWidth: 'md',
            fullWidth: true,
          },
          componentProps: {
            customerId,
            localityId,
            mooringId,
            positionId: position.id,
            position,
          },
        },
        undefined,
        true,
      )
    },
    [showDialog, t, customerId, localityId, mooringId],
  )

  const onDelete = useCallback(
    async (id: EntityId) => {
      showConfirmDialog(
        t('deletePosition', { ns: 'position' }),
        t('areYouSureDeletePosition', { ns: 'position' }),
        {
          acceptText: t('yes', { ns: 'common' }),
          cancelText: t('no', { ns: 'common' }),
        },
        async pop => {
          await deletePosition(id, {
            onSuccess: data => {
              toast(t(data.data, { ns: 'common-api-message' }), { type: 'success' })
              invalidatePositionCache.getPositions(customerId as EntityId, localityId, mooringId)
              invalidatePositionCache.getPositionsWithMinimalData(customerId, localityId, mooringId)
              pop()
            },
            onError: error => {
              if (error['data'] == POSITIONS_CONNECTED_TO_OTHER_POSITIONS) {
                toast.error(t('positionConnectedToOtherPositions', { ns: 'position' }))
              }
              if (error['data'] == CONNECTED_POSITIONS_EXIST) {
                toast.error(t('ConnectedPositionsExist', { ns: 'position' }))
              }
            },
          })
        },
      )
    },
    [customerId, localityId, mooringId, deletePosition, showConfirmDialog, t],
  )

  const onAddDeviationForPosition = useCallback(
    async position => {
      showDialog(
        CreatePositionDeviationDialog,
        {
          title: t('addNewDeviation', { ns: 'deviation' }),
          opts: {
            maxWidth: 'md',
            fullWidth: true,
          },
          componentProps: {
            customerId,
            localityId,
            formWizard: IDeviationFormWizardType.Position,
            disableSteps: false,
            type: IDeviationType.Mooring,
            position,
            mooringId,
          } as ICreateDeviationDialogProps,
        },
        undefined,
        true,
      )
    },
    [customerId, localityId, showDialog, t, mooringId],
  )

  const onRowClick = (positionId: string | number) => {
    if (localityId)
      navigateTo(
        AppRoutesNavigation.Localities.position(customerId, localityId as EntityId, mooringId as EntityId, positionId),
      )
  }

  const getActionItems = useCallback(
    params => [
      <GridActionsCellItem
        key={params.id}
        label={t('edit', { ns: 'common' })}
        icon={<MdEdit size={24} />}
        onClick={() => {
          onUpdate(params.row)
        }}
        color="primary"
        showInMenu
      />,
      <GridActionsCellItem
        key={params.id}
        label={t('remove', { ns: 'common' })}
        icon={<MdDelete size={24} />}
        onClick={() => {
          onDelete(params.id)
        }}
        color="primary"
        showInMenu
      />,
      <GridActionsCellItem
        key={params.id}
        label={t('addDeviation', { ns: 'deviation' })}
        icon={<MdEditNote size={24} />}
        onClick={() => {
          onAddDeviationForPosition(params.row)
        }}
        color="primary"
        showInMenu
      />,
    ],
    [onUpdate, onDelete, onAddDeviationForPosition, t],
  )

  const columns: GridColumns = useMemo(
    () => [
      ...PositionsTableStaticColumns,

      {
        field: 'template',
        headerName: t('template', { ns: 'mooring' }),
        renderCell: ({ row }) => {
          return row.type == IPositionType.Cage ? (
            <Flex.Row width={'100%'} justifyContent={'center'} alignItems={'center'}>
              {t('n/a', { ns: 'common' })}
            </Flex.Row>
          ) : (
            <PositionsTableTemplateRender
              position={row}
              customerId={customerId}
              localityId={localityId}
              mooringId={mooringId as EntityId}
              availablePositionDrawings={availablePositionDrawings}
              isLoading={isLoading}
            />
          )
        },
        flex: 1,
      },
      {
        field: 'actions',
        headerName: t('actions', { ns: 'common' }),
        type: 'actions' as GridNativeColTypes,
        minWidth: 60,
        getActions: getActionItems,
      },
    ],
    [customerId, localityId, mooringId, t, getActionItems, availablePositionDrawings, isLoading],
  )

  return (
    <CustomStyledTable
      id="mooring-positions-table"
      columns={columns}
      data={positions}
      pageSize={10}
      enableToolbar={true}
      getDetailPanelContent={({ row }) =>
        row.type != IPositionType.Cage ? positionDetailPanelContent(row) : undefined
      }
      getDetailPanelHeight={() => 'auto'}
      getRowHeight={() => 'auto'}
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={ids => {
        setSelectedPositions(positions.filter(x => ids.includes(x.id)))
      }}
      onDetailPanelExpandedRowIdsChange={async ids => {
        if (ids.length > 0) {
          const id = [...ids].pop()
          setExpandedPositionId(id as number)
        } else {
          setExpandedPositionId(undefined)
        }
      }}
      detailPanelExpandedRowIds={expandedPositionId ? [expandedPositionId] : []}
      onRowClick={row => onRowClick(row?.id)}
      sortModel={[
        {
          field: 'name',
          sort: 'asc',
        },
      ]}
      className={'position-table'}
    />
  )
}

export default PositionTable
